import React from 'react';
import './home.scss';
import { Col, Row } from 'reactstrap';

export const WhyBuyPerSqFt: React.FC = () => (
  <div className="margin-top-header">
    <Row>
      <Col xs="12">
        <div className="align-center pt-4">
          <span className="text-main-header">Why BuyPerSqFt</span>
        </div>
      </Col>
      <Col xs="12">
        <div className="icons pt-4">
          <div className="mx-2">
            <div className="align-center">
              <img alt="" src="/images/award-symbol.png" />
            </div>
            <div className="text-center-aligned my-3">
              <span className="text-super-lite">Marketing Partner</span>
            </div>
          </div>
          <div className="mx-2">
            <div className="align-center">
              <img alt="" src="/images/awards.png" />
            </div>
            <div className="text-center-aligned my-3">
              <span className="text-super-lite">Ease Serving hassle free</span>
            </div>
          </div>
          <div className="mx-3">
            <div className="align-center">
              <img alt="" src="/images/home.png" />
            </div>
            <div className="text-center-aligned my-3">
              <span className="text-super-lite">1000+ Customer Served</span>
            </div>
          </div>
          <div className="mx-2">
            <div className="align-center">
              <img alt="" src="/images/shield.png" />
            </div>
            <div className="text-center-aligned my-3">
              <span className="text-super-lite">Expert Property Knowledge</span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

export default WhyBuyPerSqFt;
