import { ProfileResponse, ProfileResults } from './get-profile.service.types';

export const ProfileTransform: (response: ProfileResponse) => ProfileResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :ProfileResults = {
    profileData: {
      nme: '',
      ph: '',
      UserCd: '',
      UserTp: '',
      isPKReset: false,
      lgnSts: '',
      eId: '',
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.profileData.nme = data.nme ?? '';
    result.profileData.ph = data.ph ?? '';
    result.profileData.eId = data.eId ?? '';
    result.profileData.isPKReset = data.isPKReset ?? false;
    result.profileData.lgnSts = data.lgnSts ?? 'UNK';
    result.profileData.UserCd = data.UserCd ?? '';
    result.profileData.UserTp = data.UserTp ?? '';
    result.isError = is_error;
    result.message = 'executed';
  }
  return result;
};
