import { GetAllProjectsResponse, GetAllProjectsResults } from './get-all-projects.service.types';

export const GetAllProjectsTransform: (response: GetAllProjectsResponse) => GetAllProjectsResults = (getAllSellerProductResults) => {
  const { data, is_error, message } = getAllSellerProductResults;
  const result: GetAllProjectsResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
      projData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
