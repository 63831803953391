import { createAction } from 'typesafe-actions';
import {
  API_ERROR, CLEAR_CONTACT_US_DATA, CLEAR_CREATE_ENQUIRY, CLEAR_CREATE_PROJECTS, CLEAR_DELETE_PROJECTS, CLEAR_ENQUIRY_LIST, CLEAR_GET_LIST_PROJECTS, CLEAR_LOGIN_TOKEN, CLEAR_PROFILE, CLEAR_PROJECTS_LIST, CLEAR_PUB_RES_PROJECTS_LIST, CLEAR_PUT_ENQUIRY, CLEAR_PUT_PROJECTS, CLEAR_UPLOAD_PROJECTS, CONTACT_US_DATA, CREATE_ENQUIRY, CREATE_PROJECTS, DELETE_PROJ_IMG, GET_ENQUIRY_LIST, GET_LIST_PROJ_IMG, GET_LOGIN_TOKEN, GET_PROFILE, GET_PROJECTS_LIST, GET_PUB_RES_PROJECTS_LIST, PUT_ENQUIRY, PUT_PROJECTS, SHOW_DATA, UPDATE_CONTACT_US_DATA, UPDATE_CREATE_ENQUIRY, UPDATE_CREATE_PROJECTS, UPDATE_DATA,
  UPDATE_DELETE_PROJ_IMG,
  UPDATE_ENQUIRY_LIST,
  UPDATE_GET_LIST_PROJ_IMG,
  UPDATE_LOGIN_TOKEN,
  UPDATE_PROFILE,
  UPDATE_PROJECTS_LIST,
  UPDATE_PUB_RES_PROJECTS_LIST,
  UPDATE_PUT_ENQUIRY,
  UPDATE_PUT_PROJECTS,
  UPDATE_UPLOAD_PROJ_IMG,
  UPLOAD_PROJ_IMG,
} from './constants';
import { ContactUsOptions, ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import { ErrorAction } from '../../services/types';
import { GenTokenOptions, GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileOptions, ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { PostProjectsOptions, PostProjectsResults } from '../../services/projects/add-projects/post-projects.service.types';
import { DeleteProjImgOptions, DeleteProjImgResults } from '../../services/projects/delete-proj-image/delete-projimage.service.types';
import { GetListProjImgOptions, GetListProjImgResults } from '../../services/projects/get-list-projects-images/getList-projimage.service.types';
import { ProjectsPutOptions, ProjectsPutResults } from '../../services/projects/update-projects/update-projects.service.types';
import { CreateProjImgOptions, CreateProjImgResults } from '../../services/projects/upload-proj-Image/create-projimage.service.types';
import { GetAllProjectsOptions, GetAllProjectsResults } from '../../services/projects/list-projects/get-all-projects.service.types';
import { GetAllPubRESProjectsOptions, GetAllPubRESProjectsResults } from '../../services/projects/list-pub-res-projects/get-all-pub-res-projects.service.types';
import { PostEnquiryOptions, PostEnquiryResults } from '../../services/enquiry/add-enquiry/post-enquiry.service.types';
import { GetAllEnquiryOptions, GetAllEnquiryResults } from '../../services/enquiry/get-all-enquiry/get-all-enquiry.service.types';
import { EnquiryPutOptions, EnquiryPutResults } from '../../services/enquiry/update-enquiry/update-enquiry.service.types';

export const apiError = createAction(API_ERROR)<ErrorAction>();
export const showData = createAction(SHOW_DATA)<{}>();
export const updateData = createAction(UPDATE_DATA)<string>();
export const contactUsPostReq = createAction(CONTACT_US_DATA)<ContactUsOptions>();
export const updateContactUsPostReq = createAction(UPDATE_CONTACT_US_DATA)<ContactUsResults>();
export const clearContactUsPostReq = createAction(CLEAR_CONTACT_US_DATA)();
export const getLoginToken = createAction(GET_LOGIN_TOKEN)<GenTokenOptions>();
export const updateToken = createAction(UPDATE_LOGIN_TOKEN)<GenTokenResults>();
export const clearLoginTokenReq = createAction(CLEAR_LOGIN_TOKEN)();
export const getProfile = createAction(GET_PROFILE)<ProfileOptions>();
export const updateProfile = createAction(UPDATE_PROFILE)<ProfileResults>();
export const clearProfileReq = createAction(CLEAR_PROFILE)();
export const createProjectsReq = createAction(CREATE_PROJECTS)<PostProjectsOptions>();
export const updateCreateProjectsReq = createAction(UPDATE_CREATE_PROJECTS)<PostProjectsResults>();
export const clearCreateProjectsReq = createAction(CLEAR_CREATE_PROJECTS)();
export const putProjectsReq = createAction(PUT_PROJECTS)<ProjectsPutOptions>();
export const updatePutProjectsReq = createAction(UPDATE_PUT_PROJECTS)<ProjectsPutResults>();
export const clearPutProjectsReq = createAction(CLEAR_PUT_PROJECTS)();
export const uploadProjImg = createAction(UPLOAD_PROJ_IMG)<CreateProjImgOptions>();
export const updateUploadProjImg = createAction(UPDATE_UPLOAD_PROJ_IMG)<CreateProjImgResults>();
export const clearUploadProjImg = createAction(CLEAR_UPLOAD_PROJECTS)();
export const getListProjImg = createAction(GET_LIST_PROJ_IMG)<GetListProjImgOptions>();
export const updateGetListProjImg = createAction(UPDATE_GET_LIST_PROJ_IMG)<GetListProjImgResults>();
export const clearGetListProjImg = createAction(CLEAR_GET_LIST_PROJECTS)();
export const deleteProjImg = createAction(DELETE_PROJ_IMG)<DeleteProjImgOptions>();
export const updateDeleteProjImg = createAction(UPDATE_DELETE_PROJ_IMG)<DeleteProjImgResults>();
export const clearDeleteProjImg = createAction(CLEAR_DELETE_PROJECTS)();
export const getProjectsListReq = createAction(GET_PROJECTS_LIST)<GetAllProjectsOptions>();
export const updateProjectsListReq = createAction(UPDATE_PROJECTS_LIST)<GetAllProjectsResults>();
export const clearProjectsListReq = createAction(CLEAR_PROJECTS_LIST)();
export const getPubRESProjectsListReq = createAction(GET_PUB_RES_PROJECTS_LIST)<GetAllPubRESProjectsOptions>();
export const updatePubRESProjectsListReq = createAction(UPDATE_PUB_RES_PROJECTS_LIST)<GetAllPubRESProjectsResults>();
export const clearPubRESProjectsListReq = createAction(CLEAR_PUB_RES_PROJECTS_LIST)();
export const createEnquiryReq = createAction(CREATE_ENQUIRY)<PostEnquiryOptions>();
export const updateCreateEnquiryReq = createAction(UPDATE_CREATE_ENQUIRY)<PostEnquiryResults>();
export const clearCreateEnquiryReq = createAction(CLEAR_CREATE_ENQUIRY)();
export const getEnquiryListReq = createAction(GET_ENQUIRY_LIST)<GetAllEnquiryOptions>();
export const updateEnquiryListReq = createAction(UPDATE_ENQUIRY_LIST)<GetAllEnquiryResults>();
export const clearEnquiryListReq = createAction(CLEAR_ENQUIRY_LIST)();
export const putEnquiryReq = createAction(PUT_ENQUIRY)<EnquiryPutOptions>();
export const updatePutEnquiryReq = createAction(UPDATE_PUT_ENQUIRY)<EnquiryPutResults>();
export const clearPutEnquiryReq = createAction(CLEAR_PUT_ENQUIRY)();
