export const setTokenSessionStorage = (token: string) => {
  sessionStorage.setItem('token', token);
};
export const setUserProfileSessionStorage = (userProfile: any) => {
  sessionStorage.setItem('userProfile', userProfile);
};
export const getTokenFromSessionStorage = () => sessionStorage.getItem('token') || '';
export const getUserProfileSessionStorage = () => sessionStorage.getItem('userProfile');
export const clearKeysFromSessionStorage = () => {
  sessionStorage.clear();
};
