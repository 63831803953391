import { getCommonApiToken, getPublicJSON } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { GetAllPubRESProjectsOptions, GetAllPubRESProjectsResponse } from './get-all-pub-res-projects.service.types';

const { BUYPERSQFT_API_ROOT } = getConfig();

export const GetAllPubRESProjectsRequest = ({
  requestType, userType, projTp, token, evalKey, limit, evalTp, searchBy, isSearch, isFilter, filterBy, isPublic,
}: GetAllPubRESProjectsOptions): Promise<GetAllPubRESProjectsResponse> => {
  if (!isPublic) {
    if (isFilter === 'YES') {
      return getCommonApiToken(`${BUYPERSQFT_API_ROOT}?userTp=${userType}&projTp=${projTp}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isPublic='NO'`, requestType, token);
    } if (isSearch === 'YES') {
      return getCommonApiToken(`${BUYPERSQFT_API_ROOT}?userTp=${userType}&projTp=${projTp}&isSearch=${isSearch}&searchBy=${searchBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isPublic='NO'`, requestType, token);
    }
    return getCommonApiToken(`${BUYPERSQFT_API_ROOT}?userTp=${userType}&projTp=${projTp}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isPublic='NO'`, requestType, token);
  }
  if (isFilter === 'YES') {
    return getPublicJSON(`${BUYPERSQFT_API_ROOT}?&projTp=${projTp}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isPublic='YES'`, requestType);
  } if (isSearch === 'YES') {
    return getPublicJSON(`${BUYPERSQFT_API_ROOT}?&projTp=${projTp}&isSearch=${isSearch}&searchBy=${searchBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isPublic='YES'`, requestType);
  }
  return getPublicJSON(`${BUYPERSQFT_API_ROOT}?&projTp=${projTp}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isPublic='YES'`, requestType);
};
