export enum ApiLIMIT{
    LIMIT = 30
}
export enum APIHeader {
    REQ_ADD_USER = 'add_user',
    USER_LOGIN = 'user_login',
    REQ_USER_PROFILE_INFO = 'user_profile_info',
    REQ_ADD_PROJECT = 'add_project',
    REQ_GET_ALL_PROJECTS = 'get_all_projects',
    REQ_UPDATE_PROJECT = 'update_project',
    REQ_ADD_ENQUIRY = 'add_enquiry',
    REQ_GET_ALL_ENQUIRY = 'get_all_enquiry',
    REQ_UPDATE_ENQUIRY = 'update_enquiry',
  }

export const ResidentPropertyTypeList = [
  { label: '1 BHK', value: '1BHK' },
  { label: '2 BHK', value: '2BHK' },
  { label: '3 BHK', value: '3BHK' },
  { label: '4 BHK', value: '4BHK' },
  { label: 'PentHouse', value: 'PNTH' },
  { label: 'Row House', value: 'ROWH' },
  { label: 'Studio Appartment', value: 'STUDA' },
];

export const CommPropertyTypeList = [
  { label: 'Retail Shops', value: 'RSHOP' },
  { label: 'Office Spaces', value: 'OFFSP' },
  { label: 'F&B', value: 'FB' },
  { label: 'Roof Top Resturants', value: 'RTRES' },
];

export const LOCOptions = [
  {
    label: 'Kharadi, Pune, Maharashtra', value: 'KHAR', city: 'PUNE', st: 'MAHARASHTRA',
  },
  {
    label: 'Dhanori, Pune, Maharashtra', value: 'DHAN', city: 'PUNE', st: 'MAHARASHTRA',
  },
  {
    label: 'Hinjewadi, Pune, Maharashtra', value: 'HINJ', city: 'PUNE', st: 'MAHARASHTRA',
  },
  {
    label: 'Bhosari Metro Station, Nashik Phata, Pune, Maharashtra', value: 'NPHTA', city: 'PUNE', st: 'MAHARASHTRA',
  },
];
