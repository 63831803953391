import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './admin.scss';
import { faPersonCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { LoaderData } from '../../../utils/loader';
import { errorAlert } from '../../../utils/alert';
import { ProjectComponent } from './projects/project.component';
import { GetAllEnquiry } from './enquiry/get-all-enquiry';

export const AdminDashboard: React.FC = () => {
  // const dispatch = useDispatch();
  //   const tokenData = getTokenFromSessionStorage();
  //   const userInitTp = getUserProfileSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('enquiries');
  const [loaderUser, setLoaderUser] = React.useState(false);
  const listProjects = useSelector((state: RootState) => state.buypersqft.contactUs);

  const handleOrders = () => {
    setLoaderUser(false);
    setActiveTab('enquiries');
    setUserTp('');
  };

  const handlePendProject = () => {
    // dispatch(clearSellerProjectsListReq());
    setActiveTab('projects');
  };

  React.useEffect(() => {
    setLoaderUser(false);
    if (activeTab === 'enquiries' && userTp !== '') {
    //   dispatch(getUserListReq({
    //     userType: userTp,
    //     requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
    //     status: 'PADM',
    //     // limit: ApiLIMIT.LIMIT,
    //     token: tokenData,
    //     // searchData: '',
    //   }));
    }
  }, [userTp]);

  React.useEffect(() => {
    if (listProjects.error && listProjects.message !== '') {
      setLoaderUser(false);
      setAlertMsg(listProjects.message);
      setErrorShowAlert(true);
    }
  }, [listProjects.error, listProjects.message]);

  React.useEffect(() => {
    if (!listProjects.error && listProjects.message === 'success') {
      setLoaderUser(false);
    }
  }, [listProjects.error, listProjects.message]);

  return (
    <div className="label-margin-to-nav">
      { loaderUser ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-1 pt-3">
        <span className="buy-main-label">
          Quick View - Dashboard
        </span>
      </div>
      <Row>
        <Col xs="12" className="pt-4">
          <Nav tabs className="buy-profile-tab">
            <div className="buy-row-profile-tab">
              <div className="buy-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="buy-profile-nav-tabs" active={activeTab === 'enquiries'} onClick={handleOrders}>
                    <FontAwesomeIcon icon={faPersonCircleQuestion} className="buy-tab-profile-icon mr" />
                    Enquiries
                  </NavLink>
                </NavItem>
              </div>
              <div className="buy-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="buy-profile-nav-tabs" active={activeTab === 'projects'} onClick={handlePendProject}>
                    <FontAwesomeIcon icon={faDropbox} className="buy-tab-profile-icon mr" />
                    Projects
                  </NavLink>
                </NavItem>
              </div>
            </div>
            <div className="buy-row-profile-tab-mobile">
              <div className="buy-column-profile-tab-left">
                <NavItem className="cursor-pointer buy-profile-tab">
                  <NavLink className="buy-profile-nav-tabs" active={activeTab === 'enquiries'} onClick={handleOrders}>
                    Enquiries <FontAwesomeIcon icon={faPersonCircleQuestion} className="buy-tab-profile-icon" />
                  </NavLink>
                </NavItem>
              </div>
              <div className="buy-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin buy-profile-tab">
                  <NavLink className="buy-profile-nav-tabs" active={activeTab === 'projects'} onClick={handlePendProject}>
                    Projects <FontAwesomeIcon icon={faDropbox} className="buy-tab-profile-icon" />
                  </NavLink>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            {activeTab === 'enquiries' && (
            <TabPane tabId="enquiries">
              <GetAllEnquiry />
            </TabPane>
            )}
            {activeTab === 'projects' && (
            <TabPane tabId="projects">
              <ProjectComponent />
            </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;
