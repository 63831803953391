import { EnquiryPutResponse, EnquiryPutResults } from './update-enquiry.service.types';

export const EnquiryPutTransform: (response: EnquiryPutResponse) => EnquiryPutResults = (passKeyResults) => {
  const { data, is_error, message } = passKeyResults;
  const result: EnquiryPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
