import React from 'react';
import { NavLink } from 'react-router-dom';
import './nav.scss';
import {
  Nav,
} from 'reactstrap';

export const ExclusiveLoginNavbar: React.FC = () => (
  <div className="navbar-container">
    <Nav className="navbar">
      <div className="navbar-left">
        <NavLink to="/" className="nav-logo">
          <img style={{ height: '55px', width: 'auto' }} src="/BUYPERSQFT.png" alt="buysqftLogo" />
        </NavLink>
      </div>
    </Nav>
  </div>
);

export default ExclusiveLoginNavbar;
