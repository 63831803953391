import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import {
  Routes, Route,
  NavLink,
} from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row, Col, NavItem, Popover, PopoverBody,
  Button,
} from 'reactstrap';
import './admin.scss';
import { getUserProfileSessionStorage, clearKeysFromSessionStorage, getTokenFromSessionStorage } from '../../utils/service/localstorage-service';
import CXUnauthorized from './unauthorized/unauthorized.component';
import { AdminDashboard } from '../../components/exclusive/admin/admin.dashboard';

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<AdminDashboard />} />
  </Routes>
);

export const AdminExclusiveComponent: React.FC = () => {
  const history = useNavigate();
  const tokenData = getTokenFromSessionStorage();
  const userProfile = getUserProfileSessionStorage();
  const [name, setName] = useState('');
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [openUserNotification, setOpenUserNotification] = useState(false);
  const userInfoRef = React.useRef<HTMLImageElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  // React.useEffect(() => {
  //   if (tokenData !== '') {
  //     const decodedToken: any = jwt_decode(tokenData);
  //     if (decodedToken !== undefined || decodedToken !== null) {
  //       if (decodedToken.exp * 1000 < new Date().getTime()) {
  //         history('/login/sadmin#logn');
  //       }
  //     }
  //   }
  // }, [tokenData, history]);

  React.useEffect(() => {
    let shouldRedirect = false;
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      if (decodedToken !== undefined || decodedToken !== null) {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          shouldRedirect = true;
          history('/login/sadmin#logn');
        }
      }
    }
    if (shouldRedirect) {
      window.location.reload();
    }
  }, [tokenData, history]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.nme === '') {
      setName('');
    } else {
      setName(userProfile && JSON.parse(userProfile).profileData.nme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.nme]);

  const handleUserNotification = () => {
    setOpenUserNotification(!openUserNotification);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        userInfoRef.current
        && !userInfoRef.current.contains(event.target as Node)
        && popoverRef.current
        && !popoverRef.current.contains(event.target as Node)
      ) {
        setOpenUserNotification(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {tokenData !== '' ? (
        <div>
          <Row className="navbar-admin">
            <Col lg="5" xs="8">
              <div className="align-start mx-1">
                <div className="bg-icon-nav-dash pt-3">
                  <NavLink to="dashboard">
                    <img style={{ height: '55px', width: 'auto' }} src="/BUYPERSQFT.png" alt="buysqftLogo" />
                  </NavLink>
                </div>
              </div>
            </Col>
            <Col lg="7" xs="4">
              <div className="align-end mr-1">
                <div className="menu-image-avatar">
                  <div className="align-center pt-1">
                    <Button className="button-icon" onClick={handleUserNotification}>
                      <img
                        alt="..."
                        className="logo-bg rounded-image cursor-pointer"
                        src="/BUYPERSQFT.ico"
                        id="userInfo"
                        ref={userInfoRef}
                      />
                    </Button>
                    <Popover placement="bottom" isOpen={openUserNotification} target="userInfo" toggle={handleUserNotification}>
                      <PopoverBody className="align-center">
                        <div ref={popoverRef} className="align-center">
                          <ul className="menu-list pt-3">
                            <NavItem className="menu-list">
                              <div className="align-center mx-2">
                                <span className="sadmin-user-name-text">{name}</span>
                              </div>
                            </NavItem>
                            <NavItem className="menu-list pt-1">
                              <hr className="text-lite-grey" />
                              <div className="align-center my-2">
                                <NavLink to="/login/sadmin#logn" onClick={() => { clearKeysFromSessionStorage(); window.location.href = '/login/sadmin#logn'; }} className="menu-link-notification">
                                  <FontAwesomeIcon className="sadmin-grey-icon" icon={faRightFromBracket} />
                                  <span className="text-link mx-2">Logout</span>
                                </NavLink>
                              </div>
                            </NavItem>
                          </ul>
                        </div>
                      </PopoverBody>
                    </Popover>
                  </div>
                  <div className="align-center my-1">
                    <span className="user-info-text">Admin</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="route-margin">
              <ExclusiveRoutes />
            </div>
          </Row>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default AdminExclusiveComponent;
