import { GetListProjImgResponse, GetListProjImgResults } from './getList-projimage.service.types';

export const GetListProjImgTransform: (response: GetListProjImgResponse) => GetListProjImgResults = (createProjImgDBResults) => {
  const { data } = createProjImgDBResults;
  const result: GetListProjImgResults = {
    data: [],
  };
  result.data = data;
  return result;
};
