import { ContactUsPostRequest } from './contact-us/contact-us.service';
import {
  ContactUsService, ContactUsOptions, ContactUsResponse, ContactUsResults,
} from './contact-us/contact-us.service.types';
import { ContactUsTransform } from './contact-us/contact-us.transform';
import { PostEnquiryRequest } from './enquiry/add-enquiry/post-enquiry.service';
import {
  PostEnquiryService, PostEnquiryOptions, PostEnquiryResponse, PostEnquiryResults,
} from './enquiry/add-enquiry/post-enquiry.service.types';
import { PostEnquiryTransform } from './enquiry/add-enquiry/post-enquiry.transform';
import { GetAllEnquiryRequest } from './enquiry/get-all-enquiry/get-all-enquiry.service';
import {
  GetAllEnquiryService, GetAllEnquiryOptions, GetAllEnquiryResponse, GetAllEnquiryResults,
} from './enquiry/get-all-enquiry/get-all-enquiry.service.types';
import { GetAllEnquiryTransform } from './enquiry/get-all-enquiry/get-all-enquiry.transform';
import { EnquiryPutRequest } from './enquiry/update-enquiry/update-enquiry.service';
import {
  EnquiryPutService, EnquiryPutOptions, EnquiryPutResponse, EnquiryPutResults,
} from './enquiry/update-enquiry/update-enquiry.service.types';
import { EnquiryPutTransform } from './enquiry/update-enquiry/update-enquiry.transform';
import { GenTokenRequest } from './gen-token/gen-token.service';
import {
  GenTokenService, GenTokenOptions, GenTokenResponse, GenTokenResults,
} from './gen-token/gen-token.service.types';
import { GenTokenTransform } from './gen-token/gen-token.transform';
import { ProfileRequest } from './get-profile/get-profile.service';
import {
  ProfileService, ProfileOptions, ProfileResponse, ProfileResults,
} from './get-profile/get-profile.service.types';
import { ProfileTransform } from './get-profile/get-profile.transform';
import { PostProjectsRequest } from './projects/add-projects/post-projects.service';
import {
  PostProjectsService, PostProjectsOptions, PostProjectsResponse, PostProjectsResults,
} from './projects/add-projects/post-projects.service.types';
import { PostProjectsTransform } from './projects/add-projects/post-projects.transform';
import { DeleteProjImgRequest } from './projects/delete-proj-image/delete-projimage.service';
import {
  DeleteProjImgService, DeleteProjImgOptions, DeleteProjImgResponse, DeleteProjImgResults,
} from './projects/delete-proj-image/delete-projimage.service.types';
import { DeleteProjImgTransform } from './projects/delete-proj-image/delete-projimage.transform';
import { GetListProjImgRequest } from './projects/get-list-projects-images/getList-projimage.service';
import {
  GetListProjImgService, GetListProjImgOptions, GetListProjImgResponse, GetListProjImgResults,
} from './projects/get-list-projects-images/getList-projimage.service.types';
import { GetListProjImgTransform } from './projects/get-list-projects-images/getList-projimage.transform';
import { GetAllProjectsRequest } from './projects/list-projects/get-all-projects.service';
import {
  GetAllProjectsService, GetAllProjectsOptions, GetAllProjectsResponse, GetAllProjectsResults,
} from './projects/list-projects/get-all-projects.service.types';
import { GetAllProjectsTransform } from './projects/list-projects/get-all-projects.transform';
import { GetAllPubRESProjectsRequest } from './projects/list-pub-res-projects/get-all-pub-res-projects.service';
import {
  GetAllPubRESProjectsService, GetAllPubRESProjectsOptions, GetAllPubRESProjectsResponse, GetAllPubRESProjectsResults,
} from './projects/list-pub-res-projects/get-all-pub-res-projects.service.types';
import { GetAllPubRESProjectsTransform } from './projects/list-pub-res-projects/get-all-pub-res-projects.transform';
import { ProjectsPutRequest } from './projects/update-projects/update-projects.service';
import {
  ProjectsPutService, ProjectsPutOptions, ProjectsPutResponse, ProjectsPutResults,
} from './projects/update-projects/update-projects.service.types';
import { ProjectsPutTransform } from './projects/update-projects/update-projects.transform';
import { CreateProjImgRequest } from './projects/upload-proj-Image/create-projimage.service';
import {
  CreateProjImgService, CreateProjImgOptions, CreateProjImgResponse, CreateProjImgResults,
} from './projects/upload-proj-Image/create-projimage.service.types';
import { CreateProjImgTransform } from './projects/upload-proj-Image/create-projimage.transform';
import { createService } from './service';

const response = 'Data has been loaded successfully!';

export function loadData(): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
      if (response === undefined) {
        reject();
      }
    }, 500);
  });
}
export const contactUsService: ContactUsService = createService<ContactUsOptions, ContactUsResponse, ContactUsResults>(ContactUsPostRequest, ContactUsTransform);
export const genTokenService: GenTokenService = createService<GenTokenOptions, GenTokenResponse, GenTokenResults>(GenTokenRequest, GenTokenTransform);
export const profileService: ProfileService = createService<ProfileOptions, ProfileResponse, ProfileResults>(ProfileRequest, ProfileTransform);
export const createProjectsService: PostProjectsService = createService<PostProjectsOptions, PostProjectsResponse, PostProjectsResults>(PostProjectsRequest, PostProjectsTransform);
export const putProjectsService: ProjectsPutService = createService<ProjectsPutOptions, ProjectsPutResponse, ProjectsPutResults>(ProjectsPutRequest, ProjectsPutTransform);
export const projectListService: GetAllProjectsService = createService<GetAllProjectsOptions, GetAllProjectsResponse, GetAllProjectsResults>(GetAllProjectsRequest, GetAllProjectsTransform);
export const prodImgService: CreateProjImgService = createService<CreateProjImgOptions, CreateProjImgResponse, CreateProjImgResults>(CreateProjImgRequest, CreateProjImgTransform);
export const getListProjImgService: GetListProjImgService = createService<GetListProjImgOptions, GetListProjImgResponse, GetListProjImgResults>(GetListProjImgRequest, GetListProjImgTransform);
export const deleteProjImgService: DeleteProjImgService = createService<DeleteProjImgOptions, DeleteProjImgResponse, DeleteProjImgResults>(DeleteProjImgRequest, DeleteProjImgTransform);
export const projectPubResListService: GetAllPubRESProjectsService = createService<GetAllPubRESProjectsOptions, GetAllPubRESProjectsResponse, GetAllPubRESProjectsResults>(GetAllPubRESProjectsRequest, GetAllPubRESProjectsTransform);
export const createEnquiryService: PostEnquiryService = createService<PostEnquiryOptions, PostEnquiryResponse, PostEnquiryResults>(PostEnquiryRequest, PostEnquiryTransform);
export const enquiryListService: GetAllEnquiryService = createService<GetAllEnquiryOptions, GetAllEnquiryResponse, GetAllEnquiryResults>(GetAllEnquiryRequest, GetAllEnquiryTransform);
export const putEnquiryService: EnquiryPutService = createService<EnquiryPutOptions, EnquiryPutResponse, EnquiryPutResults>(EnquiryPutRequest, EnquiryPutTransform);
