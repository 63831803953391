import { PostProjectsResponse, PostProjectsResults } from './post-projects.service.types';

export const PostProjectsTransform: (response: PostProjectsResponse) => PostProjectsResults = (createPostResults) => {
  const { data, is_error, message } = createPostResults;
  const result: PostProjectsResults = {
    message: '',
    error: false,
    data: '',
    projId: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
    result.projId = data.id;
  }
  return result;
};
