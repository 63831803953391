import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import { AddProjects } from './add-project';
import GetAllProjects from './get-all-projects';

export const ProjectComponent: React.FC = () => {
  const [isProjectAdd, setIsProjectAdd] = React.useState(false);

  const handleAddProjectInfo = () => {
    setIsProjectAdd(true);
  };

  const handleListProjectInfo = () => {
    setIsProjectAdd(false);
  };

  return (
    <div className="pt-1">
      <div className="align-end mr">
        {
                !isProjectAdd
                  ? (
                    <Button className="button-filled" onClick={handleAddProjectInfo}>
                      Add New Project
                    </Button>
                  )
                  : (
                    <Button className="button-open" onClick={handleListProjectInfo}>
                      <FontAwesomeIcon icon={faArrowLeft} className="mx-2" /> Back
                    </Button>
                  )
            }
      </div>
      {
                isProjectAdd
                  ? <AddProjects setIsProjectAdd={setIsProjectAdd} />
                  : <GetAllProjects />
            }

    </div>
  );
};

export default ProjectComponent;
