import {
  getLogin,
} from '../../utils';
import { getConfig } from '../../utils/config/config';
import { GenTokenOptions, GenTokenResponse } from './gen-token.service.types';

const { BUYPERSQFT_API_ROOT } = getConfig();

export const GenTokenRequest = ({
  userType, userName, requestType, passKey,
}: GenTokenOptions): Promise<GenTokenResponse> => getLogin(`${BUYPERSQFT_API_ROOT}?userType=${userType}`, requestType, userName, passKey);
