import { combineEpics, Epic } from 'redux-observable';
import {
  isActionOf, RootAction, RootState, Services,
} from 'typesafe-actions';
import { from } from 'rxjs';
import {
  filter, map, mergeMap, catchError,
} from 'rxjs/operators';
import {
  apiError, contactUsPostReq, createEnquiryReq, createProjectsReq, deleteProjImg, getEnquiryListReq, getListProjImg, getLoginToken, getProfile, getProjectsListReq, getPubRESProjectsListReq, putEnquiryReq, putProjectsReq, updateContactUsPostReq,
  updateCreateEnquiryReq,
  updateCreateProjectsReq,
  updateDeleteProjImg,
  updateEnquiryListReq,
  updateGetListProjImg,
  updateProfile,
  updateProjectsListReq,
  updatePubRESProjectsListReq,
  updatePutEnquiryReq,
  updatePutProjectsReq,
  updateToken,
  updateUploadProjImg,
  uploadProjImg,
} from './actions';
import { ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import { GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { PostProjectsResults } from '../../services/projects/add-projects/post-projects.service.types';
import { ProjectsPutResults } from '../../services/projects/update-projects/update-projects.service.types';
import { DeleteProjImgResults } from '../../services/projects/delete-proj-image/delete-projimage.service.types';
import { GetListProjImgResults } from '../../services/projects/get-list-projects-images/getList-projimage.service.types';
import { CreateProjImgResults } from '../../services/projects/upload-proj-Image/create-projimage.service.types';
import { GetAllProjectsResults } from '../../services/projects/list-projects/get-all-projects.service.types';
import { GetAllPubRESProjectsResults } from '../../services/projects/list-pub-res-projects/get-all-pub-res-projects.service.types';
import { PostEnquiryResults } from '../../services/enquiry/add-enquiry/post-enquiry.service.types';
import { GetAllEnquiryResults } from '../../services/enquiry/get-all-enquiry/get-all-enquiry.service.types';
import { EnquiryPutResults } from '../../services/enquiry/update-enquiry/update-enquiry.service.types';

export const contactUsPOSTEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(contactUsPostReq)),
  mergeMap((action) => from(buyPerSqFtService.contactUsService(action.payload)).pipe(
    map((response : ContactUsResults) => updateContactUsPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'buyPerSqFeet Contact Enquiry', errorMessage: error.message })),
  )),
);
export const generateTokenEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(getLoginToken)),
  mergeMap((action) => from(buyPerSqFtService.genTokenService(action.payload)).pipe(
    map((response : GenTokenResults) => updateToken(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Login', errorMessage: error.message })),
  )),
);
export const getProfileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(getProfile)),
  mergeMap((action) => from(buyPerSqFtService.profileService(action.payload)).pipe(
    map((response : ProfileResults) => updateProfile(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Profile', errorMessage: error.message })),
  )),
);

export const addProjectsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(createProjectsReq)),
  mergeMap((action) => from(buyPerSqFtService.createProjectsService(action.payload)).pipe(
    map((response : PostProjectsResults) => updateCreateProjectsReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Create New Projects', errorMessage: error.message })),
  )),
);

export const putProjectsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(putProjectsReq)),
  mergeMap((action) => from(buyPerSqFtService.putProjectsService(action.payload)).pipe(
    map((response : ProjectsPutResults) => updatePutProjectsReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Projects Update', errorMessage: error.message })),
  )),
);

export const uploadProjImgEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(uploadProjImg)),
  mergeMap((action) => from(buyPerSqFtService.prodImgService(action.payload)).pipe(
    map((response : CreateProjImgResults) => updateUploadProjImg(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Upload Proj Image', errorMessage: error.message })),
  )),
);

export const getListProjImgEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(getListProjImg)),
  mergeMap((action) => from(buyPerSqFtService.getListProjImgService(action.payload)).pipe(
    map((response : GetListProjImgResults) => updateGetListProjImg(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get List Proj Image', errorMessage: error.message })),
  )),
);

export const deleteProjImgEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(deleteProjImg)),
  mergeMap((action) => from(buyPerSqFtService.deleteProjImgService(action.payload)).pipe(
    map((response : DeleteProjImgResults) => updateDeleteProjImg(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Delete Proj Image', errorMessage: error.message })),
  )),
);

export const getAllProjectsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(getProjectsListReq)),
  mergeMap((action) => from(buyPerSqFtService.projectListService(action.payload)).pipe(
    map((response : GetAllProjectsResults) => updateProjectsListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Projects', errorMessage: error.message })),
  )),
);

export const getAllPubRESProjectsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(getPubRESProjectsListReq)),
  mergeMap((action) => from(buyPerSqFtService.projectPubResListService(action.payload)).pipe(
    map((response : GetAllPubRESProjectsResults) => updatePubRESProjectsListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Publis Residential Projects', errorMessage: error.message })),
  )),
);

export const addEnquiryEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(createEnquiryReq)),
  mergeMap((action) => from(buyPerSqFtService.createEnquiryService(action.payload)).pipe(
    map((response : PostEnquiryResults) => updateCreateEnquiryReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Create New Enquiry', errorMessage: error.message })),
  )),
);

export const getAllEnquiryEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(getEnquiryListReq)),
  mergeMap((action) => from(buyPerSqFtService.enquiryListService(action.payload)).pipe(
    map((response : GetAllEnquiryResults) => updateEnquiryListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Enquiry', errorMessage: error.message })),
  )),
);

export const putEnquiryEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { buyPerSqFtService }) => action$.pipe(
  filter(isActionOf(putEnquiryReq)),
  mergeMap((action) => from(buyPerSqFtService.putEnquiryService(action.payload)).pipe(
    map((response : EnquiryPutResults) => updatePutEnquiryReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Enquiry Update', errorMessage: error.message })),
  )),
);

export const buyPerSqFtEpics = combineEpics(
  contactUsPOSTEpic,
  generateTokenEpic,
  getProfileEpic,
  addProjectsEpic,
  putProjectsEpic,
  uploadProjImgEpic,
  getListProjImgEpic,
  deleteProjImgEpic,
  getAllProjectsEpic,
  getAllPubRESProjectsEpic,
  addEnquiryEpic,
  getAllEnquiryEpic,
  putEnquiryEpic,
);
