import React from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, CardBody, CardImg, Button, Input,
  CardHeader,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
} from 'reactstrap';
import {
  faAngleLeft,
  faAngleRight,
  faCar,
  faCircleXmark,
  faCity,
  faCube,
  faFilter,
  faHouse,
  faMagnifyingGlass,
  faRectangleXmark,
  faRug,
  faRulerCombined,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errorAlert, successAlert, toPascalSnakeCase } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import {
  APIHeader,
  CommPropertyTypeList,
  LOCOptions,
  ResidentPropertyTypeList,
} from '../../../../utils/constants';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import { ProjectsResult } from '../../../../services/projects/list-projects/get-all-projects.types';
import {
  clearProjectsListReq, clearPutProjectsReq, getProjectsListReq, putProjectsReq,
} from '../../../../store/buypersqft/actions';
import { ProjectsEditData, ResConfiguration } from '../../../../services/projects/update-projects/update-projects.types';
import { ProjectTypeMeta } from '../../../../services/projects/add-projects/post-projects.types';

export const GetAllProjects: React.FC = () => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const tokenData = getTokenFromSessionStorage();
  const [loaderAddProjects, setLoaderAddProjects] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  // const [loadingMore, setLoadingMore] = React.useState(false);
  const [openModalProj, setOpenModalProj] = React.useState<Record<string, boolean>>({});
  const [projectsUpdateInput, setProjectsUpdateInput] = React.useState<ProjectsEditData>({
    ProjTp: '',
    ProjId: '',
    nme: '',
    builder: '',
    rera: '',
    projCat: [],
    leaseTp: '',
    budget: '',
    loc: '',
    sellAr: 0,
    carpAr: 0,
    carPark: 0,
    isMovin: '',
    resConfig: [],
  });
  const [projectsData, setProjectsData] = React.useState<ProjectsResult>({
    tCnt: 0,
    fCnt: 0,
    projData: [],
    lastEvalKey: {
      ProjTp: '',
      ProjId: '',
    },
  });
  const [currentImageIndexes, setCurrentImageIndexes] = React.useState<number[]>([]);
  const listProjects = useSelector((state: RootState) => state.buypersqft.getProjectsList);
  const updateProjectsResponse = useSelector((state: RootState) => state.buypersqft.updateProjects);
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  // const [radioSelected, setRadioSelected] = React.useState('');
  const [projTypeData, setProjTypeData] = React.useState('COMM');
  const [whichProjIndex, setWhichProjIndex] = React.useState('');
  const [projCategoryData, setProjCategoryData] = React.useState('');
  const [selectGroupProjCat, setSelectGroupProjCat] = React.useState('COMM');
  const [projectCategoryOptions, setProjectCategoryOptions] = React.useState<ProjectTypeMeta[]>([]);
  const [currentConfig, setCurrentConfig] = React.useState<ResConfiguration>({
    resTp: '',
    carpAr: '',
    sellAr: '',
    carPark: 0,
  });
  const [isFilterQuery, setIsFilterQuery] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [isLastObject, setIsLastObject] = React.useState(false);

  // const [filterProject, setFilterProject] = React.useState<FilterProject>({
  //   carPark: '',
  // });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  React.useEffect(() => {
    if (evalKeyText === '' && evalTpText === '' && userTp !== '') {
      setLoaderAddProjects(true);
      setProjectsData({
        ...projectsData,
        tCnt: 0,
        fCnt: 0,
        projData: [],
        lastEvalKey: {
          ProjTp: '',
          ProjId: '',
        },
      });
      dispatch(getProjectsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: projTypeData,
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        isPublic: false,
      }));
    }
  }, [evalKeyText, evalTpText, userTp]);

  const fetchMoreData = () => {
    setLoaderAddProjects(true);
    if (isFilterQuery !== '') {
      dispatch(
        getProjectsListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_PROJECTS,
          limit: 12, // ApiLIMIT.LIMIT,
          projTp: selectGroupProjCat,
          isFilter: 'YES',
          evalKey: evalKeyText,
          evalTp: evalTpText,
          filterBy: isFilterQuery,
          searchBy: searchText,
          isSearch: 'NO',
          token: tokenData,
          isPublic: false,
        }),
      );
    } else if (isSearchQuery !== '') {
      dispatch(
        getProjectsListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_PROJECTS,
          limit: 12, // ApiLIMIT.LIMIT,
          projTp: selectGroupProjCat,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          searchBy: searchText,
          isSearch: 'YES',
          token: tokenData,
          isPublic: false,
        }),
      );
    } else {
      dispatch(
        getProjectsListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_PROJECTS,
          projTp: projTypeData,
          limit: 12, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
          isPublic: false,
        }),
      );
    }
  };

  const handleProjType = (e: any) => {
    setProjectsData({
      ...projectsData,
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    });
    setIsLastObject(false);
    setProjTypeData(e.target.name);
    setLoaderAddProjects(true);
    dispatch(
      getProjectsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: e.target.name,
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        isPublic: false,
      }),
    );
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearchFilter = (filtVal: string) => {
    setSelectGroupProjCat(filtVal);
    setIsLastObject(false);
  };

  const handleSearchProjects = () => {
    setLoaderAddProjects(true);
    setIsFilterQuery('');
    setProjTypeData('');
    setIsLastObject(false);
    setIsSearchQuery(searchText);
    setProjectsData({
      ...projectsData,
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    });
    dispatch(
      getProjectsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        limit: 12, // ApiLIMIT.LIMIT,
        projTp: selectGroupProjCat,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        isPublic: false,
      }),
    );
  };

  const handleFilterProjects = (filterData: string) => {
    setLoaderAddProjects(true);
    setIsFilterQuery(filterData);
    setProjTypeData('');
    setIsLastObject(false);
    setIsSearchQuery('');
    setProjectsData({
      ...projectsData,
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    });
    dispatch(
      getProjectsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        limit: 12, // ApiLIMIT.LIMIT,
        projTp: selectGroupProjCat,
        isFilter: 'YES',
        evalKey: '',
        evalTp: '',
        filterBy: filterData,
        searchBy: searchText,
        isSearch: 'NO',
        token: tokenData,
        isPublic: false,
      }),
    );
  };

  const nextImage = (cardIndex: number) => {
    if (projectsData.projData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] + 1) % projectsData.projData[cardIndex].projUrl.length;
        return newIndexes;
      });
    }
  };

  const prevImage = (cardIndex: number) => {
    if (projectsData.projData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] - 1 + projectsData.projData[cardIndex].projUrl.length) % projectsData.projData[cardIndex].projUrl.length;
        return newIndexes;
      });
    }
  };

  React.useEffect(() => {
    if (listProjects.isError && listProjects.message !== '') {
      setLoaderAddProjects(false);
      setAlertMsg(listProjects.message);
      setErrorShowAlert(true);
    }
  }, [listProjects.isError, listProjects.message]);

  React.useEffect(() => {
    if (!listProjects.isError && listProjects.message === 'executed') {
      setLoaderAddProjects(false);
      if (listProjects.data && listProjects.data.lastEvalKey !== null) {
        // Append new data to existing data
        const newData = (listProjects.data.projData || []).filter(
          (item) => !projectsData.projData.some((existingItem) => existingItem.ProjId === item.ProjId),
        );
        setProjectsData((prevData) => ({
          ...prevData,
          tCnt: listProjects.data.tCnt,
          fCnt: listProjects.data.fCnt,
          projData: [...prevData.projData, ...newData],
          lastEvalKey: listProjects.data.lastEvalKey,
        }));
        setEvalKeyText(listProjects.data.lastEvalKey.ProjId);
        setEvalTpText(listProjects.data.lastEvalKey.ProjTp);
        setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...newData.map(() => 0)]);
      } else if (listProjects.data.lastEvalKey === null) {
        // Handle case where there is no more data to fetch
        setProjectsData((prevData) => ({
          ...prevData,
          tCnt: listProjects.data.tCnt,
          fCnt: listProjects.data.fCnt,
          projData: [...prevData.projData, ...listProjects.data.projData],
        }));
        setIsLastObject(true);
        setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...listProjects.data.projData.map(() => 0)]);
      } else {
        setProjectsData((prevData) => ({
          ...prevData,
          tCnt: listProjects.data.tCnt,
          fCnt: listProjects.data.fCnt,
          projData: [...prevData.projData, ...listProjects.data.projData],
        }));
        setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...listProjects.data.projData.map(() => 0)]);
      }
    }
  }, [listProjects.isError, listProjects.message, listProjects.data.projData, listProjects.data.lastEvalKey, evalKeyText]);

  //   const handleClearAll = () => {
  //     setRadioSelected('');
  //     setSearchText('');
  //     setEvalKeyText('');
  //     setEvalTpText('');
  //     setProjectsData({
  //       tCnt: 0,
  //       fCnt: 0,
  //       projData: [],
  //       lastEvalKey: {
  //         ProjTp: '',
  //         ProjId: '',
  //       },
  //     });
  //     if (userTp !== '') {
  //       dispatch(getProjectsListReq({
  //         userType: userTp,
  //         projTp: projTypeData,
  //         requestType: APIHeader.REQ_GET_ALL_PROJECTS,
  //         limit: ApiLIMIT.LIMIT,
  //         evalKey: '',
  //         evalTp: '',
  //         isFilter: 'NO',
  //         isSearch: 'NO',
  //         token: tokenData,
  //       }));
  //     }
  //   };
  const handleFieldUpdateChange = (e:any) => {
    const fieldName = e.target.name;
    setProjectsUpdateInput({ ...projectsUpdateInput, [fieldName]: e.target.value });
  };

  const projToggleModal = (target: string, rowData: ProjectsEditData) => {
    setWhichProjIndex(target);
    setOpenModalProj((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setProjectsUpdateInput({
      ...projectsUpdateInput,
      ProjTp: rowData.ProjTp ? rowData.ProjTp : '',
      ProjId: rowData.ProjId ? rowData.ProjId : '',
      nme: rowData.nme ? rowData.nme : '',
      builder: rowData.builder ? rowData.builder : '',
      rera: rowData.rera ? rowData.rera : '',
      projCat: rowData.projCat ? rowData.projCat : [],
      leaseTp: rowData.leaseTp ? rowData.leaseTp : '',
      budget: rowData.budget ? rowData.budget : '',
      loc: rowData.loc ? rowData.loc : '',
      sellAr: rowData.sellAr ? rowData.sellAr : 0,
      carpAr: rowData.carpAr ? rowData.carpAr : 0,
      carPark: rowData.carPark ? rowData.carPark : 0,
      isMovin: rowData.isMovin ? rowData.isMovin : '',
      resConfig: rowData.resConfig ? rowData.resConfig : [],
    });
  };

  // Project Filter Options Start
  const projCategoryCOMMOptions = CommPropertyTypeList.map((comm) => ({
    label: comm.label,
    value: comm.value,
  }));

  const selectedProjCategoryCOMMOptions = projCategoryCOMMOptions && projCategoryCOMMOptions.find(
    (option) => option.value === projCategoryData,
  );

  const projCategoryRESOptions = ResidentPropertyTypeList.map((res) => ({
    label: res.label,
    value: res.value,
  }));

  const selectedProjCategoryRESOptions = projCategoryRESOptions && projCategoryRESOptions.find(
    (option) => option.value === projCategoryData,
  );

  const projResConfigOptions = ResidentPropertyTypeList.map((res) => ({
    label: res.label,
    value: res.value,
  }));

  const selectedProjResConfigOptionsOptions = projResConfigOptions && projResConfigOptions.find(
    (option) => option.value === currentConfig.resTp,
  );

  // Project Filter Options END

  // Project Location Options Start
  const locOptions = LOCOptions.map((loc) => ({
    label: loc.label,
    value: loc.value,
  }));

  const selectedLocOptions = locOptions && locOptions.find(
    (option) => option.label === projectsUpdateInput.loc,
  );

  // Project Location Options END

  const handleSelectUpdateProjCategory = (e: any) => {
    const selectedValue = e.value;
    const selectedLabel = e.label;
    setProjCategoryData(e.value);
    if (!projectCategoryOptions.some((option) => option.label === selectedLabel)) {
      if (!projectsUpdateInput.projCat.includes(selectedValue)) {
        setProjectsUpdateInput({ ...projectsUpdateInput, projCat: [...projectsUpdateInput.projCat, selectedValue] });

        const updatedLabel: ProjectTypeMeta = {
          label: selectedLabel,
          value: selectedValue,
        };
        setProjectCategoryOptions([...projectCategoryOptions, updatedLabel]);
      }
    }
  };

  const handleSelectUpdateProjConfig = (selectedOption: any) => {
    setCurrentConfig({ ...currentConfig, resTp: selectedOption.value });
  };

  const handleNumberUpdateConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentConfig({ ...currentConfig, [name]: value });
  };

  const handleRemoveConfig = (index: number) => {
    setProjectsUpdateInput((prevState) => ({
      ...projectsUpdateInput,
      resConfig: prevState.resConfig.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveProjType = (projCat: string) => {
    setProjectsUpdateInput((prevData) => ({
      ...prevData,
      projCat: prevData.projCat.filter(
        (projVal) => projVal !== projCat,
      ),
    }));
  };
  const handleSelectUpdateLoc = (e: any) => {
    setProjectsUpdateInput({ ...projectsUpdateInput, loc: e.label });
  };

  const handleNumberChange = (e:any) => {
    const { value } = e.target;
    const cntValue = value === '' ? 0 : parseInt(value);
    if (cntValue > 0) {
      setProjectsUpdateInput({ ...projectsUpdateInput, [e.target.name]: Number(cntValue) });
    } else {
      setProjectsUpdateInput({ ...projectsUpdateInput, [e.target.name]: Number(0) });
    }
  };

  const handleProjLease = (e: any) => {
    setProjectsUpdateInput({ ...projectsUpdateInput, leaseTp: e.target.name });
  };

  const handleProjIsMovin = (e: any) => {
    setProjectsUpdateInput({ ...projectsUpdateInput, isMovin: e.target.name });
  };

  const handleAddUpdateConfig = () => {
    setProjectsUpdateInput((prevState) => ({
      ...projectsUpdateInput,
      resConfig: [...prevState.resConfig, currentConfig],
    }));
    setCurrentConfig({
      resTp: '',
      carpAr: '',
      sellAr: '',
      carPark: 0,
    });
  };

  const handleProjectsUpdate = () => {
    setLoaderAddProjects(true);
    dispatch(clearProjectsListReq());
    setProjectsData({
      ...projectsData,
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    });
    Promise.resolve(
      dispatch(putProjectsReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PROJECT,
        inputBody: projectsUpdateInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (updateProjectsResponse.error && updateProjectsResponse.message !== '') {
      setLoaderAddProjects(false);
      setAlertMsg(updateProjectsResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateProjectsResponse.error, updateProjectsResponse.message]);

  React.useEffect(() => {
    if (!updateProjectsResponse.error && updateProjectsResponse.message !== '') {
      setLoaderAddProjects(false);
      setAlertMsg(updateProjectsResponse.message);
      dispatch(clearPutProjectsReq());
      dispatch(getProjectsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: projTypeData,
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        isPublic: false,
      }));
      setOpenModalProj((prevState) => ({
        ...prevState,
        [whichProjIndex]: false,
      }));
    }
  }, [updateProjectsResponse.error, updateProjectsResponse.message]);

  return (
    <div>
      { loaderAddProjects ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row>
        <Col lg={{ size: 3, order: 1 }} xs={{ order: 1 }}>
          <div className="pt-3 ml-1">
            <Card className="card-lite">
              <CardHeader className="bg-header">
                <div className="pt-1">
                  <span className="listing-price-text">Project Type<FontAwesomeIcon icon={faFilter} className="ml" /></span>
                  <div className="pt-2">
                    <FormGroup>
                      <Label check className="all-text">
                        <Input
                          type="radio"
                          role="switch"
                          name="COMM"
                          onChange={handleProjType}
                          checked={projTypeData === 'COMM'}
                          className="buy-check-box mr-1 cursor-pointer"
                        />
                        Commercial
                      </Label>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup>
                      <Label check className="all-text">
                        <Input
                          type="radio"
                          role="switch"
                          name="RES"
                          onChange={handleProjType}
                          checked={projTypeData === 'RES'}
                          className="buy-check-box mr-1 cursor-pointer"
                        />
                        Residentials
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="pt-1" />
                </div>
              </CardHeader>
              <CardBody>
                <Row className="proj-search-box">
                  <Col lg="12">
                    <ButtonGroup className="align-center pt-3">
                      <Button
                        outline
                        onClick={() => handleSearchFilter('COMM')}
                        active={selectGroupProjCat === 'COMM'}
                        className={selectGroupProjCat === 'COMM' ? 'button-filled' : 'button-open'}
                      >
                        COMM <FontAwesomeIcon icon={faHouse} />
                      </Button>
                      <Button
                        outline
                        onClick={() => handleSearchFilter('RES')}
                        active={selectGroupProjCat === 'RES'}
                        className={selectGroupProjCat === 'RES' ? 'button-filled mx-1' : 'button-open mx-1'}
                      >
                        RES <FontAwesomeIcon icon={faCity} />
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col lg="12" className="pt-3">
                    <Input
                      type="text"
                      className="buy-input"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                    <span className="text-super-lite">Search by Project Name, Builder, Location</span>
                  </Col>
                  <Col lg="12">
                    <div className="align-center pt-3 mb-2">
                      <Button
                        className="button-filled"
                        onClick={handleSearchProjects}
                      >
                        SEARCH <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </div>
                    <hr />
                  </Col>
                  <Col xs="12">
                    <div className="my-2">
                      <span className="listing-price-text">Project Configuration<FontAwesomeIcon icon={faFilter} className="ml" /></span>
                    </div>
                    <div className="pt-2">
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'COMM'}
                            onChange={() => handleFilterProjects('1BHK')}
                            checked={isFilterQuery === '1BHK'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          1 BHK
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'COMM'}
                            onChange={() => handleFilterProjects('2BHK')}
                            checked={isFilterQuery === '2BHK'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          2 BHK
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'COMM'}
                            onChange={() => handleFilterProjects('3BHK')}
                            checked={isFilterQuery === '3BHK'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          3 BHK
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'COMM'}
                            onChange={() => handleFilterProjects('4BHK')}
                            checked={isFilterQuery === '4BHK'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          4 BHK
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'COMM'}
                            onChange={() => handleFilterProjects('PNTH')}
                            checked={isFilterQuery === 'PNTH'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          PentHouse
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'COMM'}
                            onChange={() => handleFilterProjects('ROWH')}
                            checked={isFilterQuery === 'ROWH'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          Row House
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'COMM'}
                            onChange={() => handleFilterProjects('STUDA')}
                            checked={isFilterQuery === 'STUDA'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          Studio Appartment
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'RES'}
                            onChange={() => handleFilterProjects('RSHOP')}
                            checked={isFilterQuery === 'RSHOP'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          Retail Shops
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'RES'}
                            onChange={() => handleFilterProjects('OFFSP')}
                            checked={isFilterQuery === 'OFFSP'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          Office Spaces
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'RES'}
                            onChange={() => handleFilterProjects('FB')}
                            checked={isFilterQuery === 'FB'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          F&B
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Label check className="all-text">
                          <Input
                            type="radio"
                            role="switch"
                            disabled={selectGroupProjCat === 'RES'}
                            onChange={() => handleFilterProjects('RTRES')}
                            checked={isFilterQuery === 'RTRES'}
                            className="buy-check-box mr-1 cursor-pointer"
                          />
                          Roof Top Resturants
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter className="border-up-listing">
                <div className="my-2 align-end">
                  <Button className="button-open" onClick={handleClearAll}>
                    clear all
                  </Button>
                </div>
              </CardFooter> */}
            </Card>
          </div>
        </Col>
        <Col lg={{ size: 9, order: 2 }} xs={{ order: 2 }}>
          {/* <span className="text-lite-grey mx-2">Count: {projectsData && projectsData.projData.length} / {projectsData.tCnt}</span> */}
          <Row className="margin-top-des pt-3 mr">
            {projectsData && projectsData.projData.length > 0
              ? projectsData.projData.map((projDet, index) => (
                <Col lg="12" key={`proj-${index.toString()}`} className="my-2">
                  <Card className="card-lite">
                    <CardBody className="padding-zero">
                      <Row>
                        <Col lg="3">
                          <div className="admin-card-image-container pd pt-2">
                            {projDet.projUrl.length > 1 && (
                              <Button className="image-navigation-button left" onClick={() => prevImage(index)}>
                                <FontAwesomeIcon icon={faAngleLeft} className="round-small-icon" />
                              </Button>
                            )}
                            <CardImg
                              src={projDet.projUrl[currentImageIndexes[index]]}
                              alt={`Card-${index}`}
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src = '/images/home/broken-image.png';
                              }}
                            />
                            {projDet.projUrl.length > 1 && (
                              <Button className="image-navigation-button right" onClick={() => nextImage(index)}>
                                <FontAwesomeIcon icon={faAngleRight} className="round-small-icon" />
                              </Button>
                            )}
                          </div>
                        </Col>
                        <Col lg="7">
                          <Row className="my-1">
                            <Col lg="12">
                              <div className="align-center">
                                {projDet.isMovin === 'YES' ? <span className="text-whatsapp">Ready to Move</span> : <span className="warning-text">Possession Upcoming</span>}
                              </div>
                              <div className="align-center">
                                <span className="buy-subtext">
                                  {projDet.nme}
                                </span>
                              </div>
                              <div className="align-center">
                                <span className="text-lite-grey-grey">
                                  {toPascalSnakeCase(projDet.loc)}
                                </span>
                              </div>
                              <div className="align-center">
                                <span className="text-link pt-1">RERA : {projDet.rera}</span>
                              </div>
                            </Col>
                            <Col lg="12">
                              <Row>
                                <Col lg="12">
                                  <div className="align-start">
                                    {projDet.projCat.map((projCatDet, catIndex) => (
                                      <span className="prod-cat-bg mx-1" key={`${catIndex.toString()}`}>
                                        { CommPropertyTypeList.find((comm) => comm.value === projCatDet)?.label || ''}
                                        { ResidentPropertyTypeList.find((res) => res.value === projCatDet)?.label || ''}
                                      </span>
                                    ))}
                                  </div>
                                  <div className="align-end">
                                    {projDet.resConfig.map((resConfigtDet, resIndex) => (
                                      <div className="mx-1" key={`${resIndex.toString()}`}>
                                        <span className="box-buy-cat">
                                          <FontAwesomeIcon icon={faRug} className="mx-1" />{resConfigtDet.carpAr} sqft
                                          <span className="mx-1 icon-buy-small">|</span>
                                          <FontAwesomeIcon icon={faRulerCombined} className="mx-1" />{resConfigtDet.sellAr} sqft
                                          <span className="mx-1 icon-buy-small">|</span>
                                          <FontAwesomeIcon icon={faCar} className="mx-1" />{resConfigtDet.carPark}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                                <Col lg="12">
                                  <div className="align-end">
                                    {projDet.ProjTp === 'COMM'
                                      ? (
                                        <span className="box-buy-cat mx-1">
                                          <FontAwesomeIcon icon={faCar} className="mx-1" />
                                          {projDet.carPark}
                                        </span>
                                      ) : null}
                                    {projDet.ProjTp === 'COMM'
                                      ? (
                                        <span className="box-buy-cat mx-1">
                                          <FontAwesomeIcon icon={faRug} className="mx-1" />{projDet.carpAr} sqft
                                        </span>
                                      )
                                      : null}
                                    {projDet.ProjTp === 'COMM'
                                      ? <span className="box-buy-cat mx-1"><FontAwesomeIcon icon={faRulerCombined} className="mx-1" />{projDet.sellAr} sqft</span>
                                      : null}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg="2">
                          <div className="align-end pd">
                            {projDet.leaseTp === 'LEASE'
                              ? <span className="prod-lease-bg">LEASE</span>
                              : projDet.leaseTp === 'OTRHT'
                                ? <span className="prod-lease-bg">OUT RIGHT</span>
                                : projDet.leaseTp === 'PLSE'
                                  ? <span className="prod-lease-bg">PRE LEASE</span> : null}
                          </div>
                          <div className="align-end pt-3 mr">
                            <span className="buy-subtext">
                              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(projDet.budget))}
                            </span>
                            <span className="listing-price-text ml">Onward</span>
                          </div>
                          <div className={projDet.ProjTp === 'RES' ? 'align-end pt-5 my-1 mr' : 'align-end pt-4 my-1 mr'}>
                            <Button className="button-filled" onClick={() => projToggleModal(`projDet-${index}`, projDet)}>
                              EDIT DATA
                            </Button>
                          </div>
                          <Modal
                            isOpen={openModalProj[`projDet-${index}`] || false}
                            target={`projDet-${index}`}
                          >
                            <ModalHeader toggle={() => projToggleModal(`projDet-${index}`, projDet)} className="form-card-border-none">
                              <span className="text-lite-grey">{projDet.nme}</span>
                            </ModalHeader>
                            <ModalBody className="padding-zero mb-4">
                              <Row className="mx-1">
                                <Col xs="12" className="pt-1">
                                  <h6 className="text-lite-grey mx-2 my-1">Project Name<span className="mandate-star">*</span></h6>
                                  <Input
                                    placeholder="Enter product name"
                                    type="text"
                                    required
                                    name="nme"
                                    value={projectsUpdateInput.nme}
                                    onChange={handleFieldUpdateChange}
                                    className="buy-input"
                                  />
                                </Col>
                                <Col lg="12" className="pt-4">
                                  <Row>
                                    <Col lg="7">
                                      <h6 className="text-lite-grey mx-2 my-1">Builder Name<span className="mandate-star">*</span></h6>
                                      <Input
                                        placeholder="Enter Builder Name"
                                        type="text"
                                        required
                                        name="builder"
                                        value={projectsUpdateInput.builder}
                                        onChange={handleFieldUpdateChange}
                                        className="buy-input"
                                      />
                                    </Col>
                                    <Col lg="5">
                                      <h6 className="text-lite-grey mx-2 my-1">Budget<span className="mandate-star">*</span></h6>
                                      <Input
                                        placeholder="Budget"
                                        required
                                        name="budget"
                                        value={projectsUpdateInput.budget}
                                        onChange={handleFieldUpdateChange}
                                        className="buy-input"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg="12" className="pt-4">
                                  <h6 className="text-lite-grey mx-2 my-1">RERA<span className="mandate-star">*</span></h6>
                                  <Input
                                    placeholder="Enter RERA Details"
                                    type="text"
                                    required
                                    name="rera"
                                    value={projectsUpdateInput.rera}
                                    onChange={handleFieldUpdateChange}
                                    className="buy-input"
                                  />
                                </Col>
                                <Col lg="12" className="pt-4">
                                  <h6 className="text-lite-grey mx-2 my-1">Projects Category<span className="mandate-star">*</span></h6>
                                  {projectsUpdateInput.ProjTp === 'COMM'
                                    ? (
                                      <Select
                                        value={selectedProjCategoryCOMMOptions}
                                        options={projCategoryCOMMOptions}
                                        onChange={(e) => handleSelectUpdateProjCategory(e)}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (base) => ({
                                            ...base,
                                            // position: 'relative',
                                          }),
                                          control: (base: any) => ({
                                            ...base,
                                            '&:hover': { borderColor: '#05cbddff' },
                                            border: '1px solid #BFBFBF',
                                            minHeight: '45px',
                                            borderRadius: '5px',
                                            padding: 1,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#383838',
                                            boxShadow: 'none',
                                            backgroundColor: '#fff',
                                            '&:focus': {
                                              borderColor: '#E1EDF8',
                                              boxShadow: 'none',
                                              color: '#575656',
                                              background: '#fff',
                                            },
                                          }),
                                          option: (provided: any, state: { isSelected: any; }) => ({
                                            ...provided,
                                            color: state.isSelected ? '#383838' : '#212121',
                                            padding: 15,
                                            textAlign: 'left',
                                            background: state.isSelected ? '#FAFCFB' : '#fff',
                                            '&:hover': {
                                              background: '#FAFCFB',
                                            },
                                          }),
                                        }}
                                        noOptionsMessage={() => 'No Commercial Category Found'}
                                        placeholder="Select Commercial Category"
                                      />
                                    ) : (
                                      <Select
                                        value={selectedProjCategoryRESOptions}
                                        options={projCategoryRESOptions}
                                        onChange={(e) => handleSelectUpdateProjCategory(e)}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (base) => ({
                                            ...base,
                                            // position: 'relative',
                                          }),
                                          control: (base: any) => ({
                                            ...base,
                                            '&:hover': { borderColor: '#05cbddff' },
                                            border: '1px solid #BFBFBF',
                                            minHeight: '45px',
                                            borderRadius: '5px',
                                            padding: 1,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#383838',
                                            boxShadow: 'none',
                                            backgroundColor: '#fff',
                                            '&:focus': {
                                              borderColor: '#E1EDF8',
                                              boxShadow: 'none',
                                              color: '#575656',
                                              background: '#fff',
                                            },
                                          }),
                                          option: (provided: any, state: { isSelected: any; }) => ({
                                            ...provided,
                                            color: state.isSelected ? '#383838' : '#212121',
                                            padding: 15,
                                            textAlign: 'left',
                                            background: state.isSelected ? '#FAFCFB' : '#fff',
                                            '&:hover': {
                                              background: '#FAFCFB',
                                            },
                                          }),
                                        }}
                                          // isLoading={loaderState}
                                        noOptionsMessage={() => 'No Residential Category Found'}
                                        placeholder="Select Residential Category"
                                      />
                                    ) }
                                  <Row className="ml pt-2">
                                    {projectsUpdateInput.projCat && projectsUpdateInput.projCat.length > 0
                                      ? projectsUpdateInput.projCat.map((projVal, projCatIndex) => (
                                        <Col lg="6" key={`projCat-${projCatIndex.toString()}`} className="my-1 align-start">
                                          <div className="mr">
                                            <FontAwesomeIcon icon={faCube} className="icon-buy-small" />
                                          </div>
                                          <div>
                                            <span className="form-card-header">
                                              { CommPropertyTypeList.find((comm) => comm.value === projVal)?.label || ''}
                                              { ResidentPropertyTypeList.find((res) => res.value === projVal)?.label || ''}
                                            </span>
                                            <Button className="button-icon padding-zero ml mb-1" onClick={() => handleRemoveProjType(projVal)}>
                                              <FontAwesomeIcon icon={faRectangleXmark} className="wrong-pass-key" />
                                            </Button>
                                          </div>
                                        </Col>
                                      )) : null }
                                  </Row>
                                </Col>
                                {projectsUpdateInput.ProjTp === 'COMM'
                                  ? (
                                    <Col lg="12" className="pt-4">
                                      <h6 className="text-lite-grey mx-2 my-1">Lease Type<span className="mandate-star">*</span></h6>
                                      <Row className="ml">
                                        <Col lg="4">
                                          <Label check className="cat-check-text1">
                                            <Input
                                              type="radio"
                                              role="switch"
                                              name="LEASE"
                                              onChange={handleProjLease}
                                              checked={projectsUpdateInput.leaseTp === 'LEASE'}
                                              className="buy-check-box mr-1 cursor-pointer"
                                            />
                                            LEASE
                                          </Label>
                                        </Col>
                                        <Col lg="4">
                                          <Label check className="cat-check-text1">
                                            <Input
                                              type="radio"
                                              role="switch"
                                              name="OTRHT"
                                              onChange={handleProjLease}
                                              checked={projectsUpdateInput.leaseTp === 'OTRHT'}
                                              className="buy-check-box mr-1 cursor-pointer"
                                            />
                                            OUTRIGHT
                                          </Label>
                                        </Col>
                                        <Col lg="4">
                                          <Label check className="cat-check-text1">
                                            <Input
                                              type="radio"
                                              role="switch"
                                              name="PLSE"
                                              onChange={handleProjLease}
                                              checked={projectsUpdateInput.leaseTp === 'PLSE'}
                                              className="buy-check-box mr-1 cursor-pointer"
                                            />
                                            PRE LEASE
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : null }
                                <Col lg="12" className="pt-4">
                                  <h6 className="text-lite-grey mx-2 my-1">Project Progress<span className="mandate-star">*</span></h6>
                                  <Row className="ml">
                                    <Col lg="6">
                                      <Label check className="cat-check-text1">
                                        <Input
                                          type="radio"
                                          role="switch"
                                          name="YES"
                                          onChange={handleProjIsMovin}
                                          checked={projectsUpdateInput.isMovin === 'YES'}
                                          className="buy-check-box mr-1 cursor-pointer"
                                        />
                                        READY MOVE-IN
                                      </Label>
                                    </Col>
                                    <Col lg="6">
                                      <Label check className="cat-check-text1">
                                        <Input
                                          type="radio"
                                          role="switch"
                                          name="NO"
                                          onChange={handleProjIsMovin}
                                          checked={projectsUpdateInput.isMovin === 'NO'}
                                          className="buy-check-box mr-1 cursor-pointer"
                                        />
                                        IN PROGRESS
                                      </Label>
                                    </Col>
                                  </Row>
                                </Col>
                                {projectsUpdateInput.ProjTp === 'RES'
                                  ? (
                                    <Col lg="12" className="pt-4">
                                      <h6 className="text-lite-grey mx-2 my-1">Carpet Configuration<span className="mandate-star">*</span></h6>
                                      <Row className="proj-config-box">
                                        <Col lg="12">
                                          <Select
                                            value={selectedProjResConfigOptionsOptions}
                                            options={projResConfigOptions}
                                            onChange={(e) => handleSelectUpdateProjConfig(e)}
                                            menuPortalTarget={document.body}
                                            styles={{
                                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                              menu: (base) => ({
                                                ...base,
                                                // position: 'relative',
                                              }),
                                              control: (base: any) => ({
                                                ...base,
                                                '&:hover': { borderColor: '#05cbddff' },
                                                border: '1px solid #BFBFBF',
                                                minHeight: '45px',
                                                borderRadius: '5px',
                                                padding: 1,
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#383838',
                                                boxShadow: 'none',
                                                backgroundColor: '#fff',
                                                '&:focus': {
                                                  borderColor: '#E1EDF8',
                                                  boxShadow: 'none',
                                                  color: '#575656',
                                                  background: '#fff',
                                                },
                                              }),
                                              option: (provided: any, state: { isSelected: any; }) => ({
                                                ...provided,
                                                color: state.isSelected ? '#383838' : '#212121',
                                                padding: 15,
                                                textAlign: 'left',
                                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                                '&:hover': {
                                                  background: '#FAFCFB',
                                                },
                                              }),
                                            }}
                                            noOptionsMessage={() => 'No Residential Category Found'}
                                            placeholder="Select Residential Category"
                                          />
                                        </Col>
                                        <Col lg="4" className="pt-3">
                                          <h6 className="text-lite-grey mx-2 my-1">Salable Area<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Salable Area"
                                            type="number"
                                            min="0"
                                            name="sellAr"
                                            value={currentConfig.sellAr}
                                            onChange={handleNumberUpdateConfigChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                        <Col lg="4" className="pt-3">
                                          <h6 className="text-lite-grey mx-2 my-1">Carpet Area<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Carpet Area"
                                            type="number"
                                            min="0"
                                            name="carpAr"
                                            value={currentConfig.carpAr}
                                            onChange={handleNumberUpdateConfigChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                        <Col lg="4" className="pt-3">
                                          <h6 className="text-lite-grey mx-2 my-1">Car Parking<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Car Parking"
                                            type="number"
                                            min="0"
                                            name="carPark"
                                            value={currentConfig.carPark}
                                            onChange={handleNumberUpdateConfigChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                        <Col lg="12">
                                          <div className="align-center pt-3">
                                            <Button
                                              className="button-filled mx-1"
                                              onClick={handleAddUpdateConfig}
                                            >
                                              ADD
                                            </Button>
                                          </div>
                                        </Col>
                                        <Col lg="12">
                                          <div className="pt-3">
                                            <span>Added Configurations</span>
                                            <ul>
                                              {projectsUpdateInput.resConfig.map((config, configIndex) => (
                                                <li key={`${configIndex.toString()}`}>
                                                  {config.resTp} - Salable: {config.sellAr}, Carpet: {config.carpAr}, Parking: {config.carPark}
                                                  <Button className="button-icon padding-zero ml" onClick={() => handleRemoveConfig(index)}>
                                                    <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                                                  </Button>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : null }
                                {projectsUpdateInput.ProjTp === 'COMM'
                                  ? (
                                    <Col lg="12" className="pt-4">
                                      <Row>
                                        <Col lg="4">
                                          <h6 className="text-lite-grey mx-2 my-1">Salable Area<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Salable Area"
                                            type="number"
                                            required
                                            min="0"
                                            name="sellAr"
                                            value={projectsUpdateInput.sellAr || ''}
                                            onChange={handleNumberChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                        <Col lg="4">
                                          <h6 className="text-lite-grey mx-2 my-1">Carpet Area<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Carpet Area"
                                            type="number"
                                            required
                                            min="0"
                                            name="carpAr"
                                            value={projectsUpdateInput.carpAr || ''}
                                            onChange={handleNumberChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                        <Col lg="4">
                                          <h6 className="text-lite-grey mx-2 my-1">Car Parking<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Car Parking"
                                            type="number"
                                            required
                                            min="0"
                                            name="carPark"
                                            value={projectsUpdateInput.carPark || ''}
                                            onChange={handleNumberChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : null }
                                <Col lg="12" className="pt-4">
                                  <h6 className="text-lite-grey mx-2 my-1">Projects Location<span className="mandate-star">*</span></h6>
                                  <Select
                                    value={projectsUpdateInput.loc === '' ? null : selectedLocOptions}
                                    options={locOptions}
                                    onChange={(e) => handleSelectUpdateLoc(e)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="top"
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (base) => ({
                                        ...base,
                                        // position: 'relative',
                                        marginBottom: '0',
                                        marginTop: 'auto',
                                        bottom: '100%',
                                        top: 'auto',
                                      }),
                                      control: (base: any) => ({
                                        ...base,
                                        '&:hover': { borderColor: '#05cbddff' },
                                        border: '1px solid #BFBFBF',
                                        minHeight: '45px',
                                        borderRadius: '5px',
                                        padding: 1,
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: '#383838',
                                        boxShadow: 'none',
                                        backgroundColor: '#fff',
                                        '&:focus': {
                                          borderColor: '#E1EDF8',
                                          boxShadow: 'none',
                                          color: '#575656',
                                          background: '#fff',
                                        },
                                      }),
                                      option: (provided: any, state: { isSelected: any; }) => ({
                                        ...provided,
                                        color: state.isSelected ? '#383838' : '#212121',
                                        padding: 15,
                                        textAlign: 'left',
                                        background: state.isSelected ? '#FAFCFB' : '#fff',
                                        '&:hover': {
                                          background: '#FAFCFB',
                                        },
                                      }),
                                    }}
                                    noOptionsMessage={() => 'No Commercial Category Found'}
                                    placeholder="Select Commercial Category"
                                  />
                                </Col>
                              </Row>
                              <div className="align-center pt-4 mx-2">
                                <Button
                                  className="button-filled mx-1"
                                  onClick={handleProjectsUpdate}
                                >
                                  UPDATE
                                </Button>
                              </div>
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))
              : (
                <div className="pt-4 align-center">
                  <span className="form-collapse-card-header">No Projects Found</span>
                </div>
              )}
            <div>
              {!isLastObject
                ? (
                  <div className="align-center my-5">
                    <Button className="link-text-italic button-icon" onClick={fetchMoreData}>
                      Load More <FontAwesomeIcon icon={faSortDown} />
                    </Button>
                  </div>
                )
                : (
                  <div>
                    <div className="align-center my-5">
                      <span className="text-bold-grey pt-3">No More Records Avilable</span>
                    </div>
                  </div>
                )}
            </div>
          </Row>
          {/* <InfiniteScroll
            dataLength={projectsData.projData.length}
            next={() => fetchMoreData()}
            hasMore={loadingMore}
            loader={loaderAddProjects}
          >
            <Row className="margin-top-des pt-3">
              {projectsData && projectsData.projData.length > 0
                ? projectsData.projData.map((projDet, index) => (
                  <Col lg="4" key={`proj-${index.toString()}`} className="my-2">
                    <Card className="card-lite">
                      <CardHeader className="form-card-header">
                        {projDet.projUrl.length > 0 && <CardImg src={projDet.projUrl[0]} alt="Proj Image" className="card-image" />}
                      </CardHeader>
                      <CardBody className="padding-zero">
                        <Row className="my-1">
                          <Col xs="12">
                            <span className="text-link ml">RERA : {projDet.rera}</span>
                          </Col>
                          <Col xs="12">
                            <div className="align-center pt-2">
                              <span className="buy-subtext">
                                {projDet.nme}
                              </span>
                            </div>
                            <div className="align-center">
                              <span className="text-lite-grey-grey">
                                {projDet.loc}
                              </span>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="align-center my-2 ml">
                              <span className="buy-subtext">
                                {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(projDet.budget))}
                              </span>
                              <span className="listing-price-text ml">Onward</span>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="align-start ml my-2 pt-2">
                              {projDet.projCat.map((projCatDet, catIndex) => (
                                <span className="prod-cat-bg mx-1" key={`${catIndex.toString()}`}>
                                  { CommPropertyTypeList.find((comm) => comm.value === projCatDet)?.label || ''}
                                  { ResidentPropertyTypeList.find((res) => res.value === projCatDet)?.label || ''}
                                </span>
                              ))}
                            </div>
                            {projDet.resConfig.map((resConfigtDet, resIndex) => (
                              <div className="mx-1 my-2 pt-2" key={`${resIndex.toString()}`}>
                                <span className="box-buy-cat">
                                  <FontAwesomeIcon icon={faRug} className="mx-1" />{resConfigtDet.carpAr} sqft
                                  <span className="mx-1 icon-buy-small">|</span>
                                  <FontAwesomeIcon icon={faRulerCombined} className="mx-1" />{resConfigtDet.sellAr} sqft
                                  <span className="mx-1 icon-buy-small">|</span>
                                  <FontAwesomeIcon icon={faCar} className="mx-1" />{resConfigtDet.carPark}
                                </span>
                              </div>
                            ))}

                          </Col>
                          <Col xs="12">
                            <div className="align-start ml my-3">
                              {projDet.ProjTp === 'COMM'
                                ? (
                                  <span className="box-buy-cat mx-1">
                                    <FontAwesomeIcon icon={faCar} className="mx-1" />
                                    {projDet.carPark}
                                  </span>
                                ) : null}
                              {projDet.ProjTp === 'COMM'
                                ? (
                                  <span className="box-buy-cat mx-1">
                                    <FontAwesomeIcon icon={faRug} className="mx-1" />{projDet.carpAr} sqft
                                  </span>
                                )
                                : null}
                              {projDet.ProjTp === 'COMM'
                                ? <span className="box-buy-cat mx-1"><FontAwesomeIcon icon={faRulerCombined} className="mx-1" />{projDet.sellAr} sqft</span>
                                : null}
                            </div>
                            <Row>
                              <Col xs="5" lg="5">
                                <div className="ml pt-2 my-1">
                                  {projDet.leaseTp === 'LEASE'
                                    ? <span className="prod-lease-bg">LEASE</span>
                                    : projDet.leaseTp === 'OTRHT'
                                      ? <span className="prod-lease-bg">OUT RIGHT</span>
                                      : projDet.leaseTp === 'PLSE'
                                        ? <span className="prod-lease-bg">PRE LEASE</span> : null}
                                </div>
                                {projDet.leaseTp !== '' ? <span className="ml-1 text-lite-grey-grey-small">Lease type</span> : null}
                              </Col>
                              <Col xs="7" lg="7">
                                <div className="align-end mx-2 my-2">
                                  <Button className="button-filled" onClick={() => projToggleModal(`projDet-${index}`, projDet)}>
                                    EDIT DATA
                                  </Button>
                                </div>
                              </Col>
                              <Modal
                                isOpen={openModalProj[`projDet-${index}`] || false}
                                target={`projDet-${index}`}
                                // size="lg"
                              >
                                <ModalHeader toggle={() => projToggleModal(`projDet-${index}`, projDet)} className="form-card-border-none">
                                  <span className="text-lite-grey">{projDet.nme}</span>
                                </ModalHeader>
                                <ModalBody className="padding-zero mb-4">
                                  <Row className="mx-1">
                                    <Col xs="12" className="pt-1">
                                      <h6 className="text-lite-grey mx-2 my-1">Project Name<span className="mandate-star">*</span></h6>
                                      <Input
                                        placeholder="Enter product name"
                                        type="text"
                                        required
                                        name="nme"
                                        value={projectsUpdateInput.nme}
                                        onChange={handleFieldUpdateChange}
                                        className="buy-input"
                                      />
                                    </Col>
                                    <Col lg="12" className="pt-4">
                                      <Row>
                                        <Col lg="7">
                                          <h6 className="text-lite-grey mx-2 my-1">Builder Name<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Enter Builder Name"
                                            type="text"
                                            required
                                            name="builder"
                                            value={projectsUpdateInput.builder}
                                            onChange={handleFieldUpdateChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                        <Col lg="5">
                                          <h6 className="text-lite-grey mx-2 my-1">Budget<span className="mandate-star">*</span></h6>
                                          <Input
                                            placeholder="Budget"
                                            required
                                            name="budget"
                                            value={projectsUpdateInput.budget}
                                            onChange={handleFieldUpdateChange}
                                            className="buy-input"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col lg="12" className="pt-4">
                                      <h6 className="text-lite-grey mx-2 my-1">RERA<span className="mandate-star">*</span></h6>
                                      <Input
                                        placeholder="Enter RERA Details"
                                        type="text"
                                        required
                                        name="rera"
                                        value={projectsUpdateInput.rera}
                                        onChange={handleFieldUpdateChange}
                                        className="buy-input"
                                      />
                                    </Col>
                                    <Col lg="12" className="pt-4">
                                      <h6 className="text-lite-grey mx-2 my-1">Projects Category<span className="mandate-star">*</span></h6>
                                      {projectsUpdateInput.ProjTp === 'COMM'
                                        ? (
                                          <Select
                                            value={selectedProjCategoryCOMMOptions}
                                            options={projCategoryCOMMOptions}
                                            onChange={(e) => handleSelectUpdateProjCategory(e)}
                                            menuPortalTarget={document.body}
                                            styles={{
                                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                              menu: (base) => ({
                                                ...base,
                                                // position: 'relative',
                                              }),
                                              control: (base: any) => ({
                                                ...base,
                                                '&:hover': { borderColor: '#05cbddff' },
                                                border: '1px solid #BFBFBF',
                                                minHeight: '45px',
                                                borderRadius: '5px',
                                                padding: 1,
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#383838',
                                                boxShadow: 'none',
                                                backgroundColor: '#fff',
                                                '&:focus': {
                                                  borderColor: '#E1EDF8',
                                                  boxShadow: 'none',
                                                  color: '#575656',
                                                  background: '#fff',
                                                },
                                              }),
                                              option: (provided: any, state: { isSelected: any; }) => ({
                                                ...provided,
                                                color: state.isSelected ? '#383838' : '#212121',
                                                padding: 15,
                                                textAlign: 'left',
                                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                                '&:hover': {
                                                  background: '#FAFCFB',
                                                },
                                              }),
                                            }}
                                            noOptionsMessage={() => 'No Commercial Category Found'}
                                            placeholder="Select Commercial Category"
                                          />
                                        ) : (
                                          <Select
                                            value={selectedProjCategoryRESOptions}
                                            options={projCategoryRESOptions}
                                            onChange={(e) => handleSelectUpdateProjCategory(e)}
                                            menuPortalTarget={document.body}
                                            styles={{
                                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                              menu: (base) => ({
                                                ...base,
                                                // position: 'relative',
                                              }),
                                              control: (base: any) => ({
                                                ...base,
                                                '&:hover': { borderColor: '#05cbddff' },
                                                border: '1px solid #BFBFBF',
                                                minHeight: '45px',
                                                borderRadius: '5px',
                                                padding: 1,
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#383838',
                                                boxShadow: 'none',
                                                backgroundColor: '#fff',
                                                '&:focus': {
                                                  borderColor: '#E1EDF8',
                                                  boxShadow: 'none',
                                                  color: '#575656',
                                                  background: '#fff',
                                                },
                                              }),
                                              option: (provided: any, state: { isSelected: any; }) => ({
                                                ...provided,
                                                color: state.isSelected ? '#383838' : '#212121',
                                                padding: 15,
                                                textAlign: 'left',
                                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                                '&:hover': {
                                                  background: '#FAFCFB',
                                                },
                                              }),
                                            }}
                                          // isLoading={loaderState}
                                            noOptionsMessage={() => 'No Residential Category Found'}
                                            placeholder="Select Residential Category"
                                          />
                                        ) }
                                      <Row className="ml pt-2">
                                        {projectsUpdateInput.projCat && projectsUpdateInput.projCat.length > 0
                                          ? projectsUpdateInput.projCat.map((projVal, projCatIndex) => (
                                            <Col lg="6" key={`projCat-${projCatIndex.toString()}`} className="my-1 align-start">
                                              <div className="mr">
                                                <FontAwesomeIcon icon={faCube} className="icon-buy-small" />
                                              </div>
                                              <div>
                                                <span className="form-card-header">
                                                  { CommPropertyTypeList.find((comm) => comm.value === projVal)?.label || ''}
                                                  { ResidentPropertyTypeList.find((res) => res.value === projVal)?.label || ''}
                                                </span>
                                                <Button className="button-icon padding-zero ml mb-1" onClick={() => handleRemoveProjType(projVal)}>
                                                  <FontAwesomeIcon icon={faRectangleXmark} className="wrong-pass-key" />
                                                </Button>
                                              </div>
                                            </Col>
                                          )) : null }
                                      </Row>
                                    </Col>
                                    {projectsUpdateInput.ProjTp === 'COMM'
                                      ? (
                                        <Col lg="12" className="pt-4">
                                          <h6 className="text-lite-grey mx-2 my-1">Lease Type<span className="mandate-star">*</span></h6>
                                          <Row className="ml">
                                            <Col lg="4">
                                              <Label check className="cat-check-text1">
                                                <Input
                                                  type="radio"
                                                  role="switch"
                                                  name="LEASE"
                                                  onChange={handleProjLease}
                                                  checked={projectsUpdateInput.leaseTp === 'LEASE'}
                                                  className="buy-check-box mr-1 cursor-pointer"
                                                />
                                                LEASE
                                              </Label>
                                            </Col>
                                            <Col lg="4">
                                              <Label check className="cat-check-text1">
                                                <Input
                                                  type="radio"
                                                  role="switch"
                                                  name="OTRHT"
                                                  onChange={handleProjLease}
                                                  checked={projectsUpdateInput.leaseTp === 'OTRHT'}
                                                  className="buy-check-box mr-1 cursor-pointer"
                                                />
                                                OUTRIGHT
                                              </Label>
                                            </Col>
                                            <Col lg="4">
                                              <Label check className="cat-check-text1">
                                                <Input
                                                  type="radio"
                                                  role="switch"
                                                  name="PLSE"
                                                  onChange={handleProjLease}
                                                  checked={projectsUpdateInput.leaseTp === 'PLSE'}
                                                  className="buy-check-box mr-1 cursor-pointer"
                                                />
                                                PRE LEASE
                                              </Label>
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : null }
                                    <Col lg="12" className="pt-4">
                                      <h6 className="text-lite-grey mx-2 my-1">Project Progress<span className="mandate-star">*</span></h6>
                                      <Row className="ml">
                                        <Col lg="6">
                                          <Label check className="cat-check-text1">
                                            <Input
                                              type="radio"
                                              role="switch"
                                              name="YES"
                                              onChange={handleProjIsMovin}
                                              checked={projectsUpdateInput.isMovin === 'YES'}
                                              className="buy-check-box mr-1 cursor-pointer"
                                            />
                                            READY MOVE-IN
                                          </Label>
                                        </Col>
                                        <Col lg="6">
                                          <Label check className="cat-check-text1">
                                            <Input
                                              type="radio"
                                              role="switch"
                                              name="NO"
                                              onChange={handleProjIsMovin}
                                              checked={projectsUpdateInput.isMovin === 'NO'}
                                              className="buy-check-box mr-1 cursor-pointer"
                                            />
                                            IN PROGRESS
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Col>
                                    {projectsUpdateInput.ProjTp === 'RES'
                                      ? (
                                        <Col lg="12" className="pt-4">
                                          <h6 className="text-lite-grey mx-2 my-1">Carpet Configuration<span className="mandate-star">*</span></h6>
                                          <Row className="proj-config-box">
                                            <Col lg="12">
                                              <Select
                                                value={selectedProjResConfigOptionsOptions}
                                                options={projResConfigOptions}
                                                onChange={(e) => handleSelectUpdateProjConfig(e)}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                  menu: (base) => ({
                                                    ...base,
                                                    // position: 'relative',
                                                  }),
                                                  control: (base: any) => ({
                                                    ...base,
                                                    '&:hover': { borderColor: '#05cbddff' },
                                                    border: '1px solid #BFBFBF',
                                                    minHeight: '45px',
                                                    borderRadius: '5px',
                                                    padding: 1,
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    color: '#383838',
                                                    boxShadow: 'none',
                                                    backgroundColor: '#fff',
                                                    '&:focus': {
                                                      borderColor: '#E1EDF8',
                                                      boxShadow: 'none',
                                                      color: '#575656',
                                                      background: '#fff',
                                                    },
                                                  }),
                                                  option: (provided: any, state: { isSelected: any; }) => ({
                                                    ...provided,
                                                    color: state.isSelected ? '#383838' : '#212121',
                                                    padding: 15,
                                                    textAlign: 'left',
                                                    background: state.isSelected ? '#FAFCFB' : '#fff',
                                                    '&:hover': {
                                                      background: '#FAFCFB',
                                                    },
                                                  }),
                                                }}
                                                noOptionsMessage={() => 'No Residential Category Found'}
                                                placeholder="Select Residential Category"
                                              />
                                            </Col>
                                            <Col lg="4" className="pt-3">
                                              <h6 className="text-lite-grey mx-2 my-1">Salable Area<span className="mandate-star">*</span></h6>
                                              <Input
                                                placeholder="Salable Area"
                                                type="number"
                                                min="0"
                                                name="sellAr"
                                                value={currentConfig.sellAr}
                                                onChange={handleNumberUpdateConfigChange}
                                                className="buy-input"
                                              />
                                            </Col>
                                            <Col lg="4" className="pt-3">
                                              <h6 className="text-lite-grey mx-2 my-1">Carpet Area<span className="mandate-star">*</span></h6>
                                              <Input
                                                placeholder="Carpet Area"
                                                type="number"
                                                min="0"
                                                name="carpAr"
                                                value={currentConfig.carpAr}
                                                onChange={handleNumberUpdateConfigChange}
                                                className="buy-input"
                                              />
                                            </Col>
                                            <Col lg="4" className="pt-3">
                                              <h6 className="text-lite-grey mx-2 my-1">Car Parking<span className="mandate-star">*</span></h6>
                                              <Input
                                                placeholder="Car Parking"
                                                type="number"
                                                min="0"
                                                name="carPark"
                                                value={currentConfig.carPark}
                                                onChange={handleNumberUpdateConfigChange}
                                                className="buy-input"
                                              />
                                            </Col>
                                            <Col lg="12">
                                              <div className="align-center pt-3">
                                                <Button
                                                  className="button-filled mx-1"
                                                  onClick={handleAddUpdateConfig}
                                                >
                                                  ADD
                                                </Button>
                                              </div>
                                            </Col>
                                            <Col lg="12">
                                              <div className="pt-3">
                                                <span>Added Configurations</span>
                                                <ul>
                                                  {projectsUpdateInput.resConfig.map((config, configIndex) => (
                                                    <li key={`${configIndex.toString()}`}>
                                                      {config.resTp} - Salable: {config.sellAr}, Carpet: {config.carpAr}, Parking: {config.carPark}
                                                      <Button className="button-icon padding-zero ml" onClick={() => handleRemoveConfig(index)}>
                                                        <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                                                      </Button>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : null }
                                    {projectsUpdateInput.ProjTp === 'COMM'
                                      ? (
                                        <Col lg="12" className="pt-4">
                                          <Row>
                                            <Col lg="4">
                                              <h6 className="text-lite-grey mx-2 my-1">Salable Area<span className="mandate-star">*</span></h6>
                                              <Input
                                                placeholder="Salable Area"
                                                type="number"
                                                required
                                                min="0"
                                                name="sellAr"
                                                value={projectsUpdateInput.sellAr || ''}
                                                onChange={handleNumberChange}
                                                className="buy-input"
                                              />
                                            </Col>
                                            <Col lg="4">
                                              <h6 className="text-lite-grey mx-2 my-1">Carpet Area<span className="mandate-star">*</span></h6>
                                              <Input
                                                placeholder="Carpet Area"
                                                type="number"
                                                required
                                                min="0"
                                                name="carpAr"
                                                value={projectsUpdateInput.carpAr || ''}
                                                onChange={handleNumberChange}
                                                className="buy-input"
                                              />
                                            </Col>
                                            <Col lg="4">
                                              <h6 className="text-lite-grey mx-2 my-1">Car Parking<span className="mandate-star">*</span></h6>
                                              <Input
                                                placeholder="Car Parking"
                                                type="number"
                                                required
                                                min="0"
                                                name="carPark"
                                                value={projectsUpdateInput.carPark || ''}
                                                onChange={handleNumberChange}
                                                className="buy-input"
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : null }
                                    <Col lg="12" className="pt-4">
                                      <h6 className="text-lite-grey mx-2 my-1">Projects Location<span className="mandate-star">*</span></h6>
                                      <Select
                                        value={projectsUpdateInput.loc === '' ? null : selectedLocOptions}
                                        options={locOptions}
                                        onChange={(e) => handleSelectUpdateLoc(e)}
                                        menuPortalTarget={document.body}
                                        menuPlacement="top"
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (base) => ({
                                            ...base,
                                            // position: 'relative',
                                            marginBottom: '0',
                                            marginTop: 'auto',
                                            bottom: '100%',
                                            top: 'auto',
                                          }),
                                          control: (base: any) => ({
                                            ...base,
                                            '&:hover': { borderColor: '#05cbddff' },
                                            border: '1px solid #BFBFBF',
                                            minHeight: '45px',
                                            borderRadius: '5px',
                                            padding: 1,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#383838',
                                            boxShadow: 'none',
                                            backgroundColor: '#fff',
                                            '&:focus': {
                                              borderColor: '#E1EDF8',
                                              boxShadow: 'none',
                                              color: '#575656',
                                              background: '#fff',
                                            },
                                          }),
                                          option: (provided: any, state: { isSelected: any; }) => ({
                                            ...provided,
                                            color: state.isSelected ? '#383838' : '#212121',
                                            padding: 15,
                                            textAlign: 'left',
                                            background: state.isSelected ? '#FAFCFB' : '#fff',
                                            '&:hover': {
                                              background: '#FAFCFB',
                                            },
                                          }),
                                        }}
                                        noOptionsMessage={() => 'No Commercial Category Found'}
                                        placeholder="Select Commercial Category"
                                      />
                                    </Col>
                                  </Row>
                                  <div className="align-center pt-4 mx-2">
                                    <Button
                                      className="button-filled mx-1"
                                      onClick={handleProjectsUpdate}
                                    >
                                      UPDATE
                                    </Button>
                                  </div>
                                </ModalBody>
                              </Modal>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))
                : (
                  <div className="pt-4 align-center">
                    <span className="form-collapse-card-header">No Projects Found</span>
                  </div>
                )}
            </Row>
          </InfiniteScroll> */}
        </Col>
      </Row>
    </div>
  );
};
export default GetAllProjects;
