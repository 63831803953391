import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardBody, Card, Input, Button, InputGroup, InputGroupText, Form, Col, Row, CardImg,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import './login.scss';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import LoaderData from '../../../utils/loader';
import { errorAlert, successAlert } from '../../../utils/alert';
import { setTokenSessionStorage, setUserProfileSessionStorage } from '../../../utils/service/localstorage-service';
import { getLoginToken, getProfile, clearLoginTokenReq } from '../../../store/buypersqft/actions';
import { APIHeader } from '../../../utils/constants';

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  const { hash } = window.location;
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [powerUrl, setPowerUrl] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [userType, setUserType] = useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [loginPassKey, setLoginPassKey] = useState('');
  const [loginUserName, setLoginUserName] = useState('');
  const loginTokenData = useSelector((state: RootState) => state.buypersqft.genToken);
  const loginProfileData = useSelector((state: RootState) => state.buypersqft.profileData);

  React.useEffect(() => {
    if (path[2] === 'sadmin') {
      setUserType('ADM');
    }
  }, [path[2]]);

  React.useEffect(() => {
    if (hash) {
      setPowerUrl(hash.slice(1));
    }
  }, [hash.slice(1), powerUrl]);

  const handlePassKeyChange = (e: any) => {
    setLoginPassKey(e.target.value);
  };

  const handleUserNameChange = (e: any) => {
    setLoginUserName(e.target.value);
    const emailPattern = /\b[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}\b/;
    const numberPattern = /^\d{10}$/;
    const isValidEmail = emailPattern.test(e.target.value);
    const isValidNumber = numberPattern.test(e.target.value);
    setIsLoginButtonDisabled(!(isValidEmail || isValidNumber));
  };
  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handlePasswordSubmit = async (e:any) => {
    e.preventDefault();
    if (loginUserName !== '' && loginPassKey !== '') {
      setIsLoader(true);
      dispatch(getLoginToken({
        userName: loginUserName,
        passKey: loginPassKey,
        userType,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  };

  React.useEffect(() => {
    if (loginTokenData.isError && loginTokenData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginTokenData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginTokenData.message, loginTokenData.isError]);

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.message === 'executed' && loginTokenData.token !== '') {
      setIsLoader(false);
      setTokenSessionStorage(loginTokenData.token);
      dispatch(getProfile({
        userReqFor: userType,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: loginTokenData.token,
      }));
    }
  }, [loginTokenData.token, loginTokenData.isError]);

  React.useEffect(() => {
    if (loginProfileData.isError && loginProfileData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginProfileData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginProfileData.message, loginProfileData.isError]);

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message !== '') {
      setIsLoader(false);
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
      dispatch(clearLoginTokenReq());
      if (loginProfileData.profileData.UserTp === 'ADM') {
        history('/sadmin/dashboard');
      }
    }
  }, [loginProfileData.message, loginTokenData.isError]);

  return (
    <div>
      { isLoader ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(true, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="mx-1 pt-1">
        <Col lg={{ size: 7, order: 1 }} xs={{ order: 2 }}>
          <div className="align-center margin-login-column pt-2 mx-2">
            <CardImg
              className="process-image"
              src="/images/home/login-prop.jpg"
            />
          </div>
          {/* <div className="text-center-aligned">
            <span className="login-tagline-text">Empowering Efficiency: Your One-Stop Supply Platform for Reliable Power Equipment Solutions</span>
          </div> */}
        </Col>
        <Col lg={{ size: 5, order: 2 }} xs={{ order: 1 }}>
          <div className="pt-2">
            <Card className="card-lite">
              <CardBody>
                <div className="align-center pt-1 mx-3">
                  <span className="login-header">
                    {powerUrl === 'forgotPwd' ? 'Reset Password' : powerUrl === 'signUp' ? 'Sign up' : powerUrl === 'logn' ? 'User Login' : null}
                  </span>
                </div>
                {powerUrl === 'logn'
                  ? (
                    <div className="pt-5">
                      <Form onSubmit={handlePasswordSubmit}>
                        <div className="mx-1">
                          <h6 className="text-lite-grey mx-2 my-1">Username<span className="mandate-star">*</span></h6>
                          <Input
                            placeholder="Email Id / Registered Phone"
                            type="text"
                            required
                            className="buy-input mx-1"
                            value={loginUserName}
                            onChange={handleUserNameChange}
                          />
                        </div>
                        <div className="mx-1 pt-4">
                          <h6 className="text-lite-grey mx-2 my-1">Password<span className="mandate-star">*</span></h6>
                          <InputGroup>
                            <Input
                              placeholder="Password"
                              type={passwordShown ? 'text' : 'password'}
                              required
                              value={loginPassKey}
                              onChange={handlePassKeyChange}
                              className="buy-input mx-1"
                            />
                            <InputGroupText className="buy-input cursor-pointer" onClick={handleClickShowPassword}>
                              {passwordShown
                                ? (<FontAwesomeIcon icon={faEye} className="icon-buy-medium" />)
                                : (<FontAwesomeIcon icon={faEyeSlash} className="icon-buy-medium-dis" />
                                )}
                            </InputGroupText>
                          </InputGroup>
                        </div>
                        <div className="align-center my-4 mx-2 pt-4">
                          <Button className="button-filled mx-1" disabled={isLoginButtonDisabled || loginPassKey === ''}>
                            LOGIN
                          </Button>
                        </div>
                      </Form>
                    </div>
                  ) : null}
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default Login;
