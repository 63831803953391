import React, { MutableRefObject, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './buypersqft.scss';
import { faHouse, faCity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row, Col, ButtonGroup, Button,
} from 'reactstrap';
import { HINavbar } from '../../components/public/navbar/buypersqft.navbar';
import KnowBuyPerSqFt from '../../components/public/home/know.component';
import BuilderImageGallery from '../../components/public/home/builder-marketing';
import WhyBuyPerSqFt from '../../components/public/home/why-buypersqft.component';
import BuyFooter from '../../components/public/home/footer';
import CommercialDeal from '../../components/public/home/commercial/commercial.component';
import { ResidentialDeal } from '../../components/public/home/residentials/residential.component';
import MCommercialDeal from '../../components/public/home/commercial/mobile.commercial.component';
import MResidentialDeal from '../../components/public/home/residentials/mobile.residential.component';

export const PublicBuyPerSqFtComponent: React.FC = () => {
  const location = useLocation();
  const [selectGroupProjCat, setSelectGroupProjCat] = React.useState('COMM');
  const commercialsDiv = useRef() as MutableRefObject<HTMLDivElement>;
  const flatsDiv = useRef() as MutableRefObject<HTMLDivElement>;
  React.useLayoutEffect(() => {
    if (location.hash === '#commercials') {
      commercialsDiv.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#flats') {
      flatsDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const imagesGalBuilders = [
    '/images/builders/dosti.png',
    '/images/builders/duvile.png',
    '/images/builders/gagan-developer.png',
    '/images/builders/gera.png',
    '/images/builders/godrej.jpeg',
    '/images/builders/goel-ganga.png',
    '/images/builders/kumar-prop.png',
    '/images/builders/lodha.png',
    '/images/builders/mahindra.png',
    '/images/builders/majestique.jpeg',
    '/images/builders/mantra.jpeg',
    '/images/builders/nyati.jpeg',
    '/images/builders/panchshil.jpeg',
    '/images/builders/pride-purple.jpeg',
    '/images/builders/pristine.jpeg',
    '/images/builders/shubh.png',
    '/images/builders/sspl.jpeg',
    '/images/builders/unique.jpeg',
    '/images/builders/vj-developer.png',
    '/images/builders/vtp.png',
    '/images/builders/zen.jpeg',
  ];
  return (
    <div>
      <HINavbar />
      <KnowBuyPerSqFt />
      <BuilderImageGallery images={imagesGalBuilders} />
      <WhyBuyPerSqFt />
      <div className="protype-visibility-desk">
        <div ref={flatsDiv} className="pt-5">
          <ResidentialDeal propertyTp="RES" />
        </div>
        <div ref={commercialsDiv}>
          <CommercialDeal propertyTp="COMM" />
        </div>
      </div>
      <div ref={commercialsDiv} className="mx-2 protype-visibility-mobile">
        <Row className="my-3">
          <Col lg="12">
            <ButtonGroup className="align-center pt-3">
              <Button
                outline
                onClick={() => setSelectGroupProjCat('COMM')}
                active={selectGroupProjCat === 'COMM'}
                className={selectGroupProjCat === 'COMM' ? 'button-filled' : 'button-open'}
              >
                <FontAwesomeIcon icon={faCity} className="mr" />COMMERCIAL
              </Button>
              <Button
                outline
                onClick={() => setSelectGroupProjCat('RES')}
                active={selectGroupProjCat === 'RES'}
                className={selectGroupProjCat === 'RES' ? 'button-filled mx-1' : 'button-open mx-1'}
              >
                <FontAwesomeIcon icon={faHouse} className="mr" />RESIDENTIAL
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div>
          {selectGroupProjCat === 'COMM'
            ? <MCommercialDeal propertyTp="COMM" />
            : <MResidentialDeal propertyTp="RES" />}
        </div>
      </div>
      <div className="pt-5">
        <BuyFooter />
      </div>
    </div>
  );
};

export default PublicBuyPerSqFtComponent;
