import { ActionType, getType, Reducer } from 'typesafe-actions';
import {
  clearContactUsPostReq, clearCreateEnquiryReq, clearCreateProjectsReq,
  clearDeleteProjImg, clearEnquiryListReq, clearGetListProjImg,
  clearLoginTokenReq, clearProfileReq, clearProjectsListReq,
  clearPubRESProjectsListReq, clearPutEnquiryReq, clearPutProjectsReq, clearUploadProjImg,
  updateContactUsPostReq, updateCreateEnquiryReq, updateCreateProjectsReq,
  updateData,
  updateDeleteProjImg,
  updateEnquiryListReq,
  updateGetListProjImg,
  updateProfile,
  updateProjectsListReq,
  updatePubRESProjectsListReq,
  updatePutEnquiryReq,
  updatePutProjectsReq,
  updateToken,
  updateUploadProjImg,
} from './actions';
import * as actions from './actions';
import { BuyPerSqFtAppState } from './action.types';

export const initialState: BuyPerSqFtAppState = {
  data: {
    show: '',
  },
  contactUs: {
    message: '',
    errorMessage: '',
    error: false,
  },
  genToken: {
    token: '',
    message: '',
    isError: false,
  },
  profileData: {
    isError: false,
    message: '',
    profileData: {
      nme: '',
      ph: '',
      UserCd: '',
      UserTp: '',
      isPKReset: false,
      lgnSts: '',
      eId: '',
    },
  },
  createNewProjects: {
    message: '',
    error: false,
    data: '',
    projId: '',
  },
  updateProjects: {
    message: '',
    error: false,
  },
  uploadProjectsAWS: {
    fileName: '',
  },
  getListProjectsImageAWS: {
    data: [],
  },
  deleteProjectsAWS: {
    fileName: '',
  },
  getProjectsList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    },
    isError: false,
    message: '',
  },
  getPubRESProjectsList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    },
    isError: false,
    message: '',
  },
  createNewEnquiry: {
    message: '',
    error: false,
    data: '',
    enqId: '',
  },
  getEnquiryList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      enqData: [],
      lastEvalKey: {
        EnqTp: '',
        EnqId: '',
      },
    },
    isError: false,
    message: '',
  },
  updateEnquiry: {
    message: '',
    error: false,
  },
};
export type AllAction = ActionType<typeof actions>;
const buyPerSqFtReducer: Reducer<BuyPerSqFtAppState, AllAction> = (state: BuyPerSqFtAppState = initialState, action: AllAction): BuyPerSqFtAppState => {
  switch (action.type) {
    case getType(updateData):
      return {
        ...state,
        data: {
          show: action.payload,
        },
      };
    case getType(updateContactUsPostReq):
      return {
        ...state,
        contactUs: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearContactUsPostReq):
      return {
        ...state,
        contactUs: initialState.contactUs,
      };
    case getType(updateToken):
      return {
        ...state,
        genToken: {
          token: action.payload.token,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearLoginTokenReq):
      return {
        ...state,
        genToken: initialState.genToken,
      };
    case getType(updateProfile):
      return {
        ...state,
        profileData: {
          isError: action.payload.isError,
          message: action.payload.message,
          profileData: {
            nme: action.payload.profileData.nme,
            ph: action.payload.profileData.ph,
            eId: action.payload.profileData.eId,
            UserCd: action.payload.profileData.UserCd,
            UserTp: action.payload.profileData.UserTp,
            isPKReset: action.payload.profileData.isPKReset,
            lgnSts: action.payload.profileData.lgnSts,
          },
        },
      };
    case getType(clearProfileReq):
      return {
        ...state,
        profileData: initialState.profileData,
      };
    case getType(updateCreateProjectsReq):
      return {
        ...state,
        createNewProjects: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
          projId: action.payload.projId,
        },
      };
    case getType(clearCreateProjectsReq):
      return {
        ...state,
        createNewProjects: initialState.createNewProjects,
      };
    case getType(updateDeleteProjImg):
      return {
        ...state,
        deleteProjectsAWS: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearDeleteProjImg):
      return {
        ...state,
        deleteProjectsAWS: initialState.deleteProjectsAWS,
      };
    case getType(updateProjectsListReq):
      return {
        ...state,
        getProjectsList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearProjectsListReq):
      return {
        ...state,
        getProjectsList: initialState.getProjectsList,
      };
    case getType(updateGetListProjImg):
      return {
        ...state,
        getListProjectsImageAWS: {
          data: action.payload.data,
        },
      };
    case getType(clearGetListProjImg):
      return {
        ...state,
        getListProjectsImageAWS: initialState.getListProjectsImageAWS,
      };
    case getType(updateUploadProjImg):
      return {
        ...state,
        uploadProjectsAWS: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearUploadProjImg):
      return {
        ...state,
        uploadProjectsAWS: initialState.uploadProjectsAWS,
      };
    case getType(updatePutProjectsReq):
      return {
        ...state,
        updateProjects: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPutProjectsReq):
      return {
        ...state,
        updateProjects: initialState.updateProjects,
      };
    case getType(updatePubRESProjectsListReq):
      return {
        ...state,
        getPubRESProjectsList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearPubRESProjectsListReq):
      return {
        ...state,
        getPubRESProjectsList: initialState.getPubRESProjectsList,
      };

    case getType(updateCreateEnquiryReq):
      return {
        ...state,
        createNewEnquiry: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
          enqId: action.payload.enqId,
        },
      };
    case getType(clearCreateEnquiryReq):
      return {
        ...state,
        createNewEnquiry: initialState.createNewEnquiry,
      };
    case getType(updateEnquiryListReq):
      return {
        ...state,
        getEnquiryList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearEnquiryListReq):
      return {
        ...state,
        getEnquiryList: initialState.getEnquiryList,
      };
    case getType(updatePutEnquiryReq):
      return {
        ...state,
        updateEnquiry: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPutEnquiryReq):
      return {
        ...state,
        updateEnquiry: initialState.updateEnquiry,
      };
    default:
      return state;
  }
};
export default buyPerSqFtReducer;
