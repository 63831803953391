import React from 'react';
import { useNavigate } from 'react-router';
import './home.scss';
import {
  Button, CardImg, InputGroup, InputGroupText,
} from 'reactstrap';

export const KnowBuyPerSqFt: React.FC = () => {
  const history = useNavigate();
  return (
    <div className="home">
      <div className="image-wrapper">
        <CardImg
          src="/images/home/intro.jpg"
          alt="..."
          className="home-image"
        />
        <div className="home-content pt-5">
          <span className="know-buy-header">We help you find your Perfect Property</span>
          <p>Experience Seamless Property Searches in Your Ideal Location</p>
          <InputGroup className="align-center">
            <InputGroupText className="inbox-align-button">
              <Button onClick={() => { history('/#commercials'); }} className="button-know-home">COMMERCIAL</Button>
            </InputGroupText>
            <InputGroupText className="inbox-align-button">
              <Button onClick={() => { history('/#flats'); }} className="button-know-home-lite">FLATS & APARTMENTS</Button>
            </InputGroupText>
            <InputGroupText className="inbox-align-button">
              <Button onClick={() => { history('/#'); }} className="button-know-home-lite">HOME SERVICE</Button>
            </InputGroupText>
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default KnowBuyPerSqFt;
