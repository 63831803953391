import React from 'react';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import './paginate.scss';

interface PaginationProps {
    tCnt: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    setParentPage: (page: number) => void;
    setTotalPages: (page: number) => void;
    onNextPageClick: () => void;
  }

const PaginationButton: React.FC<PaginationProps> = ({
  tCnt,
  itemsPerPage,
  totalPages,
  setParentPage,
  setTotalPages,
  currentPage,
  setCurrentPage,
  onNextPageClick,
}) => {
  React.useEffect(() => {
    const newTotalPages = Math.ceil(tCnt / itemsPerPage);
    if (newTotalPages !== totalPages) {
      setCurrentPage(1);
      setTotalPages(newTotalPages);
    }
  }, [tCnt, totalPages, setTotalPages]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setParentPage(newPage);
    }
  };
  const handleNextPageClick = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      handlePageChange(nextPage);
      onNextPageClick();
    }
  };

  return (
    <div className="align-center">
      <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="button-icon">
        <FontAwesomeIcon className="mx-1" icon={faCaretLeft} />
      </Button>
      <div className="mx-2">
        {currentPage} / {totalPages}
      </div>
      <Button onClick={handleNextPageClick} disabled={currentPage === totalPages} className="button-icon">
        <FontAwesomeIcon className="mx-1" icon={faCaretRight} />
      </Button>
    </div>
  );
};

export default PaginationButton;
