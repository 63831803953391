import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, CardBody, CardImg, Button, Input,
  CardHeader,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import {
  faAngleLeft,
  faAngleRight,
  faCar,
  faRug,
  faRulerCombined,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errorAlert, successAlert, toPascalSnakeCase } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import {
  APIHeader,
  CommPropertyTypeList,
  ResidentPropertyTypeList,
} from '../../../../utils/constants';
import { ProjectsResult } from '../../../../services/projects/list-projects/get-all-projects.types';
import {
  clearCreateEnquiryReq,
  createEnquiryReq,
  getProjectsListReq,
} from '../../../../store/buypersqft/actions';
import { PostEnquiryInput } from '../../../../services/enquiry/add-enquiry/post-enquiry.types';

export const PublicResidentialFullViewProjects: React.FC = () => {
  const dispatch = useDispatch();
  const [loaderAddProjects, setLoaderAddProjects] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [showLease, setShowLease] = React.useState(false);
  const [showProjTp, setShowProjTp] = React.useState(false);
  const [showPossTp, setShowPossTp] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [selectedLeaseText, setSelectedLeaseText] = React.useState('Lease Type');
  const [selectedProjTp, setSelectedProjTp] = React.useState('Project Configuration');
  const [selectedPossTp, setSelectedPossTp] = React.useState('Possesion Type');
  const [isFilterQuery, setIsFilterQuery] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [currentImageIndexes, setCurrentImageIndexes] = React.useState<number[]>([]);
  const [projectsData, setProjectsData] = React.useState<ProjectsResult>({
    tCnt: 0,
    fCnt: 0,
    projData: [],
    lastEvalKey: {
      ProjTp: '',
      ProjId: '',
    },
  });
  const listProjects = useSelector((state: RootState) => state.buypersqft.getProjectsList);
  const addEnquiryResponse = useSelector((state: RootState) => state.buypersqft.createNewEnquiry);
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const phoneRegex = /^\d{10}$/;
  const [whichIndexEnq, setWhichIndexEnq] = React.useState('');
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [loaderEnquiry, setLoaderAddEnquiry] = React.useState(false);
  const [openModalProj, setOpenModalProj] = React.useState<Record<string, boolean>>({});
  const [enquiryInput, setEnquiryInput] = React.useState<PostEnquiryInput>({
    nme: '',
    ph: '',
    projId: '',
    projTp: '',
    isWhatsApp: false,
    msg: '',
    projNme: '',
    addr: '',
  });

  const handleFieldChange = (e:any) => {
    const fieldName = e.target.name;
    setEnquiryInput({ ...enquiryInput, [fieldName]: e.target.value });
    setIsValidPhone(false);
  };

  React.useEffect(() => {
    if (evalKeyText === '' && evalTpText === '') {
      setLoaderAddProjects(true);
      setProjectsData({
        ...projectsData,
        tCnt: 0,
        fCnt: 0,
        projData: [],
        lastEvalKey: {
          ProjTp: '',
          ProjId: '',
        },
      });
      dispatch(getProjectsListReq({
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: 'RES',
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        isPublic: true,
      }));
    }
  }, [evalKeyText, evalTpText]);

  const fetchMoreData = () => {
    setLoaderAddProjects(true);
    if (isFilterQuery !== '') {
      dispatch(
        getProjectsListReq({
          requestType: APIHeader.REQ_GET_ALL_PROJECTS,
          projTp: 'RES',
          limit: 12, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'YES',
          filterBy: isFilterQuery,
          isSearch: 'NO',
          isPublic: true,
        }),
      );
    } else if (isSearchQuery !== '') {
      dispatch(
        getProjectsListReq({
          requestType: APIHeader.REQ_GET_ALL_PROJECTS,
          limit: 12, // ApiLIMIT.LIMIT,
          projTp: 'RES',
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          searchBy: searchText,
          isSearch: 'YES',
          isPublic: true,
        }),
      );
    } else {
      dispatch(
        getProjectsListReq({
          requestType: APIHeader.REQ_GET_ALL_PROJECTS,
          projTp: 'RES',
          limit: 12, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          isPublic: true,
        }),
      );
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearchProjects = () => {
    setLoaderAddProjects(true);
    setIsFilterQuery('');
    setIsSearchQuery(searchText);
    dispatch(
      getProjectsListReq({
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        limit: 12, // ApiLIMIT.LIMIT,
        projTp: 'RES',
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        isPublic: true,
      }),
    );
  };

  const nextImage = (cardIndex: number) => {
    if (projectsData.projData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] + 1) % projectsData.projData[cardIndex].projUrl.length;
        return newIndexes;
      });
    }
  };

  const prevImage = (cardIndex: number) => {
    if (projectsData.projData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] - 1 + projectsData.projData[cardIndex].projUrl.length) % projectsData.projData[cardIndex].projUrl.length;
        return newIndexes;
      });
    }
  };

  React.useEffect(() => {
    if (listProjects.isError && listProjects.message !== '') {
      setLoaderAddProjects(false);
      setAlertMsg(listProjects.message);
      setErrorShowAlert(true);
    }
  }, [listProjects.isError, listProjects.message]);

  React.useEffect(() => {
    if (!listProjects.isError && listProjects.message === 'executed') {
      setLoaderAddProjects(false);
      if (searchText !== '') {
        setProjectsData(listProjects.data);
        setLoadingMore(false);
      } else if (listProjects.data.lastEvalKey !== null) {
        const newData = listProjects.data.projData.filter((item) => !projectsData.projData.some((existingItem) => existingItem.ProjId === item.ProjId));
        setProjectsData((prevData) => ({
          ...prevData,
          tCnt: listProjects.data.tCnt,
          fCnt: listProjects.data.fCnt,
          projData: [...prevData.projData, ...newData],
          lastEvalKey: listProjects.data.lastEvalKey,
        }));
        setEvalKeyText(listProjects.data.lastEvalKey.ProjId);
        setEvalTpText(listProjects.data.lastEvalKey.ProjTp);
        setLoadingMore(true);
        setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...newData.map(() => 0)]);
      } else {
        setProjectsData((prevData) => ({
          ...prevData,
          tCnt: listProjects.data.tCnt,
          fCnt: listProjects.data.fCnt,
          projData: [...prevData.projData, ...listProjects.data.projData],
        }));
        setLoadingMore(false);
        setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...listProjects.data.projData.map(() => 0)]);
      }
    }
  }, [listProjects.isError, listProjects.message, listProjects.data.projData, listProjects.data.lastEvalKey, evalKeyText]);

  const handleShowLease = () => setShowLease((prevState) => !prevState);

  const handleSelectLease = (text: string, leaseValue: string) => {
    setSelectedLeaseText(text);
    setIsFilterQuery(leaseValue);
    setIsSearchQuery('');
    setProjectsData({
      ...projectsData,
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    });
    setLoaderAddProjects(true);
    dispatch(
      getProjectsListReq({
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: 'RES',
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'YES',
        filterBy: leaseValue,
        isSearch: 'NO',
        isPublic: true,
      }),
    );
  };

  const handleShowProjTp = () => setShowProjTp((prevState) => !prevState);

  const handleSelectProjTp = (text: string, projValue: string) => {
    setSelectedProjTp(text);
    setIsFilterQuery(projValue);
    setIsSearchQuery('');
    setProjectsData({
      ...projectsData,
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    });
    setLoaderAddProjects(true);
    dispatch(
      getProjectsListReq({
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: 'RES',
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'YES',
        filterBy: projValue,
        isSearch: 'NO',
        isPublic: true,
      }),
    );
  };

  const handleShowPossTp = () => setShowPossTp((prevState) => !prevState);

  const handleSelectPossTp = (text: string, possValue: string) => {
    setSelectedPossTp(text);
    setIsFilterQuery(possValue);
    setIsSearchQuery('');
    setProjectsData({
      ...projectsData,
      tCnt: 0,
      fCnt: 0,
      projData: [],
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
    });
    setLoaderAddProjects(true);
    dispatch(
      getProjectsListReq({
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: 'RES',
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'YES',
        filterBy: possValue,
        isSearch: 'NO',
        isPublic: true,
      }),
    );
  };

  const projToggleModal = (target: string) => {
    setWhichIndexEnq(target);
    setOpenModalProj((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleAddEnquiry = (e: any, projectTp: string, projectId: string) => {
    e.preventDefault();
    const validPhone = phoneRegex.test(enquiryInput.ph);
    if (validPhone) {
      setLoaderAddEnquiry(true);
      const updatedKartInput = {
        ...enquiryInput,
        projId: projectId,
        projTp: projectTp,
      };
      Promise.resolve(updatedKartInput).then((resolvedInput) => {
        dispatch(createEnquiryReq({
          inputBody: resolvedInput,
          requestType: APIHeader.REQ_ADD_ENQUIRY,
        }));
      });
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  React.useEffect(() => {
    if (addEnquiryResponse.error && addEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg(addEnquiryResponse.message);
      setErrorShowAlert(true);
    }
  }, [addEnquiryResponse.error, addEnquiryResponse.message]);

  React.useEffect(() => {
    if (!addEnquiryResponse.error && addEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg('We have recieved your enquiry. Our team will get back to you shortly');
      setSuccessShowAlert(true);
      dispatch(clearCreateEnquiryReq());
      setOpenModalProj((prevState) => ({
        ...prevState,
        [whichIndexEnq]: false,
      }));
    }
  }, [addEnquiryResponse.error, addEnquiryResponse.message]);

  return (
    <div className="margin-top-up">
      { loaderEnquiry ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      <Row className="align-center">
        <Col xs="12" lg="12">
          <Card className="card-filter-comm">
            <Row className="mx-1">
              <Col lg="5" xs="12">
                <div className="input-wrapper">
                  <Input
                    type="text"
                    placeholder="Search by Name, Location"
                    onChange={handleSearchChange}
                  />
                  <Button type="button" onClick={handleSearchProjects}>
                    <FontAwesomeIcon icon={faSearch} className="icon-buy-medium1" />
                  </Button>
                </div>
              </Col>
              <Col lg="7" xs="12">
                <div className="align-fullview-tab pt-1">
                  <ButtonDropdown
                    isOpen={showLease}
                    toggle={() => handleShowLease()}
                    className="mx-1 margin-top-dropdwn"
                  >
                    <DropdownToggle caret className="button-open">
                      <span className="mx-2">{selectedLeaseText}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleSelectLease('Lease', 'LEASE')} className="my-2">
                        Lease
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectLease('Outright', 'OTRHT')} className="my-2">
                        Outright
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectLease('Pre Lease', 'PLSE')} className="my-2">
                        Pre Lease
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                  <ButtonDropdown
                    isOpen={showPossTp}
                    toggle={() => handleShowPossTp()}
                    className="mx-1 margin-top-dropdwn"
                  >
                    <DropdownToggle caret className="button-open">
                      <span className="mx-2">{selectedPossTp}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleSelectPossTp('Ready Move-in', 'YES')} className="my-2">
                        Ready Move-in
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectPossTp('In Progress', 'NO')} className="my-2">
                        In Progress
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                  <ButtonDropdown
                    isOpen={showProjTp}
                    toggle={() => handleShowProjTp()}
                    className="mx-1"
                  >
                    <DropdownToggle caret className="button-open">
                      <span className="mx-2">{selectedProjTp}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleSelectProjTp('1 BHK', '1BHK')} className="my-2">
                        1 BHK
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectProjTp('2 BHK', '2BHK')} className="my-2">
                        2 BHK
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectProjTp('3 BHK', '3BHK')} className="my-2">
                        3 BHK
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectProjTp('4 BHK', '4BHK')} className="my-2">
                        4 BHK
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectProjTp('Pent House', 'PNTH')} className="my-2">
                        Pent House
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectProjTp('Row House', 'ROWH')} className="my-2">
                        Row House
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectProjTp('Roof Top Resturants', 'STUDA')} className="my-2">
                        Studio Appartment
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </Col>
              {/* <Col lg="2" xs="6">
                Budget
              </Col> */}
            </Row>
          </Card>
        </Col>
      </Row>
      <div className="margin-l-r-mobile">
        {/* <span className="text-lite-grey mx-2">Count: {projectsData && projectsData.projData.length} / {projectsData.tCnt}</span> */}
        <InfiniteScroll
          dataLength={projectsData.projData.length}
          next={fetchMoreData}
          hasMore={loadingMore}
          loader={loaderAddProjects}
        >
          <Row className="margin-top-des pt-3">
            {projectsData && projectsData.projData.length > 0
              ? projectsData.projData.map((projDet, index) => (
                <Col lg="4" xs="12" key={`proj-${index.toString()}`} className="my-2">
                  <Card className="card-lite">
                    <CardHeader className="form-card-header">
                      <div className="card-image-container">
                        {projDet.projUrl.length > 1 && (
                        <Button className="image-navigation-button left" onClick={() => prevImage(index)}>
                          <FontAwesomeIcon icon={faAngleLeft} className="round-small-icon" />
                        </Button>
                        )}
                        <CardImg
                          src={projDet.projUrl[currentImageIndexes[index]]}
                          alt={`Card-${index}`}
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src = '/images/home/broken-image.png';
                          }}
                        />
                        {projDet.projUrl.length > 1 && (
                        <Button className="image-navigation-button right" onClick={() => nextImage(index)}>
                          <FontAwesomeIcon icon={faAngleRight} className="round-small-icon" />
                        </Button>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody className="padding-zero">
                      <Row className="my-1">
                        <Col xs="12">
                          <span className="text-link ml">RERA : {projDet.rera}</span>
                        </Col>
                        <Col xs="12">
                          <div className="align-center pt-2">
                            <span className="buy-subtext">
                              {projDet.nme}
                            </span>
                          </div>
                          <div className="text-center-aligned mx-1">
                            <span className="text-lite-grey-grey">
                              {toPascalSnakeCase(projDet.loc)}
                            </span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-center my-2 ml">
                            <span className="buy-subtext">
                              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(projDet.budget))}
                            </span>
                            <span className="listing-price-text ml">Onward</span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-start ml my-2 pt-2">
                            {projDet.projCat.map((projCatDet, catIndex) => (
                              <span className="prod-cat-bg mx-1" key={`${catIndex.toString()}`}>
                                { CommPropertyTypeList.find((comm) => comm.value === projCatDet)?.label || ''}
                                { ResidentPropertyTypeList.find((res) => res.value === projCatDet)?.label || ''}
                              </span>
                            ))}
                          </div>
                          <Row>
                            <Col xs="12">
                              <span className="text-super-lite ml">Project Configuration:</span>
                            </Col>
                            {projDet.resConfig.map((resConfigtDet, resIndex) => (
                              <Col lg="12" className="pt-2 align-start ml" key={`${resIndex.toString()}`}>
                                <span className="text-lite-grey-grey box-buy-cat">
                                  { ResidentPropertyTypeList.find((res) => res.value === resConfigtDet.resTp)?.label || ''}
                                  <span className="mx-2 icon-buy-small">|</span>
                                  <FontAwesomeIcon icon={faRug} className="mx-2 text-super-lite" />{resConfigtDet.carpAr} sqft
                                  <span className="mx-2 icon-buy-small">|</span>
                                  <FontAwesomeIcon icon={faRulerCombined} className="mx-2 text-super-lite" />{resConfigtDet.sellAr} sqft
                                  <span className="mx-2 icon-buy-small">|</span>
                                  <FontAwesomeIcon icon={faCar} className="mx-2 text-super-lite" />{resConfigtDet.carPark}
                                </span>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                        <Col xs="12">
                          <div className="align-center pt-4">
                            <Button className="button-filled" onClick={() => projToggleModal(`projDet-${index}`)}>
                              GET PROJECT DETAILS
                            </Button>
                          </div>
                        </Col>
                        <Modal
                          isOpen={openModalProj[`projDet-${index}`] || false}
                          target={`projDet-${index}`}
                        >
                          <ModalHeader toggle={() => projToggleModal(`projDet-${index}`)} className="form-card-border-none padding-zero mr-1 ml">
                            <span className="text-lite-grey"><span className="text-lite-grey-grey-small">Please provide your details. Our team will contact you</span><br />{projDet.nme}</span>
                          </ModalHeader>
                          <ModalBody className="padding-zero mb-4">
                            <Form onSubmit={(e) => handleAddEnquiry(e, projDet.ProjTp, projDet.ProjId)}>
                              <Row className="mx-1 pt-3">
                                <Col xs="12" className="pt-1">
                                  <h6 className="text-lite-grey mx-2 my-1">Your Name<span className="mandate-star">*</span></h6>
                                  <Input
                                    placeholder="Enter your name"
                                    type="text"
                                    required
                                    name="nme"
                                    value={enquiryInput.nme}
                                    onChange={handleFieldChange}
                                    className="buy-input"
                                  />
                                </Col>
                                <Col lg="12" className="pt-4">
                                  <h6 className="text-lite-grey mx-2 my-1">Phone<span className="mandate-star">*</span></h6>
                                  <InputGroup>
                                    <Input
                                      placeholder="Enter your Phone Number"
                                      type="number"
                                      required
                                      name="ph"
                                      value={enquiryInput.ph}
                                      onChange={handleFieldChange}
                                      className="buy-input"
                                    />
                                  </InputGroup>
                                  <h6 className="icon-buy-medium-dis mx-2 pt-2">
                                    10 digit phone number (exclude +91)
                                  </h6>
                                  <h6 className="icon-buy-medium-dis mx-1 my-2">If opting for<span className="text-whatsapp mx-1">WhatsApp</span>services please provide same number</h6>
                                </Col>
                                <Col lg="12" md="10" xs="12">
                                  <div className="text-right-login-campus-register pt-4 mx-1">
                                    <h6 className="text-lite-grey mx-2 my-1">Message if any</h6>
                                    <Input
                                      placeholder="Provide your message"
                                      type="textarea"
                                      name="msg"
                                      value={enquiryInput.msg}
                                      onChange={handleFieldChange}
                                      className="buy-textarea"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div className="align-center pt-4 mx-2">
                                <Button
                                  className="button-filled mx-1"
                                >
                                  SUBMIT
                                </Button>
                              </div>
                            </Form>
                          </ModalBody>
                        </Modal>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))
              : (
                <div className="margin-top-video align-center">
                  <span className="form-collapse-card-header">No Projects Found</span>
                </div>
              )}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default PublicResidentialFullViewProjects;
