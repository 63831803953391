import React from 'react';
import './buypersqft.scss';

import { HINavbar } from '../../components/public/navbar/buypersqft.navbar';
import { PublicResidentialFullViewProjects } from '../../components/public/home/residentials/fullview.residential';

export const AllResidentialComponent: React.FC = () => (
  <div>
    <HINavbar />
    <div className="pt-2">
      <PublicResidentialFullViewProjects />
    </div>
  </div>
);

export default AllResidentialComponent;
