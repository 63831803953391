import { GetAllPubRESProjectsResponse, GetAllPubRESProjectsResults } from './get-all-pub-res-projects.service.types';

export const GetAllPubRESProjectsTransform: (response: GetAllPubRESProjectsResponse) => GetAllPubRESProjectsResults = (getAllSellerProductResults) => {
  const { data, is_error, message } = getAllSellerProductResults;
  const result: GetAllPubRESProjectsResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        ProjTp: '',
        ProjId: '',
      },
      projData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
