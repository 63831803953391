import {
  Button,
  Card, CardBody, CardFooter,
} from 'reactstrap';

export const errorAlert = (isRefresh: boolean, errorMessage: any, closeAlert: Boolean, setIsFieldEmpty: any) => {
  const clearAlert = () => {
    setIsFieldEmpty(!closeAlert);
  };
  const clearAlertRefresh = () => {
    setIsFieldEmpty(!closeAlert);
    window.location.reload();
  };
  return (
    <div className="loader-container">
      <Card style={{ maxWidth: '500px', border: '1px solid #f2e0aa', boxShadow: '5px 5px 10px rgb(20 55 183 / 20%)' }}>
        <CardBody>
          <div className="align-center mx-4">
            <span className="alert-error">{errorMessage}</span>
          </div>
        </CardBody>
        <CardFooter style={{ background: 'white', borderTop: 'none' }}>
          <div className="align-center">
            {isRefresh
              ? (
                <Button
                  onClick={() => {
                    clearAlertRefresh();
                  }}
                  className="button-filled"
                >close
                </Button>
              )
              : (
                <Button
                  onClick={() => {
                    clearAlert();
                  }}
                  className="button-filled"
                >close
                </Button>
              )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export const successAlert = (isRefresh: boolean, message: any, closeAlert: Boolean, setIsFieldEmpty: any) => {
  const clearAlert = () => {
    setIsFieldEmpty(!closeAlert);
  };
  const clearAlertRefresh = () => {
    setIsFieldEmpty(!closeAlert);
    window.location.reload();
  };
  return (
    <div className="loader-container">
      <Card style={{
        maxWidth: '400px', background: '#fcfcf5', border: '1px solid #fcfcb6', boxShadow: '0px 6px 8px rgb(0 55 183 / 18%)',
      }}
      >
        <CardBody>
          <div className="align-center mx-2">
            <span className="alert-success">{message}</span>
          </div>
        </CardBody>
        <CardFooter style={{ background: '#fcfcf5', borderTop: 'none' }}>
          <div className="align-center">
            {isRefresh
              ? (
                <Button
                  onClick={() => {
                    clearAlertRefresh();
                  }}
                  className="button-filled"
                >close
                </Button>
              )
              : (
                <Button
                  onClick={() => {
                    clearAlert();
                  }}
                  className="button-filled"
                >close
                </Button>
              )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export const changeDateFormat = (dateData: string) => {
  const date = new Date(dateData);
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};

export const toCamelCase = (str: string) => str
  .toLowerCase()
  .split(' ')
  .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
  .join('');

export const toSnakeCase = (str: string) => str
  .replace(/([a-z])([A-Z])/g, '$1_$2')
  .replace(/\s+/g, '_')
  .toLowerCase();

export const toPascalSnakeCase = (str: string) => str
  .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
  .replace(/[\s_-]+/g, ' ') // Replace spaces, hyphens, and underscores with a single space
  .split(' ') // Split the string into words
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
  .join(' ');
