import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, Button, Input,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Tooltip,
} from 'reactstrap';
import {
  faCircleInfo,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  errorAlert, successAlert, toPascalSnakeCase,
} from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import {
  APIHeader,
} from '../../../../utils/constants';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import {
  clearEnquiryListReq,
  clearPutEnquiryReq,
  getEnquiryListReq,
  putEnquiryReq,
} from '../../../../store/buypersqft/actions';
import { EnquiryResult, EnquiryResultData } from '../../../../services/enquiry/get-all-enquiry/get-all-enquiry.types';
import PaginationButton from '../../../../utils/pagination/paginate-button';
import { EnquiryEditData } from '../../../../services/enquiry/update-enquiry/update-enquiry.types';

export const GetAllEnquiry: React.FC = () => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const tokenData = getTokenFromSessionStorage();
  const [loaderAddEnquiry, setLoaderAddEnquiry] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [tooltipOpen, setTooltipOpen] = React.useState<Record<string, boolean>>({});
  // const [loadingMore, setLoadingMore] = React.useState(false);
  const [enquiryData, setEnquiryData] = React.useState<EnquiryResult>({
    tCnt: 0,
    fCnt: 0,
    enqData: [],
    lastEvalKey: {
      EnqTp: '',
      EnqId: '',
    },
  });
  const [enquiryUpdateInput, setEnquiryUpdateInput] = React.useState<EnquiryEditData>({
    EnqTp: '',
    EnqId: '',
    sts: '',
    admMsg: '',
  });
  const [whichEnqIndex, setWhichEnqIndex] = React.useState('');
  const [openModalEnq, setOpenModalEnq] = React.useState<Record<string, boolean>>({});
  const listEnquiry = useSelector((state: RootState) => state.buypersqft.getEnquiryList);
  const updateEnquiryResponse = useSelector((state: RootState) => state.buypersqft.updateEnquiry);
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const itemsPerPage = 12;
  const [totalPages, setTotalPages] = React.useState(1);
  const [parentPage, setParentPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);

  const startIndex = (parentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRefData = enquiryData.enqData.slice(startIndex, endIndex);

  const toggle = (target: string) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  React.useEffect(() => {
    if (evalKeyText === '' && evalTpText === '' && userTp !== '' && searchText === '') {
      setLoaderAddEnquiry(true);
      setEnquiryData({
        ...enquiryData,
        tCnt: 0,
        fCnt: 0,
        enqData: [],
        lastEvalKey: {
          EnqTp: '',
          EnqId: '',
        },
      });
      dispatch(getEnquiryListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_ENQUIRY,
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
      }));
    }
  }, [evalKeyText, evalTpText, userTp, searchText]);

  const fetchMoreData = () => {
    setLoaderAddEnquiry(true);
    if (searchText !== '') {
      dispatch(
        getEnquiryListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_ENQUIRY,
          limit: 12, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          searchBy: searchText,
          isSearch: 'YES',
          token: tokenData,
        }),
      );
    } else {
      dispatch(
        getEnquiryListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_ENQUIRY,
          limit: 12, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
        }),
      );
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearchEnquiry = () => {
    setLoaderAddEnquiry(true);
    setEnquiryData({
      ...enquiryData,
      tCnt: 0,
      fCnt: 0,
      enqData: [],
      lastEvalKey: {
        EnqTp: '',
        EnqId: '',
      },
    });
    dispatch(
      getEnquiryListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_ENQUIRY,
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
      }),
    );
  };

  React.useEffect(() => {
    if (listEnquiry.isError && listEnquiry.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg(listEnquiry.message);
      setErrorShowAlert(true);
    }
  }, [listEnquiry.isError, listEnquiry.message]);

  React.useEffect(() => {
    if (!listEnquiry.isError && listEnquiry.message === 'executed') {
      setLoaderAddEnquiry(false);
      if (searchText !== '') {
        setEnquiryData(listEnquiry.data);
        // setLoadingMore(false);
      } else if (listEnquiry.data.lastEvalKey !== null) {
        const newData = listEnquiry.data.enqData.filter((item) => !enquiryData.enqData.some((existingItem) => existingItem.EnqId === item.EnqId));
        setEnquiryData((prevData) => ({
          ...prevData,
          tCnt: listEnquiry.data.tCnt,
          fCnt: listEnquiry.data.fCnt,
          enqData: [...prevData.enqData, ...newData],
          lastEvalKey: listEnquiry.data.lastEvalKey,
        }));
        setEvalKeyText(listEnquiry.data.lastEvalKey.EnqId);
        setEvalTpText(listEnquiry.data.lastEvalKey.EnqTp);
        // setLoadingMore(true);
      } else {
        setEnquiryData((prevData) => ({
          ...prevData,
          tCnt: listEnquiry.data.tCnt,
          fCnt: listEnquiry.data.fCnt,
          enqData: [...prevData.enqData, ...listEnquiry.data.enqData],
        }));
        // setLoadingMore(false);
      }
    }
  }, [listEnquiry.isError, listEnquiry.message, listEnquiry.data.enqData, listEnquiry.data.lastEvalKey, evalKeyText]);

  const handleFieldUpdateChange = (e:any) => {
    const fieldName = e.target.name;
    setEnquiryUpdateInput({ ...enquiryUpdateInput, [fieldName]: e.target.value });
  };

  const handleEnqSts = (e: any) => {
    setEnquiryUpdateInput({ ...enquiryUpdateInput, sts: e.target.name });
  };

  const enquiryToggleModal = (target: string, rowData: EnquiryResultData) => {
    setWhichEnqIndex(target);
    setOpenModalEnq((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setEnquiryUpdateInput({
      ...enquiryUpdateInput,
      EnqTp: rowData.EnqTp ? rowData.EnqTp : '',
      EnqId: rowData.EnqId ? rowData.EnqId : '',
      sts: rowData.sts ? rowData.sts : '',
      admMsg: rowData.admMsg ? rowData.admMsg : '',
    });
  };

  const handleEnquiryUpdate = () => {
    setLoaderAddEnquiry(true);
    dispatch(clearEnquiryListReq());
    setEnquiryData({
      ...enquiryData,
      tCnt: 0,
      fCnt: 0,
      enqData: [],
      lastEvalKey: {
        EnqTp: '',
        EnqId: '',
      },
    });
    Promise.resolve(
      dispatch(putEnquiryReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_ENQUIRY,
        inputBody: enquiryUpdateInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (updateEnquiryResponse.error && updateEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg(updateEnquiryResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateEnquiryResponse.error, updateEnquiryResponse.message]);

  React.useEffect(() => {
    if (!updateEnquiryResponse.error && updateEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg(updateEnquiryResponse.message);
      dispatch(clearPutEnquiryReq());
      dispatch(getEnquiryListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_ENQUIRY,
        limit: 12, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
      }));
      setOpenModalEnq((prevState) => ({
        ...prevState,
        [whichEnqIndex]: false,
      }));
    }
  }, [updateEnquiryResponse.error, updateEnquiryResponse.message]);

  return (
    <div>
      { loaderAddEnquiry ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row>
        <Col lg={{ size: 3, order: 1 }} xs={{ order: 1 }}>
          <div className="pt-3 ml-1">
            <Card className="card-lite">
              <CardHeader className="bg-header">
                <Row className="proj-search-box1">
                  <Col lg="12" className="pt-3">
                    <Input
                      type="text"
                      className="buy-input"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                    <span className="text-super-lite">Search by Project Name/Enquired By/Location</span>
                  </Col>
                  <Col lg="12">
                    <div className="align-center pt-3 mb-2 my-3">
                      <Button
                        className="button-filled"
                        onClick={handleSearchEnquiry}
                      >
                        SEARCH <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </div>
        </Col>
        <Col lg={{ size: 9, order: 2 }} xs={{ order: 2 }}>
          {/* <span className="text-lite-grey mx-2">Count: {enquiryData && enquiryData.enqData.length} / {enquiryData.tCnt}</span> */}
          <div>
            <div className="my-2 ml-1">
              <span>Enquiry Result</span>
            </div>
            <Card className="form-card-border">
              <CardBody>
                <Row>
                  <Col lg="5" xs="5">
                    <span className="text-lite-grey ">Total Records: {enquiryData.tCnt}</span>
                  </Col>
                  <Col lg="7" xs="7">
                    <div className="align-end">
                      <PaginationButton
                        tCnt={enquiryData.tCnt}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages === 0 ? 1 : totalPages}
                        setParentPage={setParentPage}
                        setTotalPages={setTotalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        onNextPageClick={fetchMoreData}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="table-responsive">
                  {enquiryData.tCnt === 0 ? (
                    <div className="pt-5 align-center">
                      <span className="form-collapse-card-header">No Record Found</span>
                    </div>
                  )
                    : (
                      <table>
                        <thead>
                          <tr>
                            <th>Enquiry By</th>
                            <th>Phone Number</th>
                            <th>Enquiry for</th>
                            <th>Message</th>
                            <th>Current Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {visibleRefData && visibleRefData.map((enqDet, index) => (
                            <tr key={`file-${index.toString()}`}>
                              <td data-name="filename" className="status">
                                <div className="align-start">
                                  {enqDet.admMsg && enqDet.admMsg !== ''
                                    ? (
                                      <div>
                                        <Button className="button-icon" id={`tooltip-${enqDet.EnqId}-${index}`}>
                                          <FontAwesomeIcon icon={faCircleInfo} />
                                        </Button>
                                        <Tooltip
                                          placement="top"
                                          isOpen={tooltipOpen[`tooltip-${enqDet.EnqId}-${index}`] || false}
                                          target={`tooltip-${enqDet.EnqId}-${index}`}
                                          toggle={() => toggle(`tooltip-${enqDet.EnqId}-${index}`)}
                                        >
                                          <div className="my-1 mx-2">
                                            Admin Message
                                            <hr className="my-1" />
                                          </div>
                                          <div className="my-1 mx-2">
                                            {enqDet.admMsg}
                                          </div>
                                        </Tooltip>
                                      </div>
                                    ) : null }
                                  <span>{toPascalSnakeCase(enqDet.nme)}</span>
                                </div>
                              </td>
                              <td className="size">
                                <span className="all-text">{enqDet.ph}</span>
                              </td>
                              <td className="status">
                                <span>{enqDet.projNme !== '' ? toPascalSnakeCase(enqDet.projNme) : 'QUOTE'}</span>
                                <br />
                                {enqDet.addr !== '' ? toPascalSnakeCase(enqDet.addr) : ''}

                              </td>
                              <td className="modified">
                                {enqDet.msg}
                              </td>
                              <td className="filename">
                                {enqDet.sts && enqDet.sts === 'PEND'
                                  ? (
                                    <span className="admin-status-grey">
                                      PENDING
                                    </span>
                                  )
                                  : enqDet.sts && enqDet.sts === 'INPROG'
                                    ? <span className="admin-status-red">PROGRESS</span>
                                    : enqDet.sts && enqDet.sts === 'COMP'
                                      ? <span className="admin-status-green">COMPLETED</span> : null}
                              </td>
                              <td data-name="action" className="action">
                                <Button className="button-filled" onClick={() => enquiryToggleModal(`enqDet-${index}`, enqDet)}>
                                  EDIT
                                </Button>
                                <Modal
                                  isOpen={openModalEnq[`enqDet-${index}`] || false}
                                  target={`enqDet-${index}`}
                                >
                                  <ModalHeader toggle={() => enquiryToggleModal(`enqDet-${index}`, enqDet)} className="form-card-border-none">
                                    <span className="text-lite-grey">{enqDet.nme}</span>
                                  </ModalHeader>
                                  <ModalBody className="padding-zero mb-4">
                                    <Row className="mx-1">
                                      <Col xs="12" className="pt-1">
                                        <h6 className="text-lite-grey mx-2 my-1">Status<span className="mandate-star">*</span></h6>
                                        <Row className="ml">
                                          <Col lg="4">
                                            <Label check className="cat-check-text1">
                                              <Input
                                                type="radio"
                                                role="switch"
                                                name="PEND"
                                                onChange={handleEnqSts}
                                                checked={enquiryUpdateInput.sts === 'PEND'}
                                                className="buy-check-box mr-1 cursor-pointer"
                                              />
                                              PENDING
                                            </Label>
                                          </Col>
                                          <Col lg="4">
                                            <Label check className="cat-check-text1">
                                              <Input
                                                type="radio"
                                                role="switch"
                                                name="INPROG"
                                                onChange={handleEnqSts}
                                                checked={enquiryUpdateInput.sts === 'INPROG'}
                                                className="buy-check-box mr-1 cursor-pointer"
                                              />
                                              IN PROGRESS
                                            </Label>
                                          </Col>
                                          <Col lg="4">
                                            <Label check className="cat-check-text1">
                                              <Input
                                                type="radio"
                                                role="switch"
                                                name="COMP"
                                                onChange={handleEnqSts}
                                                checked={enquiryUpdateInput.sts === 'COMP'}
                                                className="buy-check-box mr-1 cursor-pointer"
                                              />
                                              COMPLETED
                                            </Label>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg="12" className="pt-4">
                                        <h6 className="text-lite-grey mx-2 my-1">Admin Message<span className="mandate-star">*</span></h6>
                                        <Input
                                          placeholder="Enter Message"
                                          type="textarea"
                                          name="admMsg"
                                          value={enquiryUpdateInput.admMsg}
                                          onChange={handleFieldUpdateChange}
                                          className="buy-textarea"
                                        />
                                      </Col>
                                    </Row>
                                    <div className="align-center pt-4 mx-2">
                                      <Button
                                        className="button-filled mx-1"
                                        onClick={handleEnquiryUpdate}
                                      >
                                        UPDATE
                                      </Button>
                                    </div>
                                  </ModalBody>
                                </Modal>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) }
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default GetAllEnquiry;
