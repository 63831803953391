import React from 'react';
import './buypersqft.scss';

import { HINavbar } from '../../components/public/navbar/buypersqft.navbar';
import { PublicCommercialFullViewProjects } from '../../components/public/home/commercial/fullview.commercial';

export const AllCommercialComponent: React.FC = () => (
  <div>
    <HINavbar />
    <div className="pt-2">
      <PublicCommercialFullViewProjects />
    </div>
  </div>
);

export default AllCommercialComponent;
