import { CreateProjImgResponse, CreateProjImgResults } from './create-projimage.service.types';

export const CreateProjImgTransform: (response: CreateProjImgResponse) => CreateProjImgResults = (createProjImgDBResults) => {
  const { data } = createProjImgDBResults;
  const result: CreateProjImgResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};
