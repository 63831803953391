import AWS from 'aws-sdk';
import { CreateProjImgTransportType } from '../../services/projects/upload-proj-Image/create-projimage.types';
import { getConfig } from '../config/config';
import { GetListProjImgTransportType } from '../../services/projects/get-list-projects-images/getList-projimage.types';
import { DeleteProjImgTransportType } from '../../services/projects/delete-proj-image/delete-projimage.types';

const {
  USERNAME, PASSWORD, AWS_ACCESS_KEY, AWS_SECRET, AWS_REGION,
} = getConfig();

const s3 = new AWS.S3({
  accessKeyId: AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRET,
  region: AWS_REGION,
});

export const getToken = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};
const requestOptions = (method: string, token: string, requestType: string, body?: BodyInit) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
  },
  body: JSON.stringify(body),
});

const requestPublicData = (method: string, requestType: string, bodyData: any) => ({
  method,
  headers: {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  },
  body: JSON.stringify(bodyData),
});

export const postPublicData = async (url: RequestInfo, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestPublicData('POST', requestType, body));
  const data = await response.json();
  return data;
};

const getLastIndexNumber = async (bucketName: string, folderName: string): Promise<number> => {
  const params = {
    Bucket: bucketName,
    Prefix: `${folderName}/`, // Include the folder prefix
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const fileKeys = data.Contents?.map((item) => item.Key) || [];
    const lastIndex = fileKeys.reduce((maxIndex, fileKey) => {
      if (fileKey) {
        const match = fileKey.match(/_(\d+)\.\w+$/);
        if (match) {
          const index = parseInt(match[1], 10);
          return index > maxIndex ? index : maxIndex;
        }
      }
      return maxIndex;
    }, 0);
    return lastIndex;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting last index number:', error);
    throw error;
  }
};

export const uploadS3File = async (
  bucketName: string,
  fileNmeInit: string,
  folderName: string,
  formData: FormData,
  isEdit: boolean,
): Promise<CreateProjImgTransportType> => {
  try {
    if (isEdit === true) {
      try {
        const lastIndex = await getLastIndexNumber(bucketName, folderName);
        const uploadPromises = Array.from(formData.entries()).map(async ([key, file], index) => {
          if (file instanceof File) {
            const indxNum = lastIndex + index + 1;
            const parts = key.split('.');
            const extension = parts[parts.length - 1];
            const filename = `${fileNmeInit}_${indxNum}.${extension}`;
            const s3Key = `${folderName}/${filename}`;

            const res = await s3.upload({
              Bucket: bucketName,
              Key: s3Key,
              Body: file,
              ACL: 'public-read',
            }).promise();

            return { data: res.Key };
          }
          throw new Error('Invalid file type');
        });

        const uploadResults = await Promise.all(uploadPromises);
        if (uploadResults.length > 0) {
          return uploadResults[0];
        }
        return { data: 'none' };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error uploading files:', error);
        throw error;
      }
    }
    const uploadPromises = Array.from(formData.entries()).map(async ([key, file], index) => {
      if (file instanceof File) {
        const indxNum = index + 1;
        const parts = key.split('.');
        const extension = parts[parts.length - 1];
        const filename = `${fileNmeInit}_${indxNum}.${extension}`;
        const s3Key = `${folderName}/${filename}`;

        const res = await s3.upload({
          Bucket: bucketName,
          Key: s3Key,
          Body: file,
          ACL: 'public-read',
        }).promise();

        return { data: res.Key };
      }
      throw new Error('Invalid file type');
    });
    const uploadResults = await Promise.all(uploadPromises);
    if (uploadResults.length > 0) {
      return uploadResults[0];
    }
    return { data: 'none' };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const getListS3File = async (
  bucketName: string,
  folderName: string,
): Promise<GetListProjImgTransportType> => {
  const params = {
    Bucket: bucketName,
    Prefix: folderName,
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const fileKeys = data.Contents?.map((item) => {
      const fileName = item.Key?.split('/').pop() || '';
      const url = s3.getSignedUrl('getObject', {
        Bucket: bucketName,
        Key: item.Key,
        Expires: 60 * 60,
        ...(fileName.endsWith('.pdf')
          ? {
            ResponseContentDisposition: 'inline',
            ResponseContentType: 'application/pdf',
          }
          : {}),
      });
      return { url, nme: fileName };
    }) || [];
    return { data: fileKeys };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading files: ', error);
    return { data: [] };
  }
};

export const deleteFilesFromS3 = async (
  bucketName: string,
  folderName: string,
  delFiles: string[],
): Promise<DeleteProjImgTransportType> => {
  try {
    const delPromises = delFiles.map(async (fileKey) => {
      const s3Key = `${folderName}/${fileKey}`;

      await s3.deleteObject({
        Bucket: bucketName,
        Key: s3Key,
      }).promise();
    });

    await Promise.all(delPromises);
    return { data: 'deleted' };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error deleting files:', error);
    throw error;
  }
};

export const getJSON = async (url: RequestInfo, token: string, requestType: string) => {
  const response = await fetch(url, requestOptions('GET', token, requestType));
  const data = await response.json();
  return data;
};
export const postJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('POST', token, requestType, body));
  const data = await response.json();
  return data;
};
export const putJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('PUT', token, requestType, body));
  const data = await response.json();
  return data;
};
export const getLogin = async (url: RequestInfo, requestType: string, userName: string, passKey: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${userName}:${passKey}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

export const getPublicJSON = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

const requestOptionsPrivateFiles = (method: string, requestType: string, requestFile: string, requestData: string, token: string, formdata: FormData) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
    'Request-Data': requestData,
    'Request-File': requestFile,
    // 'Content-Type': formdata.type,
  },
  body: formdata,
});

const requestOptionsPublicFile = (method: string, requestType: string, requestFile: string, requestData: string, formdata: any) => ({
  method,
  headers: {
    'Request-Type': requestType,
    'Request-Data': requestData,
    'Request-File': requestFile,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  },
  body: formdata,
});

export const postPublicFileData = async (url: RequestInfo, requestType: string, fileUpload:string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(body));
  formData.append('files', fileUpload);
  const response = await fetch(url, requestOptionsPublicFile('POST', requestType, '', '', formData));
  const data = await response.json();
  return data;
};

export const postPublicFormData = async (url: RequestInfo, requestType: string, requestFile: string, requestData: string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(body));
  const response = await fetch(url, requestOptionsPublicFile('POST', requestType, requestFile, requestData, formData));
  const data = await response.json();
  return data;
};

export const putCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'PUT', headers });
  const data = await response.json();
  return data;
};

export const getCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const getCommonApiToken = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const putPrivateFileData = async (url: RequestInfo, token: string, requestType: string, requestFile: string, requestData: string, fileData: FormData) => {
  const response = await fetch(url, requestOptionsPrivateFiles('PUT', requestType, requestFile, requestData, token, fileData));
  const data = await response.json();
  return data;
};
