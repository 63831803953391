import { GetAllEnquiryResponse, GetAllEnquiryResults } from './get-all-enquiry.service.types';

export const GetAllEnquiryTransform: (response: GetAllEnquiryResponse) => GetAllEnquiryResults = (getAllSellerProductResults) => {
  const { data, is_error, message } = getAllSellerProductResults;
  const result: GetAllEnquiryResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        EnqTp: '',
        EnqId: '',
      },
      enqData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
