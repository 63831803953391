import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicBuyPerSqFtComponent from '../../scenes/public-buypersqft/buypersqft.component';
import { AdminExclusiveComponent } from '../../scenes/pivate-buypersqft/admin.component';
import { LoginBUYComponent } from '../../scenes/public-buypersqft/login.component';
import AllCommercialComponent from '../../scenes/public-buypersqft/all-comm.components';
import { AllResidentialComponent } from '../../scenes/public-buypersqft/all-res.components';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/" element={<PublicBuyPerSqFtComponent />} />
    <Route path="/commercial" element={<AllCommercialComponent />} />
    <Route path="/residential" element={<AllResidentialComponent />} />
    <Route path="/login/*" element={<LoginBUYComponent />} />
    <Route path="/sadmin/*" element={<AdminExclusiveComponent />} />
  </Routes>
);

export default AppBase;
