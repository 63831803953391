export const API_ERROR = 'API_ERROR';
export const SHOW_DATA = 'SHOW_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const CONTACT_US_DATA = 'CONTACT_US_DATA';
export const UPDATE_CONTACT_US_DATA = 'UPDATE_CONTACT_US_DATA';
export const CLEAR_CONTACT_US_DATA = 'CLEAR_CONTACT_US_DATA';
export const GET_LOGIN_TOKEN = 'GET_LOGIN_TOKEN';
export const UPDATE_LOGIN_TOKEN = 'UPDATE_LOGIN_TOKEN';
export const CLEAR_LOGIN_TOKEN = 'CLEAR_LOGIN_TOKEN';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const CREATE_PROJECTS = 'CREATE_PROJECTS';
export const UPDATE_CREATE_PROJECTS = 'UPDATE_CREATE_PROJECTS';
export const CLEAR_CREATE_PROJECTS = 'CLEAR_CREATE_PROJECTS';
export const PUT_PROJECTS = 'PUT_PROJECTS';
export const UPDATE_PUT_PROJECTS = 'UPDATE_PUT_PROJECTS';
export const CLEAR_PUT_PROJECTS = 'CLEAR_PUT_PROJECTS';
export const UPLOAD_PROJ_IMG = 'UPLOAD_PROJ_IMG';
export const UPDATE_UPLOAD_PROJ_IMG = 'UPDATE_UPLOAD_PROJ_IMG';
export const CLEAR_UPLOAD_PROJECTS = 'CLEAR_UPLOAD_PROJECTS';
export const GET_LIST_PROJ_IMG = 'GET_LIST_PROJ_IMG';
export const UPDATE_GET_LIST_PROJ_IMG = 'UPDATE_GET_LIST_PROJ_IMG';
export const CLEAR_GET_LIST_PROJECTS = 'CLEAR_GET_LIST_PROJECTS';
export const DELETE_PROJ_IMG = 'DELETE_PROJ_IMG';
export const UPDATE_DELETE_PROJ_IMG = 'UPDATE_DELETE_PROJ_IMG';
export const CLEAR_DELETE_PROJECTS = 'CLEAR_DELETE_PROJECTS';
export const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
export const UPDATE_PROJECTS_LIST = 'UPDATE_PROJECTS_LIST';
export const CLEAR_PROJECTS_LIST = 'CLEAR_PROJECTS_LIST';
export const GET_PUB_RES_PROJECTS_LIST = 'GET_PUB_RES_PROJECTS_LIST';
export const UPDATE_PUB_RES_PROJECTS_LIST = 'UPDATE_PUB_RES_PROJECTS_LIST';
export const CLEAR_PUB_RES_PROJECTS_LIST = 'CLEAR_PUB_RES_PROJECTS_LIST';
export const CREATE_ENQUIRY = 'CREATE_ENQUIRY';
export const UPDATE_CREATE_ENQUIRY = 'UPDATE_CREATE_ENQUIRY';
export const CLEAR_CREATE_ENQUIRY = 'CLEAR_CREATE_ENQUIRY';
export const GET_ENQUIRY_LIST = 'GET_ENQUIRY_LIST';
export const UPDATE_ENQUIRY_LIST = 'UPDATE_ENQUIRY_LIST';
export const CLEAR_ENQUIRY_LIST = 'CLEAR_ENQUIRY_LIST';
export const PUT_ENQUIRY = 'PUT_ENQUIRY';
export const UPDATE_PUT_ENQUIRY = 'UPDATE_PUT_ENQUIRY';
export const CLEAR_PUT_ENQUIRY = 'CLEAR_PUT_ENQUIRY';
