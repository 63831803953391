import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import './commercial.scss';
import {
  Button, Card, CardBody, CardHeader, CardImg, Col, Form, Input, InputGroup, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import {
  faAngleLeft,
  faAngleRight,
  faArrowRightLong,
  faArrowUp,
  faCar,
  faRug,
  faRulerCombined,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  APIHeader, CommPropertyTypeList, ResidentPropertyTypeList,
} from '../../../../utils/constants';
import { ProjectsResult } from '../../../../services/projects/list-projects/get-all-projects.types';
import {
  clearCreateEnquiryReq, clearProjectsListReq, createEnquiryReq, getProjectsListReq,
} from '../../../../store/buypersqft/actions';
import LoaderData from '../../../../utils/loader';
import { ProTpProps } from '../homeList.type';
import { PostEnquiryInput } from '../../../../services/enquiry/add-enquiry/post-enquiry.types';
import { successAlert, errorAlert, toPascalSnakeCase } from '../../../../utils/alert';

export const MCommercialDeal: React.FC<ProTpProps> = ({ propertyTp }) => {
  const dispatch = useDispatch();
  const [mobileCommProjectsData, setMobileCommProjectsData] = React.useState<ProjectsResult>({
    tCnt: 0,
    fCnt: 0,
    projData: [],
    lastEvalKey: {
      ProjTp: '',
      ProjId: '',
    },
  });
  const [openModalProj, setOpenModalProj] = React.useState<Record<string, boolean>>({});
  const listCOMMProjects = useSelector((state: RootState) => state.buypersqft.getProjectsList);
  const [evalKeyCOMMText, setEvalKeyCOMMText] = React.useState('');
  const [evalTpCOMMText, setEvalTpCOMMText] = React.useState('');
  const [loaderMobileProjects, setLoaderMobileProjects] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [isLastObject, setIsLastObject] = React.useState(false);
  const addEnquiryResponse = useSelector((state: RootState) => state.buypersqft.createNewEnquiry);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const phoneRegex = /^\d{10}$/;
  const [whichIndexEnq, setWhichIndexEnq] = React.useState('');
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [loaderEnquiry, setLoaderAddEnquiry] = React.useState(false);
  const [currentImageIndexes, setCurrentImageIndexes] = React.useState<number[]>([]);
  const [enquiryInput, setEnquiryInput] = React.useState<PostEnquiryInput>({
    nme: '',
    ph: '',
    projId: '',
    projTp: '',
    isWhatsApp: false,
    msg: '',
    projNme: '',
    addr: '',
  });

  const handleFieldChange = (e:any) => {
    const fieldName = e.target.name;
    setEnquiryInput({ ...enquiryInput, [fieldName]: e.target.value });
    setIsValidPhone(false);
  };

  React.useEffect(() => {
    if (evalKeyCOMMText === '' && evalTpCOMMText === '' && propertyTp === 'COMM') {
      setLoaderMobileProjects(true);
      dispatch(getProjectsListReq({
        requestType: APIHeader.REQ_GET_ALL_PROJECTS,
        projTp: 'COMM',
        limit: 3, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        isPublic: true,
      }));
    }
  }, [evalKeyCOMMText, evalTpCOMMText, propertyTp]);

  const fetchMoreData = () => {
    if (evalKeyCOMMText !== '' && evalTpCOMMText !== '') {
      setLoadingMore(true);
      dispatch(
        getProjectsListReq({
          requestType: APIHeader.REQ_GET_ALL_PROJECTS,
          projTp: 'COMM',
          limit: 3, // ApiLIMIT.LIMIT,
          evalKey: evalKeyCOMMText,
          evalTp: evalTpCOMMText,
          isFilter: 'NO',
          isSearch: 'NO',
          isPublic: true,
        }),
      );
    }
  };

  const nextImage = (cardIndex: number) => {
    if (mobileCommProjectsData.projData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] + 1) % mobileCommProjectsData.projData[cardIndex].projUrl.length;
        return newIndexes;
      });
    }
  };

  const prevImage = (cardIndex: number) => {
    if (mobileCommProjectsData.projData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] - 1 + mobileCommProjectsData.projData[cardIndex].projUrl.length) % mobileCommProjectsData.projData[cardIndex].projUrl.length;
        return newIndexes;
      });
    }
  };

  const projToggleModal = (target: string) => {
    setWhichIndexEnq(target);
    setOpenModalProj((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  React.useEffect(() => {
    if (!listCOMMProjects.isError && listCOMMProjects.message === 'executed' && propertyTp === 'COMM') {
      setLoadingMore(false);

      if (listCOMMProjects.data.lastEvalKey !== null) {
        // Filter out duplicates and new data
        const newData = listCOMMProjects.data.projData.filter((item) => !mobileCommProjectsData.projData.some((existingItem) => existingItem.ProjId === item.ProjId));

        // Update state with the first item from newData
        if (newData.length > 0) {
          setMobileCommProjectsData((prevData) => ({
            ...prevData,
            tCnt: listCOMMProjects.data.tCnt,
            fCnt: listCOMMProjects.data.fCnt,
            projData: [...prevData.projData, newData[0]], // Update with only the first item from newData
            lastEvalKey: listCOMMProjects.data.lastEvalKey,
          }));

          setEvalKeyCOMMText(listCOMMProjects.data.lastEvalKey.ProjId);
          setEvalTpCOMMText(listCOMMProjects.data.lastEvalKey.ProjTp);
          setCurrentImageIndexes((prevIndexes) => [...prevIndexes, 0]); // Assuming only one index is added
        }
      } else {
        // Update state by adding each unique item from listCOMMProjects.data.projData
        listCOMMProjects.data.projData.forEach((item) => {
          if (!mobileCommProjectsData.projData.some((existingItem) => existingItem.ProjId === item.ProjId)) {
            setMobileCommProjectsData((prevData) => ({
              ...prevData,
              tCnt: listCOMMProjects.data.tCnt,
              fCnt: listCOMMProjects.data.fCnt,
              projData: [...prevData.projData, item],
            }));
            setCurrentImageIndexes((prevIndexes) => [...prevIndexes, 0]);
            setIsLastObject(true);
          }
        });
      }

      dispatch(clearProjectsListReq());
    }
  }, [
    listCOMMProjects.isError,
    listCOMMProjects.message,
    listCOMMProjects.data.projData,
    listCOMMProjects.data.lastEvalKey,
    evalKeyCOMMText,
    propertyTp,
  ]);
  // React.useEffect(() => {
  //   if (!listCOMMProjects.isError && listCOMMProjects.message === 'executed' && listCOMMProjects.data.lastEvalKey !== null && !isLastObject) {
  //     setLoadingMore(false);
  //     setLoaderMobileProjects(false);
  //     const newData = listCOMMProjects.data.projData.filter(
  //       (item) => !mobileCommProjectsData.projData.some((existingItem) => existingItem.ProjId === item.ProjId),
  //     );
  //     setCOMMmobileCommProjectsData((prevData) => ({
  //       ...prevData,
  //       tCnt: listCOMMProjects.data.tCnt,
  //       fCnt: listCOMMProjects.data.fCnt,
  //       projData: [...prevData.projData, ...newData],
  //       lastEvalKey: listCOMMProjects.data.lastEvalKey,
  //     }));
  //     setEvalKeyCOMMText(listCOMMProjects.data.lastEvalKey.ProjId || '');
  //     setEvalTpCOMMText(listCOMMProjects.data.lastEvalKey.ProjTp || '');
  //     setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...newData.map(() => 0)]);
  //   }
  // }, [listCOMMProjects.isError, listCOMMProjects.message, listCOMMProjects.data.lastEvalKey]);

  // React.useEffect(() => {
  //   if (!listCOMMProjects.isError && listCOMMProjects.message === 'executed' && listCOMMProjects.data.lastEvalKey === null) {
  //     setLoadingMore(false);
  //     setLoaderMobileProjects(false);
  //     setIsLastObject(true);
  //     setCOMMmobileCommProjectsData((prevData) => ({
  //       ...prevData,
  //       tCnt: listCOMMProjects.data.tCnt,
  //       fCnt: listCOMMProjects.data.fCnt,
  //       projData: [...prevData.projData, ...listCOMMProjects.data.projData],
  //     }));
  //     setEvalKeyCOMMText('');
  //     setEvalTpCOMMText('');
  //     setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...listCOMMProjects.data.projData.map(() => 0)]);
  //   }
  // }, [listCOMMProjects.isError, listCOMMProjects.message, listCOMMProjects.data.lastEvalKey]);

  React.useEffect(() => {
    if (isLastObject === true) {
      setLoadingMore(false);
      setLoaderMobileProjects(false);
      dispatch(clearProjectsListReq());
    }
  }, [isLastObject]);

  const handleAddEnquiry = (e: any, projectTp: string, projectId: string, projectName: string, projectAddr: string) => {
    e.preventDefault();
    const validPhone = phoneRegex.test(enquiryInput.ph);
    if (validPhone) {
      setLoaderAddEnquiry(true);
      const updatedKartInput = {
        ...enquiryInput,
        projId: projectId,
        projTp: projectTp,
        projNme: projectName,
        addr: projectAddr,
      };
      Promise.resolve(updatedKartInput).then((resolvedInput) => {
        dispatch(createEnquiryReq({
          inputBody: resolvedInput,
          requestType: APIHeader.REQ_ADD_ENQUIRY,
        }));
      });
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  React.useEffect(() => {
    if (addEnquiryResponse.error && addEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg(addEnquiryResponse.message);
      setErrorShowAlert(true);
    }
  }, [addEnquiryResponse.error, addEnquiryResponse.message]);

  React.useEffect(() => {
    if (!addEnquiryResponse.error && addEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg('We have recieved your enquiry. Our team will get back to you shortly');
      setSuccessShowAlert(true);
      dispatch(clearCreateEnquiryReq());
      // dispatch(getProjectsListReq({
      //   requestType: APIHeader.REQ_GET_ALL_PROJECTS,
      //   projTp: 'COMM',
      //   limit: 3, // ApiLIMIT.LIMIT,
      //   evalKey: '',
      //   evalTp: '',
      //   isFilter: 'NO',
      //   isSearch: 'NO',
      //   isPublic: true,
      // }));
      setOpenModalProj((prevState) => ({
        ...prevState,
        [whichIndexEnq]: false,
      }));
    }
  }, [addEnquiryResponse.error, addEnquiryResponse.message]);

  return (
    <div className="margin-top-header">
      { loadingMore ? <LoaderData /> : null}
      { loaderEnquiry ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      <Row>
        <Col xs="12">
          <div className="align-center pt-4">
            <span className="text-main-header">Commercial Projects</span>
          </div>
          <div className="align-center">
            <Button to="all-projects" className="link-text-italic button-icon" target="_blank" href="/commercial">
              Show All <FontAwesomeIcon icon={faArrowRightLong} />
            </Button>
          </div>
        </Col>
        <Col xs="12">
          <Row className="margin-top-des pt-3">
            {mobileCommProjectsData && mobileCommProjectsData.projData.length > 0
              ? mobileCommProjectsData.projData.map((projDet, index) => (
                <Col xs="12" key={`projj-${index.toString()}`} className="my-2">
                  <Card className="card-lite">
                    <CardHeader className="form-card-header">
                      <div className="card-image-container">
                        {projDet.projUrl.length > 1 && (
                        <Button className="image-navigation-button left" onClick={() => prevImage(index)}>
                          <FontAwesomeIcon icon={faAngleLeft} className="round-small-icon" />
                        </Button>
                        )}
                        <CardImg
                          src={projDet.projUrl[currentImageIndexes[index]]}
                          alt={`Card-${index}`}
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src = '/images/home/broken-image.png';
                          }}
                        />
                        {projDet.projUrl.length > 1 && (
                        <Button className="image-navigation-button right" onClick={() => nextImage(index)}>
                          <FontAwesomeIcon icon={faAngleRight} className="round-small-icon" />
                        </Button>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody className="padding-zero">
                      <Row className="my-1">
                        <Col xs="12">
                          <span className="text-link ml">RERA : {projDet.rera}</span>
                        </Col>
                        <Col xs="12">
                          <div className="align-center pt-2">
                            <span className="buy-subtext">
                              {projDet.nme}
                            </span>
                          </div>
                          <div className="text-center-aligned mx-1">
                            <span className="text-lite-grey-grey">
                              {toPascalSnakeCase(projDet.loc)}
                            </span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-center my-2 ml">
                            <span className="buy-subtext">
                              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(projDet.budget))}
                            </span>
                            <span className="listing-price-text ml">Onward</span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-start ml my-2 pt-2">
                            {projDet.projCat.map((projCatDet, catIndex) => (
                              <span className="prod-cat-bg mx-1" key={`${catIndex.toString()}`}>
                                { CommPropertyTypeList.find((comm) => comm.value === projCatDet)?.label || ''}
                                { ResidentPropertyTypeList.find((res) => res.value === projCatDet)?.label || ''}
                              </span>
                            ))}
                          </div>
                          {projDet.resConfig.map((resConfigtDet, resIndex) => (
                            <div className="mx-1 my-2 pt-2" key={`${resIndex.toString()}`}>
                              <span className="box-buy-cat">
                                <FontAwesomeIcon icon={faRug} className="mx-1" />{resConfigtDet.carpAr} sqft
                                <span className="mx-1 icon-buy-small">|</span>
                                <FontAwesomeIcon icon={faRulerCombined} className="mx-1" />{resConfigtDet.sellAr} sqft
                                <span className="mx-1 icon-buy-small">|</span>
                                <FontAwesomeIcon icon={faCar} className="mx-1" />{resConfigtDet.carPark}
                              </span>
                            </div>
                          ))}

                        </Col>
                        <Col xs="12">
                          <div className="align-start ml my-3">
                            {projDet.ProjTp === 'COMM'
                              ? (
                                <span className="box-buy-cat mx-1">
                                  <FontAwesomeIcon icon={faCar} className="mx-1" />
                                  {projDet.carPark}
                                </span>
                              ) : null}
                            {projDet.ProjTp === 'COMM'
                              ? (
                                <span className="box-buy-cat mx-1">
                                  <FontAwesomeIcon icon={faRug} className="mx-1" />{projDet.carpAr} sqft
                                </span>
                              )
                              : null}
                            {projDet.ProjTp === 'COMM'
                              ? <span className="box-buy-cat mx-1"><FontAwesomeIcon icon={faRulerCombined} className="mx-1" />{projDet.sellAr} sqft</span>
                              : null}
                          </div>
                          <Row>
                            <Col xs="4" lg="4">
                              <div className="ml pt-2">
                                {projDet.leaseTp === 'LEASE'
                                  ? <span className="prod-lease-bg">LEASE</span>
                                  : projDet.leaseTp === 'OTRHT'
                                    ? <span className="prod-lease-bg">OUT RIGHT</span>
                                    : projDet.leaseTp === 'PLSE'
                                      ? <span className="prod-lease-bg">PRE LEASE</span> : null}
                              </div>
                              {/* {projDet.leaseTp !== '' ? <span className="ml-1 text-lite-grey-grey-small">Lease type</span> : null} */}
                            </Col>
                            <Col xs="8" lg="8">
                              <div className="align-end mr pt-1">
                                <Button className="button-filled" onClick={() => projToggleModal(`projDet-${index}`)}>
                                  GET PROJECT DETAILS
                                </Button>
                              </div>
                            </Col>
                            <Modal
                              isOpen={openModalProj[`projDet-${index}`] || false}
                              target={`projDet-${index}`}
                            >
                              <ModalHeader toggle={() => projToggleModal(`projDet-${index}`)} className="form-card-border-none padding-zero mr-1 ml">
                                <span className="text-lite-grey"><span className="text-lite-grey-grey-small">Please provide your details. Our team will contact you</span><br />{projDet.nme}</span>
                              </ModalHeader>
                              <ModalBody className="padding-zero mb-4">
                                <Form onSubmit={(e) => handleAddEnquiry(e, projDet.ProjTp, projDet.ProjId, projDet.nme, projDet.loc)}>
                                  <Row className="mx-1 pt-3">
                                    <Col xs="12" className="pt-1">
                                      <h6 className="text-lite-grey mx-2 my-1">Your Name<span className="mandate-star">*</span></h6>
                                      <Input
                                        placeholder="Enter your name"
                                        type="text"
                                        required
                                        name="nme"
                                        value={enquiryInput.nme}
                                        onChange={handleFieldChange}
                                        className="buy-input"
                                      />
                                    </Col>
                                    <Col lg="12" className="pt-4">
                                      <h6 className="text-lite-grey mx-2 my-1">Phone<span className="mandate-star">*</span></h6>
                                      <InputGroup>
                                        <Input
                                          placeholder="Enter your Phone Number"
                                          type="number"
                                          required
                                          name="ph"
                                          value={enquiryInput.ph}
                                          onChange={handleFieldChange}
                                          className="buy-input"
                                        />
                                      </InputGroup>
                                      <h6 className="icon-buy-medium-dis mx-2 pt-2">
                                        10 digit phone number (exclude +91)
                                      </h6>
                                      <h6 className="icon-buy-medium-dis mx-1 my-2">If opting for<span className="text-whatsapp mx-1">WhatsApp</span>services please provide same number</h6>
                                    </Col>
                                    <Col lg="12" md="10" xs="12">
                                      <div className="text-right-login-campus-register pt-4 mx-1">
                                        <h6 className="text-lite-grey mx-2 my-1">Message if any</h6>
                                        <Input
                                          placeholder="Provide your message"
                                          type="textarea"
                                          name="msg"
                                          value={enquiryInput.msg}
                                          onChange={handleFieldChange}
                                          className="buy-textarea"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="align-center pt-4 mx-2">
                                    <Button
                                      className="button-filled mx-1"
                                    >
                                      SUBMIT
                                    </Button>
                                  </div>
                                </Form>
                              </ModalBody>
                            </Modal>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))
              : (
                <div className="pt-4 align-center">
                  <span className="form-collapse-card-header">No Projects Found</span>
                </div>
              )}
            {loaderMobileProjects ? (
              <div className="loader-container-none">
                <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
                <span className="mx-2">Please wait....</span>
              </div>
            ) : null}
          </Row>
        </Col>
        <div>
          {!isLastObject
            ? (
              <div className="align-center">
                <Button disabled={loaderMobileProjects} className="link-text-italic button-icon" onClick={fetchMoreData}>
                  Load More <FontAwesomeIcon icon={faSortDown} />
                </Button>
              </div>
            )
            : (
              <div>
                <div className="align-center">
                  <span className="text-bold-grey pt-3">No More Records Avilable</span>
                </div>
                <div className="align-center">
                  <Button to="#commercials" className="link-text-italic button-icon" href="#commercials">
                    Back to top <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </div>
              </div>
            )}
        </div>
      </Row>
    </div>
  );
};

export default MCommercialDeal;
