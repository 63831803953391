import { PostEnquiryResponse, PostEnquiryResults } from './post-enquiry.service.types';

export const PostEnquiryTransform: (response: PostEnquiryResponse) => PostEnquiryResults = (createPostResults) => {
  const { data, is_error, message } = createPostResults;
  const result: PostEnquiryResults = {
    message: '',
    error: false,
    data: '',
    enqId: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
    result.enqId = data.id;
  }
  return result;
};
