import React from 'react';
import {
  faBars, faClose,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import './nav.scss';
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup, InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavLink,
  Row,
} from 'reactstrap';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { RootState } from 'typesafe-actions';
import { PostEnquiryInput } from '../../../services/enquiry/add-enquiry/post-enquiry.types';
import { createEnquiryReq, clearCreateEnquiryReq } from '../../../store/buypersqft/actions';
import { APIHeader } from '../../../utils/constants';
import { LoaderData } from '../../../utils/loader';
import { errorAlert, successAlert } from '../../../utils/alert';

export const HINavbar: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [click, setClick] = React.useState(true);
  const [changeIcon, setChangeIcon] = React.useState(true);
  const [getQuote, setGetQuote] = React.useState(false);
  const addEnquiryResponse = useSelector((state: RootState) => state.buypersqft.createNewEnquiry);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const phoneRegex = /^\d{10}$/;
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [loaderEnquiry, setLoaderAddEnquiry] = React.useState(false);
  const [enquiryInput, setEnquiryInput] = React.useState<PostEnquiryInput>({
    nme: '',
    ph: '',
    projId: '',
    projTp: '',
    isWhatsApp: false,
    msg: '',
    projNme: '',
    addr: '',
  });

  const handleFieldChange = (e:any) => {
    const fieldName = e.target.name;
    setEnquiryInput({ ...enquiryInput, [fieldName]: e.target.value });
    setIsValidPhone(false);
  };

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClick(!click);
  };

  const handleOpenQuote = () => {
    setGetQuote(true);
  };

  const handleAddEnquiry = (e: any, projectTp: string, projectId: string, projectName: string, projectAddr: string) => {
    e.preventDefault();
    const validPhone = phoneRegex.test(enquiryInput.ph);
    if (validPhone) {
      setLoaderAddEnquiry(true);
      const updatedKartInput = {
        ...enquiryInput,
        projId: projectId,
        projTp: projectTp,
        projNme: projectName,
        addr: projectAddr,
      };
      Promise.resolve(updatedKartInput).then((resolvedInput) => {
        dispatch(createEnquiryReq({
          inputBody: resolvedInput,
          requestType: APIHeader.REQ_ADD_ENQUIRY,
        }));
      });
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  React.useEffect(() => {
    if (addEnquiryResponse.error && addEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg(addEnquiryResponse.message);
      setErrorShowAlert(true);
    }
  }, [addEnquiryResponse.error, addEnquiryResponse.message]);

  React.useEffect(() => {
    if (!addEnquiryResponse.error && addEnquiryResponse.message !== '') {
      setLoaderAddEnquiry(false);
      setAlertMsg('We have recieved your enquiry. Our team will get back to you shortly');
      setSuccessShowAlert(true);
      dispatch(clearCreateEnquiryReq());
      // dispatch(getPubRESProjectsListReq({
      //   requestType: APIHeader.REQ_GET_ALL_PROJECTS,
      //   projTp: 'RES',
      //   limit: 3, // ApiLIMIT.LIMIT,
      //   evalKey: '',
      //   evalTp: '',
      //   isFilter: 'NO',
      //   isSearch: 'NO',
      //   isPublic: true,
      // }));
      setGetQuote(false);
    }
  }, [addEnquiryResponse.error, addEnquiryResponse.message]);

  return (
    <div className="navbar-container">
      { loaderEnquiry ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      <Nav className="navbar">
        <div className="navbar-left">
          <NavLink to="/" className="nav-logo" onClick={() => { history('/'); }}>
            <img style={{ height: '55px', width: 'auto' }} src="/BUYPERSQFT.png" alt="buysqftLogo" />
          </NavLink>
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <div className="navbar-right margin-nav-desk">
            <InputGroup>
              <InputGroupText className="quote-nav-main-left cursor-pointer" onClick={handleOpenQuote}>
                GET QUOTE
              </InputGroupText>
              <InputGroupText className="quote-nav-main-middle">
                +91 70203 11609
              </InputGroupText>
              <InputGroupText
                className="quote-nav-main-middle"
                href="https://web.whatsapp.com/"
                target="_blank"
              >
                <Button
                  className="button-icon"
                  href="https://web.whatsapp.com/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faWhatsapp} className="quote-nav-main-middle" />
                </Button>

              </InputGroupText>
            </InputGroup>
          </div>
        </ul>
      </Nav>
      <NavLink
        className="nav-icon"
        onClick={() => { handleClick(); }}
        to="#"
      >
        {changeIcon ? <FontAwesomeIcon icon={faClose} className="nav-close-btn" /> : <FontAwesomeIcon icon={faBars} className="nav-open-btn" />}
      </NavLink>
      <Modal
        isOpen={getQuote}
        toggle={() => setGetQuote(false)}
      >
        <ModalHeader toggle={() => setGetQuote(false)} className="form-card-border-none">
          <span className="dash-seller-chat-icon">Contact Us</span>
        </ModalHeader>
        <ModalBody className="padding-zero mx-3">
          <Form onSubmit={(e) => handleAddEnquiry(e, '', '', '', '')}>
            <Row className="mx-1 pt-3">
              <Col xs="12" className="pt-1">
                <h6 className="text-lite-grey mx-2 my-1">Your Name<span className="mandate-star">*</span></h6>
                <Input
                  placeholder="Enter your name"
                  type="text"
                  required
                  name="nme"
                  value={enquiryInput.nme}
                  onChange={handleFieldChange}
                  className="buy-input"
                />
              </Col>
              <Col lg="12" className="pt-4">
                <h6 className="text-lite-grey mx-2 my-1">Phone<span className="mandate-star">*</span></h6>
                <InputGroup>
                  <Input
                    placeholder="Enter your Phone Number"
                    type="number"
                    required
                    name="ph"
                    value={enquiryInput.ph}
                    onChange={handleFieldChange}
                    className="buy-input"
                  />
                </InputGroup>
                <h6 className="icon-buy-medium-dis mx-2 pt-2">
                  10 digit phone number (exclude +91)
                </h6>
                <h6 className="icon-buy-medium-dis mx-1 my-2">If opting for<span className="text-whatsapp mx-1">WhatsApp</span>services please provide same number</h6>
              </Col>
              <Col lg="12" md="10" xs="12">
                <div className="text-right-login-campus-register pt-4 mx-1">
                  <h6 className="text-lite-grey mx-2 my-1">Message if any</h6>
                  <Input
                    placeholder="Provide your message"
                    type="textarea"
                    name="msg"
                    value={enquiryInput.msg}
                    onChange={handleFieldChange}
                    className="buy-textarea"
                  />
                </div>
              </Col>
            </Row>
            <div className="align-center pt-3 mx-2 my-3">
              <Button
                className="button-filled mx-1"
              >
                SUBMIT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default HINavbar;
