import { DeleteProjImgResponse, DeleteProjImgResults } from './delete-projimage.service.types';

export const DeleteProjImgTransform: (response: DeleteProjImgResponse) => DeleteProjImgResults = (createProjImgDBResults) => {
  const { data } = createProjImgDBResults;
  const result: DeleteProjImgResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};
