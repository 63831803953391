import { getCommonApiToken } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { GetAllEnquiryOptions, GetAllEnquiryResponse } from './get-all-enquiry.service.types';

const { BUYPERSQFT_API_ROOT } = getConfig();

export const GetAllEnquiryRequest = ({
  requestType, userType, token, evalKey, limit, evalTp, searchBy, isSearch, isFilter, filterBy,
}: GetAllEnquiryOptions): Promise<GetAllEnquiryResponse> => {
  if (isFilter === 'YES') {
    return getCommonApiToken(`${BUYPERSQFT_API_ROOT}?userTp=${userType}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
  } if (isSearch === 'YES') {
    return getCommonApiToken(`${BUYPERSQFT_API_ROOT}?userTp=${userType}&isSearch=${isSearch}&searchBy=${searchBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
  }
  return getCommonApiToken(`${BUYPERSQFT_API_ROOT}?userTp=${userType}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
};
