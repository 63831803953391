import { StaticConfig } from './config-type';

export const getConfig: () => StaticConfig = () => ({
  USERNAME: process.env.REACT_APP_USERNAME || '',
  PASSWORD: process.env.REACT_APP_PASSWORD || '',
  BUYPERSQFT_API_ROOT: process.env.REACT_APP_BUYPERSQFT_API_ROOT || '',
  BUYPERSQFT_API_VERSION: parseInt(process.env.REACT_APP_BUYPERSQFT_API_VERSION || '1', 10),
  AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY || '',
  AWS_SECRET: process.env.REACT_APP_AWS_SECRET || '',
  AWS_REGION: process.env.REACT_APP_AWS_REGION || '',
  AWS_PRODUCT_BUCKET: process.env.REACT_APP_AWS_PRODUCT_BUCKET || '',
});
