import React from 'react';
import { ExclusiveLoginNavbar } from '../../components/public/navbar/exclusive-navbar';
import { Login } from '../../components/public/login/login';

export const LoginBUYComponent: React.FC = () => (
  <div>
    <ExclusiveLoginNavbar />
    <Login />
  </div>
);

export default LoginBUYComponent;
