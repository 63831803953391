import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, CardImg, Button, Input, Form,
  Label,
} from 'reactstrap';
import {
  faCircleXmark,
  faCube,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark } from '@fortawesome/free-regular-svg-icons';
import { getConfig } from '../../../../utils/config/config';
import { errorAlert, successAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import {
  APIHeader,
  CommPropertyTypeList,
  LOCOptions,
  ResidentPropertyTypeList,
} from '../../../../utils/constants';
import { PostProjectsInput, ProjectTypeMeta, ResConfiguration } from '../../../../services/projects/add-projects/post-projects.types';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import { ProjImgFileInput } from '../../../../services/projects/upload-proj-Image/create-projimage.types';
import { FileDetails } from '../../../../services/projects/get-list-projects-images/getList-projimage.types';
import {
  clearCreateProjectsReq, clearUploadProjImg, createProjectsReq, uploadProjImg,
} from '../../../../store/buypersqft/actions';
import { ProjectProps } from './proj.type';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const AddProjects: React.FC<ProjectProps> = ({ setIsProjectAdd }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const tokenData = getTokenFromSessionStorage();
  const [loaderAddProjects, setLoaderAddProjects] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [projCategoryData, setProjCategoryData] = React.useState('');
  const addProjectsResponse = useSelector((state: RootState) => state.buypersqft.createNewProjects);
  const uploadProjectsAwsResponse = useSelector((state: RootState) => state.buypersqft.uploadProjectsAWS);
  const [prodImageFile, setProjImageDataFile] = React.useState<ProjImgFileInput>({
    fileData: new FormData(),
  });
  const [addProjImgList, setAddProjImgList] = React.useState<FileDetails[]>([]);
  const [projectCategoryOptions, setProjectCategoryOptions] = React.useState<ProjectTypeMeta[]>([]);
  const [projectsInput, setProjectsInput] = React.useState<PostProjectsInput>({
    nme: '',
    builder: '',
    rera: '',
    projType: 'COMM',
    projCat: [],
    leaseTp: '',
    budget: '',
    loc: '',
    sellAr: 0,
    carpAr: 0,
    carPark: 0,
    isMovin: 'NO',
    resConfig: [],
  });
  const [currentConfig, setCurrentConfig] = React.useState<ResConfiguration>({
    resTp: '',
    carpAr: '',
    sellAr: '',
    carPark: 0,
  });

  React.useEffect(() => {
    setProjImageDataFile(prodImageFile);
  }, [prodImageFile.fileData]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
      // setOEMArray(userInitTp && JSON.parse(userInitTp).profileData.oem);
    }
  }, [userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const handleProjType = (e: any) => {
    setProjectsInput({ ...projectsInput, projType: e.target.name, projCat: [] });
    setProjectCategoryOptions([]);
    setProjCategoryData('');
  };

  const handleProjIsMovin = (e: any) => {
    setProjectsInput({ ...projectsInput, isMovin: e.target.name });
  };

  const handleProjLease = (e: any) => {
    setProjectsInput({ ...projectsInput, leaseTp: e.target.name });
  };

  const handleProjectsSubmit = (e:any) => {
    e.preventDefault();
    setLoaderAddProjects(true);
    Promise.resolve(
      dispatch(createProjectsReq({
        userType: userTp,
        requestType: APIHeader.REQ_ADD_PROJECT,
        inputBody: projectsInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (addProjectsResponse.error && addProjectsResponse.message !== '') {
      setLoaderAddProjects(false);
      setAlertMsg(addProjectsResponse.message);
      setErrorShowAlert(true);
    }
  }, [addProjectsResponse.error, addProjectsResponse.message]);

  React.useEffect(() => {
    if (!addProjectsResponse.error && addProjectsResponse.message !== '') {
      if (addProjectsResponse.projId !== '') {
        // const prodIdNme = `${addProjectsResponse.prodId}.${mediaFileNmeExt}`;
        dispatch(uploadProjImg({
          bucketNme: AWS_PRODUCT_BUCKET,
          folderName: `projects/${addProjectsResponse.projId}`,
          fileNmeInit: addProjectsResponse.projId,
          formData: prodImageFile.fileData,
          requestType: '',
          isEdit: false,
        }));
      } else {
        setLoaderAddProjects(false);
        setAlertMsg('Projects Image upload failed');
        setErrorShowAlert(true);
      }
    }
  }, [addProjectsResponse.error, addProjectsResponse.message, addProjectsResponse.projId]);

  React.useEffect(() => {
    if (uploadProjectsAwsResponse.fileName !== '') {
      setLoaderAddProjects(false);
      setAlertMsg('Project Uploaded Successfully');
      setSuccessShowAlert(true);
      setProjectsInput({
        ...projectsInput,
        nme: '',
        builder: '',
        rera: '',
        projType: 'COMM',
        projCat: [],
        leaseTp: '',
        budget: '',
        loc: '',
        sellAr: 0,
        carpAr: 0,
        carPark: 0,
        isMovin: 'NO',
        resConfig: [],
      });
      setProjImageDataFile({ ...prodImageFile, fileData: new FormData() });
      setAddProjImgList([]);
      dispatch(clearCreateProjectsReq());
      dispatch(clearUploadProjImg());
      setIsProjectAdd(false);
    }
  }, [uploadProjectsAwsResponse.fileName]);

  const handleProjectsImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 5 * 1024 * 1024;

    const formData = new FormData();
    // const previews: string[] = [];

    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 5 MB.');
        setErrorShowAlert(true);
        return;
      }
      formData.append(file.name, file);
      const objectUrl = URL.createObjectURL(file);
      setAddProjImgList((prevList) => [
        ...prevList,
        { url: objectUrl, nme: file.name },
      ]);
    });
    setProjImageDataFile({ ...prodImageFile, fileData: formData });
  };

  // Project Filter Options Start
  const projCategoryCOMMOptions = CommPropertyTypeList.map((comm) => ({
    label: comm.label,
    value: comm.value,
  }));

  const selectedProjCategoryCOMMOptions = projCategoryCOMMOptions && projCategoryCOMMOptions.find(
    (option) => option.value === projCategoryData,
  );

  const projCategoryRESOptions = ResidentPropertyTypeList.map((res) => ({
    label: res.label,
    value: res.value,
  }));

  const selectedProjCategoryRESOptions = projCategoryRESOptions && projCategoryRESOptions.find(
    (option) => option.value === projCategoryData,
  );

  const projResConfigOptions = ResidentPropertyTypeList.map((res) => ({
    label: res.label,
    value: res.value,
  }));

  const selectedProjResConfigOptionsOptions = projResConfigOptions && projResConfigOptions.find(
    (option) => option.value === currentConfig.resTp,
  );

  // Project Filter Options END

  // Project Location Options Start
  const locOptions = LOCOptions.map((loc) => ({
    label: loc.label,
    value: loc.value,
  }));

  const selectedLocOptions = locOptions && locOptions.find(
    (option) => option.label === projectsInput.loc,
  );

  // Project Location Options END

  const handleFieldChange = (e:any) => {
    const fieldName = e.target.name;
    setProjectsInput({ ...projectsInput, [fieldName]: e.target.value });
  };

  const handleNumberChange = (e:any) => {
    const { value } = e.target;
    const cntValue = value === '' ? 0 : parseInt(value);
    if (cntValue > 0) {
      setProjectsInput({ ...projectsInput, [e.target.name]: Number(cntValue) });
    } else {
      setProjectsInput({ ...projectsInput, [e.target.name]: Number(0) });
    }
  };

  const handleRemoveImage = (indexToRemove: number) => {
    // const updatedPreviews = filePreviews.filter((_, index) => index !== indexToRemove);
    const updatedPreviews = addProjImgList.filter((_, index) => index !== indexToRemove);
    const updatedFiles = new FormData();

    Array.from(prodImageFile.fileData.entries()).forEach(([key, file], index) => {
      if (index !== indexToRemove) {
        updatedFiles.append(key, file);
      }
    });

    // setFilePreviews(updatedPreviews);
    setAddProjImgList(updatedPreviews);
    setProjImageDataFile({ ...prodImageFile, fileData: updatedFiles });
  };

  const handleSelectProjCategory = (e: any) => {
    const selectedValue = e.value;
    const selectedLabel = e.label;
    setProjCategoryData(e.value);
    if (!projectCategoryOptions.some((option) => option.label === selectedLabel)) {
      if (!projectsInput.projCat.includes(selectedValue)) {
        setProjectsInput({ ...projectsInput, projCat: [...projectsInput.projCat, selectedValue] });

        const updatedLabel: ProjectTypeMeta = {
          label: selectedLabel,
          value: selectedValue,
        };
        setProjectCategoryOptions([...projectCategoryOptions, updatedLabel]);
      }
    }
  };
  const handleRemoveProjType = (projCat: string) => {
    setProjectCategoryOptions((prevOptions) => prevOptions.filter((option) => option.value !== projCat));

    const projCatToRemove = projectsInput.projCat.find(
      (project) => project === projCat,
    );

    if (projCatToRemove) {
      setProjectsInput((prevData) => ({
        ...prevData,
        projCat: prevData.projCat.filter(
          (projVal) => projVal !== projCatToRemove,
        ),
      }));
    }
  };
  const handleSelectLoc = (e: any) => {
    setProjectsInput({ ...projectsInput, loc: e.label });
  };

  const handleSelectProjConfig = (selectedOption: any) => {
    setCurrentConfig({ ...currentConfig, resTp: selectedOption.value });
  };

  const handleNumberConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentConfig({ ...currentConfig, [name]: value });
  };

  const handleAddConfig = () => {
    setProjectsInput((prevState) => ({
      ...projectsInput,
      resConfig: [...prevState.resConfig, currentConfig],
    }));
    setCurrentConfig({
      resTp: '',
      carpAr: '',
      sellAr: '',
      carPark: 0,
    });
  };

  const handleRemoveConfig = (index: number) => {
    setProjectsInput((prevState) => ({
      ...projectsInput,
      resConfig: prevState.resConfig.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="pt-4">
      { loaderAddProjects ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="mx-1 align-center">
        <Col lg="11" xs="12">
          <Card className="card-lite my-2">
            <Row className="mx-1">
              <Col lg="5" xs="12">
                <div className="text-center-aligned my-3">
                  <span className="text-lite-dark">Ensure you upload multiple project images.</span>
                </div>
                <div className="align-center my-4">
                  {addProjImgList.length === 0
                    ? <CardImg className="img-fluid project-preview" src="/images/home/broken-image.png" alt="broken img" />
                    : (
                      <Row className="align-center">
                        <span className="text-lite-grey">{Array.from(prodImageFile.fileData.keys()).length} files in Queue</span>
                        {addProjImgList.map((preview, indexImg) => (
                          <Col lg="3" key={`projImg-${indexImg.toString()}`} className="border-image mx-1">
                            <div className="align-end">
                              <Button className="button-icon padding-zero" onClick={() => handleRemoveImage(indexImg)}>
                                <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                              </Button>
                            </div>
                            <div className="pt-1">
                              <span className="text-lite-grey">{preview.nme}</span>
                              <CardImg className="seller-image-dim" src={preview.url} alt={`ImgData ${indexImg + 1}`} />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}
                </div>
                <div className="align-center">
                  <Input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="fileData"
                    multiple
                    className="button-filled"
                    onChange={(e) => handleProjectsImage(e)}
                  />
                </div>
              </Col>
              <Col lg="7" xs="12">
                <div className="align-center pt-2">
                  <span className="powermart-dark-icon">
                    Add New Projects
                  </span>
                </div>
                <Form onSubmit={handleProjectsSubmit}>
                  <Row className="mx-1">
                    <Col lg="12" className="pt-4">
                      <Row>
                        <Col lg="6">
                          <Label check className="cat-check-text">
                            <Input
                              type="radio"
                              role="switch"
                              name="COMM"
                              onChange={handleProjType}
                              checked={projectsInput.projType === 'COMM'}
                              className="buy-check-box mr-1 cursor-pointer"
                            />
                            COMMERCIALS
                          </Label>
                        </Col>
                        <Col lg="6">
                          <Label check className="cat-check-text">
                            <Input
                              type="radio"
                              role="switch"
                              name="RES"
                              onChange={handleProjType}
                              checked={projectsInput.projType === 'RES'}
                              className="buy-check-box mr-1 cursor-pointer"
                            />
                            RESIDENTIALS
                          </Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="12" className="pt-4">
                      <h6 className="text-lite-grey mx-2 my-1">Project Name<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter project name"
                        type="text"
                        required
                        name="nme"
                        value={projectsInput.nme}
                        onChange={handleFieldChange}
                        className="buy-input"
                      />
                    </Col>
                    <Col lg="12" className="pt-4">
                      <Row>
                        <Col lg="7">
                          <h6 className="text-lite-grey mx-2 my-1">Builder Name<span className="mandate-star">*</span></h6>
                          <Input
                            placeholder="Enter Builder Name"
                            type="text"
                            required
                            name="builder"
                            value={projectsInput.builder}
                            onChange={handleFieldChange}
                            className="buy-input"
                          />
                        </Col>
                        <Col lg="5">
                          <h6 className="text-lite-grey mx-2 my-1">Budget<span className="mandate-star">*</span></h6>
                          <Input
                            placeholder="Budget"
                            required
                            name="budget"
                            value={projectsInput.budget}
                            onChange={handleFieldChange}
                            className="buy-input"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="12" className="pt-4">
                      <h6 className="text-lite-grey mx-2 my-1">RERA<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter RERA Details"
                        type="text"
                        required
                        name="rera"
                        value={projectsInput.rera}
                        onChange={handleFieldChange}
                        className="buy-input"
                      />
                    </Col>
                    <Col lg="12" className="pt-4">
                      <h6 className="text-lite-grey mx-2 my-1">Projects Category<span className="mandate-star">*</span></h6>
                      {projectsInput.projType === 'COMM'
                        ? (
                          <Select
                            value={selectedProjCategoryCOMMOptions}
                            options={projCategoryCOMMOptions}
                            onChange={(e) => handleSelectProjCategory(e)}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                                // position: 'relative',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#05cbddff' },
                                border: '1px solid #BFBFBF',
                                minHeight: '45px',
                                borderRadius: '5px',
                                padding: 1,
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#383838',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                          // isLoading={loaderState}
                            noOptionsMessage={() => 'No Commercial Category Found'}
                            placeholder="Select Commercial Category"
                          />
                        ) : (
                          <Select
                            value={selectedProjCategoryRESOptions}
                            options={projCategoryRESOptions}
                            onChange={(e) => handleSelectProjCategory(e)}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                                // position: 'relative',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#05cbddff' },
                                border: '1px solid #BFBFBF',
                                minHeight: '45px',
                                borderRadius: '5px',
                                padding: 1,
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#383838',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                        // isLoading={loaderState}
                            noOptionsMessage={() => 'No Residential Category Found'}
                            placeholder="Select Residential Category"
                          />
                        ) }
                      <Row className="ml pt-2">
                        {projectCategoryOptions && projectCategoryOptions.length > 0
                          ? projectCategoryOptions.map((proj, index) => (
                            <Col lg="6" key={`projCat-${index.toString()}`} className="my-1 align-start">
                              <div className="mr">
                                <FontAwesomeIcon icon={faCube} className="icon-buy-small" />
                              </div>
                              <div>
                                <span className="form-card-header">{proj.label}</span>
                                <Button className="button-icon padding-zero ml mb-1" onClick={() => handleRemoveProjType(proj.value)}>
                                  <FontAwesomeIcon icon={faRectangleXmark} className="wrong-pass-key" />
                                </Button>
                              </div>
                            </Col>
                          )) : null }
                      </Row>
                    </Col>
                    {projectsInput.projType === 'COMM'
                      ? (
                        <Col lg="12" className="pt-4">
                          <h6 className="text-lite-grey mx-2 my-1">Lease Type<span className="mandate-star">*</span></h6>
                          <Row className="ml">
                            <Col lg="4">
                              <Label check className="cat-check-text1">
                                <Input
                                  type="radio"
                                  role="switch"
                                  name="LEASE"
                                  onChange={handleProjLease}
                                  checked={projectsInput.leaseTp === 'LEASE'}
                                  className="buy-check-box mr-1 cursor-pointer"
                                />
                                LEASE
                              </Label>
                            </Col>
                            <Col lg="4">
                              <Label check className="cat-check-text1">
                                <Input
                                  type="radio"
                                  role="switch"
                                  name="OTRHT"
                                  onChange={handleProjLease}
                                  checked={projectsInput.leaseTp === 'OTRHT'}
                                  className="buy-check-box mr-1 cursor-pointer"
                                />
                                OUTRIGHT
                              </Label>
                            </Col>
                            <Col lg="4">
                              <Label check className="cat-check-text1">
                                <Input
                                  type="radio"
                                  role="switch"
                                  name="PLSE"
                                  onChange={handleProjLease}
                                  checked={projectsInput.leaseTp === 'PLSE'}
                                  className="buy-check-box mr-1 cursor-pointer"
                                />
                                PRE LEASE
                              </Label>
                            </Col>
                          </Row>
                        </Col>
                      ) : null }
                    <Col lg="12" className="pt-4">
                      <h6 className="text-lite-grey mx-2 my-1">Project Progress<span className="mandate-star">*</span></h6>
                      <Row className="ml">
                        <Col lg="6">
                          <Label check className="cat-check-text1">
                            <Input
                              type="radio"
                              role="switch"
                              name="YES"
                              onChange={handleProjIsMovin}
                              checked={projectsInput.isMovin === 'YES'}
                              className="buy-check-box mr-1 cursor-pointer"
                            />
                            READY MOVE-IN
                          </Label>
                        </Col>
                        <Col lg="6">
                          <Label check className="cat-check-text1">
                            <Input
                              type="radio"
                              role="switch"
                              name="NO"
                              onChange={handleProjIsMovin}
                              checked={projectsInput.isMovin === 'NO'}
                              className="buy-check-box mr-1 cursor-pointer"
                            />
                            IN PROGRESS
                          </Label>
                        </Col>
                      </Row>
                    </Col>
                    {projectsInput.projType === 'RES'
                      ? (
                        <Col lg="12" className="pt-4">
                          <h6 className="text-lite-grey mx-2 my-1">Carpet Configuration<span className="mandate-star">*</span></h6>
                          <Row className="proj-config-box">
                            <Col lg="12">
                              <Select
                                value={selectedProjResConfigOptionsOptions}
                                options={projResConfigOptions}
                                onChange={(e) => handleSelectProjConfig(e)}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  menu: (base) => ({
                                    ...base,
                                    // position: 'relative',
                                  }),
                                  control: (base: any) => ({
                                    ...base,
                                    '&:hover': { borderColor: '#05cbddff' },
                                    border: '1px solid #BFBFBF',
                                    minHeight: '45px',
                                    borderRadius: '5px',
                                    padding: 1,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: '#383838',
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    '&:focus': {
                                      borderColor: '#E1EDF8',
                                      boxShadow: 'none',
                                      color: '#575656',
                                      background: '#fff',
                                    },
                                  }),
                                  option: (provided: any, state: { isSelected: any; }) => ({
                                    ...provided,
                                    color: state.isSelected ? '#383838' : '#212121',
                                    padding: 15,
                                    textAlign: 'left',
                                    background: state.isSelected ? '#FAFCFB' : '#fff',
                                    '&:hover': {
                                      background: '#FAFCFB',
                                    },
                                  }),
                                }}
                                noOptionsMessage={() => 'No Residential Category Found'}
                                placeholder="Select Residential Category"
                              />
                            </Col>
                            <Col lg="4" className="pt-3">
                              <h6 className="text-lite-grey mx-2 my-1">Salable Area<span className="mandate-star">*</span></h6>
                              <Input
                                placeholder="Salable Area"
                                type="number"
                                min="0"
                                name="sellAr"
                                value={currentConfig.sellAr}
                                onChange={handleNumberConfigChange}
                                className="buy-input"
                              />
                            </Col>
                            <Col lg="4" className="pt-3">
                              <h6 className="text-lite-grey mx-2 my-1">Carpet Area<span className="mandate-star">*</span></h6>
                              <Input
                                placeholder="Carpet Area"
                                type="number"
                                min="0"
                                name="carpAr"
                                value={currentConfig.carpAr}
                                onChange={handleNumberConfigChange}
                                className="buy-input"
                              />
                            </Col>
                            <Col lg="4" className="pt-3">
                              <h6 className="text-lite-grey mx-2 my-1">Car Parking<span className="mandate-star">*</span></h6>
                              <Input
                                placeholder="Car Parking"
                                type="number"
                                min="0"
                                name="carPark"
                                value={currentConfig.carPark}
                                onChange={handleNumberConfigChange}
                                className="buy-input"
                              />
                            </Col>
                            <Col lg="12">
                              <div className="align-center pt-3">
                                <Button
                                  className="button-filled mx-1"
                                  onClick={handleAddConfig}
                                >
                                  ADD
                                </Button>
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="pt-3">
                                <span>Added Configurations</span>
                                <ul>
                                  {projectsInput.resConfig.map((config, index) => (
                                    <li key={`${index.toString()}`}>
                                      {config.resTp} - Salable Area: {config.sellAr}, Carpet Area: {config.carpAr}, Car Parking: {config.carPark}
                                      <Button className="button-icon padding-zero ml" onClick={() => handleRemoveConfig(index)}>
                                        <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                                      </Button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      ) : null }
                    {projectsInput.projType === 'COMM'
                      ? (
                        <Col lg="12" className="pt-4">
                          <Row>
                            <Col lg="4">
                              <h6 className="text-lite-grey mx-2 my-1">Salable Area<span className="mandate-star">*</span></h6>
                              <Input
                                placeholder="Salable Area"
                                type="number"
                                required
                                min="0"
                                name="sellAr"
                                value={projectsInput.sellAr || ''}
                                onChange={handleNumberChange}
                                className="buy-input"
                              />
                            </Col>
                            <Col lg="4">
                              <h6 className="text-lite-grey mx-2 my-1">Carpet Area<span className="mandate-star">*</span></h6>
                              <Input
                                placeholder="Carpet Area"
                                type="number"
                                required
                                min="0"
                                name="carpAr"
                                value={projectsInput.carpAr || ''}
                                onChange={handleNumberChange}
                                className="buy-input"
                              />
                            </Col>
                            <Col lg="4">
                              <h6 className="text-lite-grey mx-2 my-1">Car Parking<span className="mandate-star">*</span></h6>
                              <Input
                                placeholder="Car Parking"
                                type="number"
                                required
                                min="0"
                                name="carPark"
                                value={projectsInput.carPark || ''}
                                onChange={handleNumberChange}
                                className="buy-input"
                              />
                            </Col>
                          </Row>
                        </Col>
                      ) : null }
                    <Col lg="12" className="pt-4">
                      <h6 className="text-lite-grey mx-2 my-1">Projects Location<span className="mandate-star">*</span></h6>
                      <Select
                        value={projectsInput.loc === '' ? null : selectedLocOptions}
                        options={locOptions}
                        onChange={(e) => handleSelectLoc(e)}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({
                            ...base,
                            // position: 'relative',
                          }),
                          control: (base: any) => ({
                            ...base,
                            '&:hover': { borderColor: '#05cbddff' },
                            border: '1px solid #BFBFBF',
                            minHeight: '45px',
                            borderRadius: '5px',
                            padding: 1,
                            fontSize: '16px',
                            fontWeight: 400,
                            color: '#383838',
                            boxShadow: 'none',
                            backgroundColor: '#fff',
                            '&:focus': {
                              borderColor: '#E1EDF8',
                              boxShadow: 'none',
                              color: '#575656',
                              background: '#fff',
                            },
                          }),
                          option: (provided: any, state: { isSelected: any; }) => ({
                            ...provided,
                            color: state.isSelected ? '#383838' : '#212121',
                            padding: 15,
                            textAlign: 'left',
                            background: state.isSelected ? '#FAFCFB' : '#fff',
                            '&:hover': {
                              background: '#FAFCFB',
                            },
                          }),
                        }}
                          // isLoading={loaderState}
                        noOptionsMessage={() => 'No Location Found'}
                        placeholder="Select Location"
                      />
                    </Col>
                  </Row>
                  <div className="align-center pt-4 mx-2">
                    <Button
                      className="button-filled mx-1"
                      disabled={Array.from(prodImageFile.fileData.keys()).length === 0}
                    >
                      SAVE
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
            <div className="pt-5" />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default AddProjects;
